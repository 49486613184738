import {
  CloseOutlined,
  FormOutlined,
  LoadingOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Form,
  Col,
  Divider,
  Row,
  Space,
  Button,
  Select,
  Spin,
  Tooltip,
} from "antd";
import React, { useContext } from "react";
import GeneralSettingService, {
  GeneralSettingsSections,
  Languages,
} from "../../Services/GeneralSettingService";
import { AccountShort, ISelectGroup } from "../../../Types";
import "../../Common/Acc_Common.css";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import { useClient } from "../../Context/ClientContext";
import { DefaultLedger, SettingsSections } from "../../Services/SettingService";
import { notificationContext } from "../../Common/PageRoute";
import { Voucher_Type } from "../../Services/VoucherServices";
import { voucherType } from "../Reports/VoucherDetailsByLedger";
// import { notificationContext } from "../../Common/PageRoute";

const SettingDefaultLedger = () => {
  const [form] = Form.useForm();
  const { openNotification } = useContext(notificationContext);
  const { currentClient, companySettings, updateCompanySettings } = useClient();
  const [disable, setDisable] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [salesAccount, setSalesAccount] = React.useState<any[]>([]);
  const [purchaseAccount, setPurchaseAccount] = React.useState<any[]>([]);
  const [bankAccount, setBankAccount] = React.useState<any[]>([]);
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    setLoading(true);
    console.log("data", companySettings?.setting?.defaultLedgers);

    const allLedger = async (clientId: string) => {
      await Promise.all([
        LedgerService.getLedgersByGroupName(
          clientId,
          SearchLedgerByGroupsTypes.Sales
        ),
        LedgerService.getLedgersByGroupName(
          clientId,
          SearchLedgerByGroupsTypes.Purchase
        ),
        LedgerService.getLedgersByGroupName(
          clientId,
          SearchLedgerByGroupsTypes.BankAndCash
        ),
      ])
        .then((res: any) => {
          if (res) {
            // console.log(res, "partyname");
            setSalesAccount(res[0]);
            setPurchaseAccount(res[1]);
            setBankAccount(res[2]);
            setLoading(false);
          }
        })
        .catch((ex: any) => {
          console.error(ex);
          setLoading(false);
        });
    };
    allLedger(currentClient?.id!);
    companySettings?.setting?.defaultLedgers?.forEach((x: DefaultLedger) => {
      if (x?.defaultLedgerId) {
        form.setFieldValue(x?.voucherTitle, {
          value: x?.defaultLedgerId,
          key: x?.voucherTitle,
          title: x?.voucherType,
        });
      }
    });
    console.log("Settings: ", companySettings?.setting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  const UpdateGeneral = () => {
    setSaveButtonLoading(true);
    form.validateFields().then((val) => {
      console.log("Form Data: ", val);

      const defaultLedgers: any = Object.entries(val)
        .map(([key, values]): any => {
          const tmpValues = values as any;
          if (tmpValues) {
            return {
              defaultLedgerId: tmpValues?.value,
              voucherTitle: key,
              voucherType: tmpValues?.title,
            };
          }
        })
        .filter((led: any) => led !== undefined);
      const postData = {
        defaultLedgers: defaultLedgers,
      };

      console.log("Post Data: ", defaultLedgers);

      updateCompanySettings!(
        SettingsSections.DefaultLedger,
        {
          ...companySettings,

          setting: {
            ...companySettings?.setting!,
            defaultLedgers: postData?.defaultLedgers,
          },
        },
        postData
      ).then((result: any) => {
        if (result?.status) {
          setDisable(true);
          setSaveButtonLoading(false);
          openNotification("success", "Default Ledger Updated successfully");
        } else {
          setDisable(true);
          setSaveButtonLoading(false);
          openNotification("error", "there is some error");
        }
      });
    });
  };

  return (
    <>
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
        spinning={loading}
      >
        <div style={{ width: "30%" }}>
          <Row justify="space-between">
            <Col className="fs-18 fw-600">Default Ledger</Col>
            <Col>
              {disable && (
                <Tooltip title="Edit">
                  <FormOutlined
                    className="ca-edit-btn"
                    style={{ color: "rgb(106 119 227)" }}
                    onClick={() => {
                      setDisable(false);
                    }}
                  />
                </Tooltip>
              )}
            </Col>
          </Row>
          <Divider className="my-12" />
          <Form
            form={form}
            name="defaultLedger"
            disabled={disable}
            autoComplete="off"
            // onFinish={UpdateGeneral}
          >
            {/* <Row className="ps-14">
              <Col lg={11}>Language</Col>
              <Col lg={13}>
                <Form.Item className="m-0" name="language">
                  <Select style={{ width: "60%" }} options={LanguageOptions} />
                </Form.Item>
              </Col>
            </Row> */}
            <Divider orientation="left" orientationMargin={0}>
              Sales Invoice
            </Divider>

            <Row className="ps-14">
              {companySettings?.setting?.vouchersSettings
                ?.filter(
                  (vSet: any) => vSet.settingType === SettingsSections.Invoice
                )
                .map((vTemplate: any) => (
                  <>
                    <Col lg={11}>{vTemplate?.title}</Col>
                    <Col lg={13}>
                      <Form.Item className="mb-10" name={vTemplate?.title}>
                        <Select
                          allowClear
                          showSearch
                          labelInValue
                          options={salesAccount.map((value: any) => ({
                            value: value?.id,
                            label: value?.ledgerName,
                            title: Voucher_Type.SalesVoucher.toString(),
                          }))}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Col>
                  </>
                ))}
            </Row>
            <Divider orientation="left" orientationMargin={0}>
              Purchase Bill
            </Divider>

            <Row className="ps-14">
              {companySettings?.setting?.vouchersSettings
                ?.filter(
                  (vSet: any) => vSet.settingType === SettingsSections.PInvoice
                )
                .map((vTemplate: any) => (
                  <>
                    <Col lg={11}>{vTemplate.title}</Col>
                    <Col lg={13}>
                      <Form.Item className="mb-10" name={vTemplate.title}>
                        <Select
                          allowClear
                          showSearch
                          labelInValue
                          options={purchaseAccount.map((value: any) => ({
                            value: value?.id,
                            label: value?.ledgerName,
                            title: Voucher_Type.PurchaseVoucher.toString(),
                          }))}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Col>
                  </>
                ))}
            </Row>
            <Divider orientation="left" orientationMargin={0}>
              Receipt
            </Divider>

            <Row className="ps-14">
              {companySettings?.setting?.vouchersSettings
                ?.filter(
                  (vSet: any) => vSet.settingType === SettingsSections.Receipt
                )
                .map((vTemplate: any) => (
                  <>
                    <Col lg={11}>{vTemplate.title}</Col>
                    <Col lg={13}>
                      <Form.Item className="mb-10" name={vTemplate.title}>
                        <Select
                          allowClear
                          showSearch
                          labelInValue
                          options={bankAccount.map((value: any) => ({
                            value: value?.id,
                            label: value?.ledgerName,
                            title: Voucher_Type.Receipt.toString(),
                          }))}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Col>
                  </>
                ))}
            </Row>

            <Divider orientation="left" orientationMargin={0}>
              Payment
            </Divider>

            <Row className="ps-14">
              {companySettings?.setting?.vouchersSettings
                ?.filter(
                  (vSet: any) => vSet.settingType === SettingsSections.Payment
                )
                .map((vTemplate: any) => (
                  <>
                    <Col lg={11}>{vTemplate.title}</Col>
                    <Col lg={13}>
                      <Form.Item className="mb-10" name={vTemplate.title}>
                        <Select
                          allowClear
                          showSearch
                          labelInValue
                          options={bankAccount.map((value: any) => ({
                            value: value?.id,
                            label: value?.ledgerName,
                            title: Voucher_Type.Payment.toString(),
                          }))}
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        />
                      </Form.Item>
                    </Col>
                  </>
                ))}
            </Row>

            <Divider className="my-7" />

            <Row justify="end" style={{ height: 32 }}>
              {!disable && (
                <Space>
                  <Button
                    icon={<CloseOutlined />}
                    style={{ backgroundColor: "aliceblue" }}
                    onClick={() => {
                      setDisable(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    loading={saveButtonLoading}
                    onClick={() => UpdateGeneral()}
                  >
                    Save
                  </Button>
                </Space>
              )}
            </Row>
          </Form>
        </div>
      </Spin>
    </>
  );
};

export default SettingDefaultLedger;
