import { ApiUtility } from "../../Utilities/ApiUtility.axios";

export enum Limit_Type {
  Yearly,
  Monthly,
  PerTransaction,
}

export enum Transaction_Type {
  Domestic,
  International,
}

class TdsTcsService {
  route = "v1/API";
  // TCS Services

  // https://localhost:7142/v1/API/
  addTCS = (params: any, id?: string) =>
    ApiUtility.post(`${this.route}/Acc_TCS/${id ? id : ""}`, params);

  // https: //localhost:7142/v1/API/Acc_TCS/6717a034990705ff19c4460e
  getTCSByID = (id: string) => ApiUtility.get(`${this.route}/Acc_TCS/${id}`);

  // https: //localhost:7142/v1/API/Acc_TCS/GetTCSList?start=0&length=0
  getTCSList = (
    start: number,
    length: number,
    isActive?: boolean,
    search?: string
  ) => {
    return ApiUtility.getResult(`${this.route}/Acc_TCS/GetTCSList`, {
      start,
      length,
    });
  };

  // TDS Services
  addTDS = (params: any, id?: string) =>
    ApiUtility.post(`${this.route}/Acc_TDS/${id ? id : ""}`, params);

  // https://localhost:7142/v1/API/Acc_TDS/6717b3c9990705ff19c44610
  getTDSByID = (id: string) => ApiUtility.get(`${this.route}/Acc_TDS/${id}`);

  // https://localhost:7142/v1/API/Acc_TDS/GetTDSList?search=asas&start=0&length=15
  getTDSList = (
    start: number,
    length: number,
    isActive?: boolean,
    search?: string,
    companyId?: string
  ) => {
    return ApiUtility.getResult(`${this.route}/Acc_TDS/GetTDSList`, {
      start,
      length,
      companyId,
    });
  };

  // https://localhost:7142/v1/API/Acc_TDS/673c8700e66b827f6547c71c/GetCompanyTDSList?start=0&length=0

  getCompanyTDSList = (
    clientId: string,
    start: number,
    length: number,
    search?: string
  ) =>
    ApiUtility.getResult(
      `${this.route}/Acc_TDS/${clientId}/GetCompanyTDSList`,
      {
        search,
        start,
        length,
      }
    );

  // MakeTDSActiveDeactive;
  makeTDSActiveDeactive = (clientId: string, tdsId: string) =>
    ApiUtility.post(
      `${this.route}/Acc_TDS/${clientId}/MakeTDSActiveDeactive/${tdsId}`,
      {}
    );
}
export default new TdsTcsService();
