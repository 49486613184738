import React, { useEffect, useState } from "react";
import { Tabs, TabsProps } from "antd";
import { MainBreadCrumbs } from "../Common/MainBreadCrumbs";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "../Context/ThemeContext";
import AddItems from "../Pages/Purchase/AddItems";
import AddCategoryModel from "../Pages/Expenses&income/AddCategoryModel";
import AssetModel from "../Pages/Assets/AssetModel";
import DisposedAssestNewModal from "../Pages/Assets/DisposedAssestNewModal";
import { useClient } from "../Context/ClientContext";
import { Product_Module } from "../Services/ClientService";
// import DisposeAssetmodel from "../Pages/Assets/DisposeAssetmodel";

const SalesInvoiceTemplate = (props: any) => {
  const navigate = useNavigate();
  const themeData: any = useTheme();
  const location = useLocation();
  const [tabBreadCrumb, setTabBreadCrumb] = React.useState("");
  const [color, setColor] = React.useState(false);
  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };
  window.addEventListener("scroll", changeColor);
  const { companySettings, currentClient } = useClient();
  const [locationUrl, setLocationUrl] = React.useState<string>("");
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [itemTableTrigger, setItemTableTrigger] =
    React.useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [openItemModel, setOpenItemModel] = React.useState<any>({
    type: "",
    param: {},
  });
  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  React.useEffect(() => {
    let splittedpath = location.pathname.split("/");
    let salesurl = splittedpath[splittedpath.length - 1];
    setLocationUrl(salesurl);
    let breadKey = salesurl.charAt(0).toUpperCase() + salesurl.slice(1);
    setTabBreadCrumb(breadKey);
  }, [location]);

  const onChange = (key: string) => {
    navigate(key);
  };

  const keyconvertor = (item: string) => {
    if (item === "Credit Notes") {
      return "creditNotes";
    } else if (item === "Debit Note") {
      return "debitNote";
    } else if (item === "Purchase Bill") {
      return "purchaseBill";
    } else if (item === "Purchase Out") {
      return "purchaseOut";
    } else if (item === "Purchase Order") {
      return "order";
    } else if (item === "Sales Order") {
      return "order";
    } else if (item === "Payment Out") {
      return "PaymentOut";
    } else if (item === "Delivery Challan") {
      return "deliveryChallan";
    } else if (item === "Fixed Assets") {
      return "fixedAsset";
    } else if (item === "Share Capital") {
      return "sharecapital";
    } else if (item === "Bank Accounts") {
      return "bankAccounts";
    } else if (item === "Cash In Hand") {
      return "cashInHand";
    } else if (item === "Loan Accounts") {
      return "loanAccounts";
    } else if (item === "Aged Debtors") {
      return "debtors";
    } else if (item === "Aged Creditors") {
      return "creditors";
    } else if (item === "Bank Reconciliation") {
      return "bankReconciliation";
    } else if (item === "Item Group") {
      return "itemGroup";
    } else if (item === "Company Info") {
      return "companyInfo";
    } else if (item === "Payment Term") {
      return "paymentTerm";
    } else if (item === "Opening Balance") {
      return "openingBalance";
    } else if (item === "Default Ledger") {
      return "defaultLedger";
    } else if (item === "Terms And Conditions") {
      return "termsandConditions";
    } else if (item === "Item Groups") {
      return "itemgroups";
    } else if (item === "Item Category") {
      return "itemCategory";
    } else if (item === "Item Locations") {
      return "itemlocations";
    } else {
      return item.trim().toLowerCase();
    }
  };

  const createMenuItem = (
    item: { showData: string; addButton: string },
    onChange: (key: string) => void,
    isEnabled: boolean
  ) => {
    const key = keyconvertor(item?.showData);
    const label = (
      <div>
        {item?.showData && (
          <span style={{ padding: "0px 14px" }} onClick={() => onChange(key)}>
            <span>{item?.showData}</span>
          </span>
        )}
      </div>
    );
    return isEnabled ? { key, label } : false;  
  };

  const conditionHandlers: Record<string, (item: any) => boolean> = {    
    TDS: () => companySettings?.setting?.tdsDetails?.isTDSEnabled! === true,
    "Sales Order": () =>
      currentClient?.productModule !== Product_Module.Billing,
    "Purchase Order": () =>
      currentClient?.productModule !== Product_Module.Billing,
  };

  const items: TabsProps["items"] | any = props.menuprops?.map((item: any) => {
    const isConditionMet = conditionHandlers[item?.showData]?.(item);
    // console.log("isConditionMet", isConditionMet);

    if (isConditionMet !== undefined) {
      return createMenuItem(item, onChange, isConditionMet);
    }

    // Default case
    return {
      key: keyconvertor(item?.showData),
      label: (
        <div>
          {item?.showData && (
            <span
              style={{ padding: "0px 14px" }}
              onClick={() => onChange(keyconvertor(item.showData))}
            >
              <span>{item?.showData}</span>
            </span>
          )}
        </div>
      ),
      children: "",
    };
  });

  // const items1: TabsProps["items"] | any = props.menuprops?.map(
  //   (item: { showData: string; addButton: string }, i: number) => {
  //     if (item?.showData === "TDS") {
  //       return companySettings?.setting?.tdsDetails?.isTDSEnabled
  //         ? {
  //             key: keyconvertor(item?.showData),
  //             label: (
  //               <div>
  //                 {item?.showData && (
  //                   <span
  //                     style={{ padding: "0px 14px" }}
  //                     onClick={() => onChange(keyconvertor(item.showData))}
  //                   >
  //                     <span>{item?.showData}</span>
  //                   </span>
  //                 )}
  //               </div>
  //             ),
  //           }
  //         : false;
  //     } 
  //     return {
  //       key: keyconvertor(item?.showData),
  //       label: (
  //         <div>
  //           {item?.showData && (
  //             <span
  //               style={{ padding: "0px 14px" }}
  //               onClick={() => onChange(keyconvertor(item.showData))}
  //             >
  //               <span>{item?.showData}</span>
  //             </span>
  //           )}
  //           {/* <span className="ca-hover-show">
  //             {item?.addButton &&
  //               (item?.addButton === "salesItem" ||
  //                 item?.addButton === "purchaseItem") && (
  //                 <PlusOutlined
  //                   style={{
  //                     paddingLeft: "15px",
  //                     margin: "0px",
  //                     color: "#1677FF",
  //                   }}
  //                   onClick={() => {
  //                     setOpenItemModel({
  //                       type: "addItem",
  //                       param: { open: true },
  //                     });
  //                     setItemTableTrigger(true);
  //                   }}
  //                 />
  //               )}
  //             {item?.addButton === "category" && (
  //               <PlusOutlined
  //                 style={{
  //                   paddingLeft: "15px",
  //                   margin: "0px",
  //                   color: "#1677FF",
  //                 }}
  //                 onClick={() => {
  //                   setOpenItemModel({
  //                     type: "addCategory",
  //                     param: { itemType: item?.addButton, open: true },
  //                   });
  //                   setItemTableTrigger(true);
  //                 }}
  //               />
  //               // </Link>
  //             )}
  //             {item?.addButton === "fixedasset" && (
  //               <PlusOutlined
  //                 style={{
  //                   paddingLeft: "15px",
  //                   margin: "0px",
  //                   color: "#1677FF",
  //                 }}
  //                 onClick={() => {
  //                   setOpenItemModel({
  //                     type: "addFixedAsset",
  //                     param: { itemType: item?.addButton, open: true },
  //                   });
  //                   setItemTableTrigger(true);
  //                 }}
  //               />
  //               // </Link>
  //             )}
  //             {item?.addButton === "disposed" && (
  //               <PlusOutlined
  //                 style={{
  //                   paddingLeft: "15px",
  //                   margin: "0px",
  //                   color: "#1677FF",
  //                 }}
  //                 onClick={() => {
  //                   setOpenItemModel({
  //                     type: "addDisposed",
  //                     param: { itemType: item?.addButton, open: true },
  //                   });
  //                 }}
  //               />
  //             )}
  //             {item?.addButton &&
  //               item?.addButton !== "salesItem" &&
  //               item?.addButton !== "purchaseItem" &&
  //               item?.addButton !== "category" &&
  //               item?.addButton !== "fixedasset" &&
  //               item?.addButton !== "disposed" && (
  //                 <Link to={item?.addButton}>
  //                   <PlusOutlined
  //                     style={{ paddingLeft: "14px", margin: "0px" }}
  //                     onClick={() => onChange(item.addButton)}
  //                   />
  //                 </Link>
  //               )}
  //           </span> */}
  //         </div>
  //       ),
  //       children: "",
  //     };
  //   }
  // );

  return (
    <>
      <MainBreadCrumbs
        breadCrumbItems={[...props.breadcrumprops, { item: tabBreadCrumb }]}
      />
      {width <= 768 ? (
        <Tabs
          size="small"
          className="Tabs-Responsive customTab"
          onChange={onChange}
          type="card"
          items={items}
        />
      ) : (
        locationUrl !== "" && (
          <Tabs
            activeKey={locationUrl}
            defaultActiveKey={locationUrl}
            items={items}
            className="ca-header-tab"
            tabBarGutter={11}
            tabBarStyle={{
              // backgroundColor: "#f5f8ff",
              // backgroundColor: "white",
              backgroundColor: "rgb(247, 248, 250)",
              position: "fixed",
              height: "32px",
              boxShadow: color ? "1px 2px 5px 4px red" : "",
              fontFamily: "'Open Sans','sans-serif'",
              fontWeight: 500,
              borderRadius: "6px",
              // marginLeft: "38px",
              // width:
              //   width <= 1024
              //     ? "91%"
              //     : themeData?.themeData?.silderSize
              //     ? "83%"
              //     : "calc(100vw - 100px)",
              width: "100%",
              // marginTop: "8px",
              // marginTop: "20px",
              zIndex: 5,
            }}
          />
        )
      )}
      <div>
        <div
          style={{
            // padding: "10px 20px 0px 20px",
            padding: "10px 15px 0px",
            // margin: width <= 768 ? "-20px 0px 0px 0px" : "40px 0px 0px 0px",
            margin: width <= 768 ? "-20px 0px 0px 0px" : "1px 0px 0px ",
            overflowY: "auto",
            // height: `calc(100vh - 112px)`,
            height: `calc(100vh - 74px)`,
            // backgroundColor: themeData?.themeData?.darkMode ? "" : "white",
            backgroundColor: themeData?.themeData?.darkMode ? "" : "#f7f8fa",
          }}
        >
          {props.element}
        </div>
      </div>
      {/*--------------- Add Items Props --------------- */}
      {openItemModel.type === "addItem" && (
        <AddItems
          open={openItemModel?.param.open}
          onCancel={() => setOpenItemModel({ type: "", param: {} })}
          setTrigger={setTrigger}
          itemPage={openItemModel?.param.itemType}
        />
      )}
      {openItemModel.type === "addCategory" && (
        <AddCategoryModel
          isModalOpen={openItemModel?.param.open}
          setIsModalOpen={() => setOpenItemModel({ type: "", param: {} })}
          editCategory={{ id: null }}
        />
      )}
      {openItemModel.type === "addFixedAsset" && (
        <AssetModel
        // isModalOpen={openItemModel?.param.open}
        // setIsModalOpen={() => setOpenItemModel({ type: "", param: {} })}
        // editAsset={{ id: null }}
        // isConfirmation={false}
        // modelCancel={function (): void {
        //   throw new Error("Function not implemented.");
        // }}
        // setIsConfirmation={function (
        //   value: React.SetStateAction<boolean>
        // ): void {
        //   throw new Error("Function not implemented.");
        // }}
        // setTrigger={function (value: React.SetStateAction<boolean>): void {
        //   throw new Error("Function not implemented.");
        // }}
        />
      )}
      {openItemModel.type === "addDisposed" && (
        <DisposedAssestNewModal
          setReRender={setTrigger}
          reRender={trigger}
          isDisposeOpen={openItemModel?.param.open}
          setIsDisposeOpen={() => setOpenItemModel({ type: "", param: {} })}
        />
      )}
    </>
  );
};

export default SalesInvoiceTemplate;
