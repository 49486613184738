import React, { useContext, useState } from "react";
import {
  Button,
  Col,
  Row,
  Input,
  DatePicker,
  Table,
  Tooltip,
  Form,
  Select,
  Card,
  Skeleton,
  Space,
  Popover,
  Tag,
} from "antd";
import {
  DeleteTwoTone,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import "./Journals.css";
import { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import "../../Common/Acc_Common.css";
//import JournalService from "../../Services/JournalService";
import VoucherServices, {
  OrderStatus,
  VoucherFilterStatus,
  Voucher_Type,
} from "../../Services/VoucherServices";
import { useTheme } from "../../Context/ThemeContext";
import { dayjs } from "../../../Utilities/dayjs";
import ViewJournal from "./ViewJournal";
import { Utils } from "../../../Utilities/Utils";
import noteContext from "../../Context/StyleContext";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import search from "../../Images/search.svg";
import { TransactionTypes } from "../../../Types/CommonConstants";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";
import InvoiceExportService from "../../Services/InvoiceExportDetails";
import { useHotkeys } from "react-hotkeys-hook";
import DraftService from "../../Services/DraftService";
import { FiDownload } from "react-icons/fi";
import DownloadService, { ViewType } from "../../Services/DownloadService";
import ViewPreviewModel from "../Sales/ViewPreviewModel";

// export const TransactionTypeNames = [
//   "Unknown",
//   "Journal",
//   "Sales Invoice",
//   "Purchase Invoice",
//   "Credit Note",
//   "Purchase Credit Note",
//   "Receipt",
//   "Payment",
//   "Bad Debts",
//   "Fixed Asset",
//   "Depreciate",
//   "Dividend Accrual",
//   "Dividend Payment",
// ];

const JournalsTable: React.FC = () => {
  const navigate = useNavigate();
  const { RangePicker } = DatePicker;
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const { currentClient, companySettings } = useClient();
  const { themeData } = useTheme();
  const { windowWidth } = useContext(noteContext);
  const { openNotification } = useContext(notificationContext);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [open, setIsOpen] = React.useState<any>("");
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<VoucherFilterStatus>(
    VoucherFilterStatus.Active
  );
  const [loadingButtons, setLoadingButtons] = useState<any>({});

  const [journalId, setJournalId] = React.useState<string>("");
  const [journalRefNo, setJournalRefNo] = React.useState<number>();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [voucherDetailData, setVoucherDetailData] = React.useState<any>([]);
  //const [journalList, setJournalList] = React.useState<any[]>([]);
  const [searchJournal, setSearchJournal] = React.useState<string>("");
  const [filterDate, setFilterDate] = React.useState<any>(
    JSON.parse(localStorage.getItem("LockPeriod")!)
      ? {
          from: JSON.parse(localStorage.getItem("LockPeriod")!).from,
          to: JSON.parse(localStorage.getItem("LockPeriod")!).to,
        }
      : {
          from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
          to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
        }
  );

  const [totAmount, setTotAmount] = React.useState<number>(0);
  const [deleteOrRevive, setDeleteOrRevive] = React.useState<any>({
    id: "",
    isRev: false,
  });
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [exportType, setExportType] = React.useState<number>(-1);
  const [exportjournalData, setExportJournalData] = React.useState<any[]>([]);

  let splitPathInVchType: Voucher_Type;

  interface IJournalList {
    key: React.Key;
    id: string;
    number: number;
    ledgerName: string;
    date: any;
    amount: number;
    voucherStatus: number;
  }
  // interface DataType {
  //   id: string;
  //   key: string;
  //   date: string;
  //   journalno: string;
  //   reference: string;
  //   amount: string;
  //   type: number;
  // }
  const downloadAndView = async (
    cliendId: string,
    voucherId: string,
    type: ViewType
    // invId: any
  ) => {
    if (cliendId && voucherId) {
      setLoadingButtons((prev: any) => ({ ...prev, [voucherId]: true }));
      await DownloadService.downloadPdf(
        cliendId,
        voucherId,
        type,
        Voucher_Type.Journal
      )
        .then((res) => {
          console.log("res", res);
          if (res) {
            console.log("download sucess");
            setLoadingButtons((prev: any) => ({ ...prev, [voucherId]: false }));
          }
        })
        .catch((ex) => {
          openNotification("error", ex.message);
        });
    } else {
      openNotification("error", "Invalid Details");
      setLoadingButtons(false);
    }
  };
  const columns: ColumnsType<IJournalList> = [
    {
      title: "Date",
      dataIndex: "date",
      key: "Date",
      width: "10%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Ref No.",
      dataIndex: "number",
      width: "10%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <>
            {record?.voucherStatus !== 1 ? (
              <>
                <span
                  style={{ color: "#1677FF", cursor: "pointer" }}
                  onClick={() => {
                    setIsOpen("viewPreview");
                    setJournalId(record?.id);
                  }}
                >
                  {record?.number}
                </span>
              </>
            ) : (
              <>
                <span style={{ color: "red" }}>{record?.number}</span>
              </>
            )}
          </>
        </Skeleton>
      ),
    },
    {
      title: "Account Name",
      dataIndex: "ledgerName",
      key: "account",
      // sorter: true,
      width: "25%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.ledgerName}
        </Skeleton>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      align: "right",
      // width: "10%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.voucherStatus !== 1 ? (
            <>₹ {Utils.getFormattedNumber(record?.amount)}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ₹ {Utils.getFormattedNumber(record?.amount)}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Action",
      dataIndex: "icon2",
      align: "center",
      width: "10%",
      render: (_, record) => (
        <Skeleton
          active
          paragraph={{ rows: 1 }}
          loading={loading}
          title={false}
          className="cursor"
        >
          {record?.voucherStatus !== 1 ? (
            <>
              <Row gutter={13} justify={"center"}>
                {/* <Col>
                  <Button
                    size="small"
                    icon={<EyeOutlined />}
                    style={{ border: "none", color: "#395cd2" }}
                    loading={!!loadingButtons[record.id]}
                    onClick={() =>
                      // downloadAndView(
                      //   currentClient?.id!,
                      //   record?.id,
                      //   ViewType.Pdf
                      //   // record.invNumber?.id
                      // )
                    }
                  />
                </Col> */}
                <Col>
                  <Button
                    size="small"
                    icon={<FiDownload />}
                    style={{ border: "none", color: "#395cd2" }}
                    loading={!!loadingButtons[record.id]}
                    onClick={() =>
                      downloadAndView(
                        currentClient?.id!,
                        record?.id,
                        ViewType.Pdf
                        // record.invNumber?.id
                      )
                    }
                  />
                </Col>
                <Col>
                  <Tooltip
                    title="Edit"
                    placement="top"
                    arrow={true}
                    color="#444653"
                  >
                    <EditOutlined
                      className="cursor"
                      onClick={() => {
                        status !== VoucherFilterStatus.Draft
                          ? navigate(
                              `/accounts/clients/${currentClient?.id}/journals/${record?.id}`
                            )
                          : navigate(
                              `/accounts/clients/${currentClient?.id}/journals/editDraft/${record?.id}`
                            );
                      }}
                    />
                  </Tooltip>
                </Col>
                <Tooltip
                  title="Delete"
                  placement="top"
                  arrow={true}
                  color="red"
                >
                  <DeleteTwoTone
                    className="ca-delete-btn cursor"
                    twoToneColor="#ff879d"
                    onClick={() => {
                      setConfirmation(true);
                      setJournalId(record?.id);
                      setJournalRefNo(record?.number);
                    }}
                  />
                </Tooltip>
              </Row>
            </>
          ) : (
            <>
              <Tooltip title="Revive Voucher">
                <SyncOutlined
                  style={{ color: "green" }}
                  onClick={() => {
                    setJournalRefNo(record?.number);
                    navigate(
                      `/accounts/clients/${currentClient?.id}/journals/${record?.id}`
                    );
                  }}
                />
              </Tooltip>
            </>
          )}
        </Skeleton>
      ),
    },
  ];

  // if (status === "deleted") {
  //   columns.push({
  //     width: "25%",
  //     align: "left",
  //     render: (_, record) => (
  //       <Skeleton
  //         active
  //         loading={loading}
  //         paragraph={{ rows: 1 }}
  //         title={false}
  //         className="cursor"
  //       >
  //         <Tooltip title="Revive journal and its effects on calculations">
  //           <SyncOutlined
  //             className="ps-32"
  //             onClick={() => {
  //               setConfirmation(true);
  //               setJournalId(record?.id);
  //             }}
  //           />
  //         </Tooltip>
  //       </Skeleton>
  //     ),
  //   });
  // } else {
  //   columns.push(
  //     {
  //       title: "  ",
  //       align: "center",
  //       dataIndex: "editicon",
  //       width: "5%",
  //       render: (_, record) => (
  //         <Skeleton
  //           active
  //           loading={loading}
  //           paragraph={{ rows: 1 }}
  //           title={false}
  //           className="cursor"
  //         >
  //           <Tooltip
  //             title={
  //               record?.type === TransactionTypes.FixedAsset ||
  //               record?.type === TransactionTypes.Depreciate
  //                 ? `Editing is disabled as journal is posted from ${
  //                     TransactionTypeNames[record?.type]
  //                   }`
  //                 : "Edit"
  //             }
  //             placement="top"
  //             color={
  //               record?.type === TransactionTypes.FixedAsset ||
  //               record?.type === TransactionTypes.Depreciate
  //                 ? "gray"
  //                 : "#3D4998"
  //             }
  //           >
  //             <EditOutlined
  //               style={{
  //                 color:
  //                   record?.type === TransactionTypes.FixedAsset ||
  //                   record?.type === TransactionTypes.Depreciate
  //                     ? "rgb(216 207 207)"
  //                     : "#444653",
  //               }}
  //               className={
  //                 record?.type === TransactionTypes.FixedAsset ||
  //                 record?.type === TransactionTypes.Depreciate
  //                   ? ""
  //                   : "ca-edit-btn"
  //               }
  //               onClick={() => {
  //                 record?.type !== TransactionTypes.FixedAsset &&
  //                   record?.type !== TransactionTypes.Depreciate &&
  //                   navigate(
  //                     `/accounts/clients/${currentClient?.id}/journals/${record?.id}`
  //                   );
  //               }}
  //             />
  //           </Tooltip>
  //         </Skeleton>
  //       ),
  //     },
  //     {
  //       title: "  ",
  //       dataIndex: "deleteicon",
  //       align: "left",
  //       width: "20%",
  //       render: (_, record) => (
  //         <Skeleton
  //           active
  //           loading={loading}
  //           paragraph={{ rows: 1 }}
  //           title={false}
  //           className="cursor"
  //         >
  //           <Tooltip
  //             title={
  //               record?.type === TransactionTypes.FixedAsset ||
  //               record?.type === TransactionTypes.Depreciate
  //                 ? `Deleting is disabled as journal is posted from ${
  //                     TransactionTypeNames[record?.type]
  //                   }`
  //                 : "Delete"
  //             }
  //             placement="top"
  //             color={
  //               record?.type === TransactionTypes.FixedAsset ||
  //               record?.type === TransactionTypes.Depreciate
  //                 ? "gray"
  //                 : "red"
  //             }
  //           >
  //             <DeleteTwoTone
  //               className={
  //                 record?.type === TransactionTypes.FixedAsset ||
  //                 record?.type === TransactionTypes.Depreciate
  //                   ? "ps-6"
  //                   : "ca-delete-btn"
  //               }
  //               twoToneColor={
  //                 record?.type === TransactionTypes.FixedAsset ||
  //                 record?.type === TransactionTypes.Depreciate
  //                   ? "rgb(216 207 207)"
  //                   : "#ff879d"
  //               }
  //               onClick={() => {
  //                 if (
  //                   record?.type !== TransactionTypes.FixedAsset &&
  //                   record?.type !== TransactionTypes.Depreciate
  //                 ) {
  //                   setConfirmation(true);
  //                   setJournalId(record?.id);
  //                 }
  //               }}
  //             />
  //           </Tooltip>
  //         </Skeleton>
  //       ),
  //     }
  //   );
  // }
  // --------- Get TableData API ---------

  React.useEffect(() => {
    if (currentClient?.id && (exportType === -1 || exportType)) {
      getJournalList(
        currentClient?.id,
        Voucher_Type.Journal,
        dayjs(filterDate.from, "DD/MM/YYYY").toISOString(),
        dayjs(filterDate.to, "DD/MM/YYYY").endOf("day").toISOString(),
        exportType >= 1 ? 0 : (page - 1) * pageSize,
        exportType >= 1 ? 0 : pageSize,
        filter.sortCol,
        filter.sortDir,
        undefined,
        undefined,
        searchJournal,
        status
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    searchJournal,
    filterDate.from,
    filterDate.to,
    status,
    refreshAtDelete,
    exportType,
  ]);

  const getJournalList = async (
    clientId: string,
    voucherType: Voucher_Type = 10,
    fromDate: string,
    toDate: string,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    noPartyId?: string,
    npReconciled?: string,
    searchJournal?: string,
    status?: VoucherFilterStatus
  ) => {
    if (exportType < 1) {
      setLoading(true);
    }
    const res =
      status === VoucherFilterStatus.Draft
        ? await DraftService.getVoucherDraftList(
            clientId,
            voucherType,
            fromDate,
            toDate,
            start,
            length,
            sortCol,
            sortDir,
            noPartyId
          )
        : await VoucherServices.getVoucharList(
            clientId,
            voucherType,
            fromDate,
            toDate,
            start,
            length,
            sortCol,
            sortDir,
            noPartyId,
            npReconciled,
            searchJournal,
            status
          );
    setTotAmount(res?.result?.totalAmount);
    let journalData = res?.result?.voucherItems
      ?.map((el: any, index: number) => {
        //  console.log("This is my data", el);
        return {
          id: el?.vId,
          key: `${el?.vId}-${index}`, // Unique key using vId and index
          date: dayjs(el?.date).format("DD/MM/YYYY"),
          number: el?.number,
          ledgerName: el?.pLedger[0].ledgerName,
          amount: el?.invAmount,
          voucherStatus: el?.voucherStatus,
        };
      })
      .flat();
    if (exportType >= 1) {
      setExportJournalData(journalData);
      if (exportType === 1) {
        exportCsv(journalData);
      } else if (exportType === 2) {
        exportPDF(journalData);
      } else if (exportType === 3) {
        exportExcel(journalData);
      }
    } else {
      setTotalRecords(res.result?.totalRecords);
      setLoading(false);
      setVoucherDetailData(journalData);
    }

    // setVoucherDetailsData(res);
    // .catch((ex: any) => {
    //   console.error(ex.message);
    //   setLoading(false);
    //   //setVoucherDetailsData([]);
    // });
  };
  //console.log(voucherDetailData, "dataAll");

  // ----- Delete or revive api -----
  React.useEffect(() => {
    if (deleteOrRevive.id) {
      const DeleteOrRevive = async () => {
        setLoading(true);
        try {
          if (status === VoucherFilterStatus.Draft) {
            const deleteData = await DraftService.deleteDraft(
              currentClient?.id!,
              deleteOrRevive.id
            );
            if (deleteData.result) {
              openNotification("success", "Delete Sucessfully");
              setRefreshAtDelete(!refreshAtDelete);
            }
          } else {
            const res = await VoucherServices.deleteVoucher(
              currentClient?.id!,
              deleteOrRevive.id
            );
            if (res.result) {
              setRefreshAtDelete(!refreshAtDelete);
              openNotification(
                "success",
                `Entry ${
                  status === VoucherFilterStatus.Deleted ? `revived` : `deleted`
                } successfully ${deleteOrRevive.number} `
              );
            } else {
              openNotification("error", res.message);
            }
          }
        } catch (err: any) {
          console.error("error..", err);
          openNotification("error", err.message);
        }
        setDeleteOrRevive({ id: "", isRev: false, number: "" }); //due to this component is rerendering twice
      };
      DeleteOrRevive();
      setConfirmation(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteOrRevive?.id]);

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "Date",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
  };

  // const exportCsv = () => {
  //   VoucherServices.exportCsv(
  //     currentClient?.id,
  //     Voucher_Type.Journal,
  //     (page - 1) * pageSize,
  //     pageSize,
  //     filterDate.from,
  //     filterDate.to,
  //     filter.sortCol,
  //     filter.sortDir,
  //     undefined,
  //     undefined,
  //     searchJournal,
  //     status
  //     // searchCustomer
  //     // invoiceStatus
  //   )
  //     .then((res) => {
  //       if (res) {
  //         console.log("download sucess");
  //       }
  //     })
  //     .catch((ex: any) => {
  //       console.error("error..", ex.message);
  //       openNotification("error", ex.message);
  //     });
  // };
  // ----- Short-Cut for Open Model -----F
  // useKeyboardShortcuts("N", ["shiftKey"], () => {
  //   navigate(`/accounts/clients/${currentClient?.id}/journals/add`);
  // });
  useHotkeys("shift+n", () => {
    navigate(`/accounts/clients/${currentClient?.id}/journals/add`);
  });

  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => {
      if (col === "Amount") {
        return `${Utils.getFormattedNumber(totAmount)}`;
      } else if (col === "Account Name") {
        return "Total";
      } else {
        return ""; // Empty string for other columns
      }
    });
  };

  let path = "Journal List";
  const columnsA = ["Date", "Ref No", "Account Name", "Amount"];
  console.log("jData", voucherDetailData);

  const fieldMapping = {
    Date: (item: any) => item?.date,
    "Ref No": (item: any) => item?.number,
    "Account Name": (item: any) => item?.ledgerName,
    Amount: (item: any) => Utils.getFormattedNumber(item?.amount),
  };

  const exportCsv = (exportData: any[]) => {
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      filterDate,
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (exportData: any[]) => {
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const exportExcel = async (exportData: any[]) => {
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportjournalData.length > 0) {
              exportCsv(exportjournalData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportjournalData.length > 0) {
              exportPDF(exportjournalData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSX"
          onClick={() => {
            if (exportjournalData.length > 0) {
              exportExcel(exportjournalData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSX
        </Button>
      </div>
    </Space>
  );

  const getQuarterPreset: any = (startMonth: number) => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() + startMonth).startOf("month"),
      fromDate.month(fromDate.month() + startMonth + 2).endOf("month"),
    ];
  };

  const thisYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month()).startOf("month"),
      fromDate.month(fromDate.month() + 11).endOf("month"),
    ];
  };

  const lastYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() - 12).startOf("month"),
      fromDate.month(fromDate.month() - 1).endOf("month"),
    ];
  };

  const getLast15Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(15, "day").startOf("day"); // Start 15 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };
  const getLast7Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(7, "day").startOf("day"); // Start 7 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };

  const getThisMonth = () => {
    const now = dayjs();
    return [now.startOf("month"), now.endOf("month")];
  };
  const getLastMonth = () => {
    const now = dayjs();
    const lastMonth = now.subtract(1, "month");
    return [lastMonth.startOf("month"), lastMonth.endOf("month")];
  };
  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        marginTop: "6px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <React.Fragment>
        <Row style={{ marginTop: 10 }}>
          <Col lg={10} md={24} xxl={12} xl={10}>
            <Space size={5}>
              {/* <Tooltip
                title={
                  <>
                    Add Journal
                    <br />
                    <Tag>
                      <kbd>Shift + F3</kbd>
                    </Tag>
                  </>
                }
              > */}
              <Button
                type="primary"
                style={{
                  color: "white",
                  marginBottom: "0px",
                }}
                onClick={() => {
                  navigate(
                    `/accounts/clients/${currentClient?.id}/journals/add`
                  );
                }}
              >
                <PlusOutlined />
                Journal
              </Button>
              {/* </Tooltip> */}
              {voucherDetailData?.length === 0 ? (
                <>
                  <Button type="default" icon={<DownloadOutlined />} disabled>
                    Export
                  </Button>
                </>
              ) : (
                <>
                  <Popover
                    content={content}
                    placement="rightTop"
                    trigger="hover"
                  >
                    <Button
                      type="default"
                      icon={<DownloadOutlined />}
                      // onClick={() => exportCsv()}
                    >
                      Export
                    </Button>
                  </Popover>
                </>
              )}
            </Space>
          </Col>
          <Col lg={12} md={24} xxl={12}>
            <Row justify="end" gutter={8}>
              <Col>
                {/* <Form.Item name="search" className="inputBoxMb"> */}
                <Input
                  name="search"
                  style={{ width: "200px", border: "1px solid #f1f3ff" }}
                  placeholder="Search Ledger"
                  allowClear
                  onChange={(e: any) => {
                    setLoading(true);
                    if (!e.cancelable) {
                      const searchItem = setTimeout(() => {
                        setPage(1);
                        setSearchJournal(e.target.value);
                      }, 500);
                      return () => clearTimeout(searchItem);
                    } else {
                      setSearchJournal(e.target.value);
                    }
                    setExportJournalData([]);
                    setExportType(-1);
                  }}
                  suffix={
                    loading || searchJournal ? (
                      ""
                    ) : (
                      <img alt="search" src={search} />
                    )
                  }
                />
                {/* </Form.Item> */}
              </Col>
              <Col>
                <RangePicker
                  defaultValue={[
                    dayjs(filterDate?.from, "DD/MM/YYYY"),
                    dayjs(filterDate?.to, "DD/MM/YYYY"),
                  ]}
                  format="DD/MM/YYYY"
                  style={{
                    width:
                      width <= 464 ? "230px" : width == 375 ? "200px" : "250px",
                    border: "1px solid #f1f3ff",
                  }}
                  onChange={(e: any) => {
                    // console.log(e, "date");
                    if (e === null) {
                      setFilterDate({});
                    } else {
                      setFilterDate({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      });
                      localStorage.setItem(
                        "LockPeriod",
                        JSON.stringify({
                          ...filterDate,
                          from: dayjs(e[0]).format("DD/MM/YYYY"),
                          to: dayjs(e[1]).format("DD/MM/YYYY"),
                        })
                      );
                      openNotification("success", `Period locked.`);
                    }
                    setExportJournalData([]);
                    setExportType(-1);
                  }}
                  presets={[
                    {
                      label: "Today",
                      value: [dayjs().add(0, "d"), dayjs()],
                    },
                    {
                      label: "Last 7 Days",
                      value: getLast7Days(),
                    },
                    {
                      label: "Last 15 Days",
                      value: getLast15Days(),
                    },
                    {
                      label: "This Month",
                      value: getThisMonth(),
                    },
                    {
                      label: "Last Month",
                      value: getLastMonth(),
                    },
                    {
                      label: "Quarter 1",
                      value: getQuarterPreset(0),
                    },
                    {
                      label: "Quarter 2",
                      value: getQuarterPreset(3),
                    },
                    {
                      label: "Quarter 3",
                      value: getQuarterPreset(6),
                    },
                    {
                      label: "Quarter 4",
                      value: getQuarterPreset(9),
                    },
                    {
                      label: "This Year",
                      value: thisYear(),
                    },
                    {
                      label: "Last Year",
                      value: lastYear(),
                    },
                  ]}
                />
              </Col>
              <Col>
                <Select
                  value={status}
                  style={{ width: windowWidth === 1024 ? 80 : 120 }}
                  options={[
                    { value: VoucherFilterStatus.All, label: "All" },
                    { value: VoucherFilterStatus.Active, label: "Active" },
                    { value: VoucherFilterStatus.Deleted, label: "Deleted" },
                    { value: VoucherFilterStatus.Draft, label: "Draft" },
                  ]}
                  onSelect={(val: VoucherFilterStatus) => {
                    setPage(1);
                    setStatus(val);
                    setExportJournalData([]);
                    setExportType(-1);
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Table
          className={`Tabel-style table-${
            themeData?.componentSize ?? "middle"
          }`}
          locale={{
            triggerDesc: "",
            triggerAsc: "",
            cancelSort: "",
          }}
          dataSource={
            loading
              ? (Array.from({ length: pageSize }, (_, index) => ({
                  key: `loading-${index}`,
                })) as IJournalList[])
              : voucherDetailData
          }
          columns={columns}
          onChange={tableSort}
          scroll={
            pageSize > 15 ? { x: 1500, y: window.innerHeight - 300 } : undefined
          }
          pagination={{
            total: totalRecords,
            current: page,
            pageSize: pageSize,
            showSizeChanger: true,
            onChange: (page: number, pageSize: number) => {
              setPage(page);
              setPageSize(pageSize);
            },
            pageSizeOptions: ["10", "15", "25", "50", "100"],
            showTotal: (totalRecords, page) =>
              `${page[0]}-${page[1]} of ${totalRecords} items`,
          }}
          summary={() => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row
                  style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
                >
                  <Table.Summary.Cell
                    index={1}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={2}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={3} className="fw600">
                    Total
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} className="fw600" align="right">
                    ₹ {Utils.getFormattedNumber(totAmount)}
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={5} className="fw600">
                    {"   "}
                  </Table.Summary.Cell>
                  {/* <Table.Summary.Cell index={6} className="fw600">
                    {"   "}
                  </Table.Summary.Cell> */}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
        {confirmation && (
          <ConfirmationModal
            open={confirmation}
            onNo={() => setConfirmation(false)}
            onYes={() => {
              setDeleteOrRevive({
                id: journalId,
                isRev: status === VoucherFilterStatus.Deleted ? true : false,
              });
              // setRefreshAtDelete(!refreshAtDelete);
            }}
            text={
              status === VoucherFilterStatus.Deleted
                ? `Are you sure you want to revive "${journalRefNo}?`
                : `Are you sure you want to delete "${journalRefNo}"?`
            }
          />
        )}
        {open === "viewPreview" && (
          <ViewPreviewModel
            open={open === "viewPreview"}
            onCancel={() => setIsOpen("")}
            voucherId={journalId}
            invVoucharType={Voucher_Type.Journal}
            partyId={currentClient?.id}
          />
        )}
        {open === "ViewJournal" && (
          <ViewJournal
            open={open}
            onCancel={() => setIsOpen(false)}
            journalId={journalId}
          />
        )}
      </React.Fragment>
    </Card>
  );
};

export default JournalsTable;
