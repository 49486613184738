import { ApiUtility } from "../../Utilities/ApiUtility.axios";

class ItemCategoryService {
  private route = "/v1/API/Acc_ItemCategorys";

  getCategoryList = (
    companyId: string | undefined,
    search?: string,
    sortCol?: number,
    sortDir?: number
  ) =>
    // ApiUtility.get(
    //   `${this.route}/${companyId}/GetList?sortCol=${sortCol}&sortDir=${sortDir}&search=${search}`
    // );
    ApiUtility.get(`${this.route}/${companyId}/GetList`, {
      sortCol,
      sortDir,
      search,
    });

  getCategoryDatabyId = (companyId: string | undefined, categoryId: string) =>
    ApiUtility.get(`${this.route}/${companyId}/${categoryId}`);

  deleteCategoryDatabyId = (
    companyId: string | undefined,
    categoryId: string
  ) =>
    ApiUtility.delete(`${this.route}/${companyId}/deleteCategory/${categoryId}`);

  postCategoryData = (
    companyId: string | undefined,
    categoryId: string,
    modal: any
  ) =>
    ApiUtility.post(
      `${this.route}/${companyId}/${categoryId ? categoryId : ""}`,
      modal
    );
}
export default new ItemCategoryService();
