import { BusinessTypes, CompanyGSTTypes } from "../../Types";
import { IIdName } from "../../Types/CommonTypes";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { Dayjs } from "dayjs";
import { Voucher_Type } from "./VoucherServices";
import { Setting_Type } from "./UsersService";

export enum SettingsSections {
  All,
  General,
  Invoice,
  ExportInvoice,
  DeemedExportInvoice,
  SEZInvoice,
  CrNote,
  Receipt,
  Quotation,
  SOrder,
  PInvoice,
  ExportBill,
  DeemedExportBill,
  SEZBill,
  RCMBill,
  ExpenseJV,
  POrder,
  Payment,
  DrNote,
  Journal,
  Contra,
  DChallan,
  RChallan,
  StockVoucher,
  Asset,
  Dividend,
  Item,
  DueOn,
  GstDetails,
  TBSettings,
  CompanyInfo,
  ContactDetails,
  Logo,
  Template,
  DefaultTemplate,
  JnlTemplate,
  LedgerTemplate,
  DefaultLedger,
}

export enum Ordinals {
  Last,
  First,
  Second,
  Third,
  Fourth,
  Fifth,
  Sixth,
  Seventh,
  Eighth,
  Ninth,
  Tenth,
  Eleventh,
  Twelfth,
  Thirteenth,
  Fourteenth,
  Fifteenth,
  Sixteenth,
  Seventeenth,
  Eighteenth,
  Nineteenth,
  Twentieth,
  TwentyFirst,
  TwentySecond,
  TwentyThird,
  TwentyFourth,
  TwentyFifth,
  TwentySixth,
  TwentySeventh,
  TwentyEighth,
  TwentyNinth,
  Thirtieth,
  ThirtyFirst,
}

export enum DayUnits {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Day,
}

export enum Discount_Policy {
  NoDiscount,
  OnlyTradeDiscount,
  OnlyCashDiscount,
  TradenCashDiscount,
}

export enum ItemRate_Type {
  Exclusive,
  Inclusive,
}

export enum DuplicateCopy_Type {
  Original,
  Duplicate,
  Triplicate,
  Quadruplicate,
  Quintuplicate,
}

export enum TimeUnits {
  Day,
  Week,
  Month,
  Year,
}

export enum OrdinalTargets {
  Current,
  Next,
}

export enum DueOnTypes {
  After,
  Ordinal,
  Receipt,
}

export enum VatSubmitType {
  Monthly,
  Quarterly,
  Yearly,
}

export enum InvoiceTemplates {
  Template1,
  Template2,
  Template3,
  Template4,
  Template5,
  Template6,
}

export enum FontPtSizes {
  Eight,
  Nine,
  Ten,
  Eleven,
}

export enum TaxSubtotals {
  TaxRates,
  TaxRatesOverZero,
  SingleTaxSubtotal,
}

export interface IGstShort extends IIdName<number> {
  rate: number;
}
export interface EntitySettings {
  preSuf: string;
  start: string;
  isSuffix: boolean;
  disclaimer: string;
  title?: string;
  monthInclude?: boolean;
  monthNumFormat?: number;
  separator?: string;
  yearInclude?: boolean;
  defaultLedger?: string;
  showContactDetail?: boolean;
  showReceipt?: boolean;
  showShippingAddress?: boolean;
  showBankDetail?: boolean;
  showBankDetailWithQRorUPI?: boolean;
  showPriceHistory?: boolean;
  showOtherCharges?: boolean;
  eInvoicing?: boolean;
  eWayBilling?: boolean;
  returnNoteDayLimit?: number;
  termsnConditions?: string;
  discountPolicy?: Discount_Policy;
  itemRateType?: ItemRate_Type;
  invoiceCopyLabelling?: any;
  autoRoundOff?: boolean;
  roundOffType?: RoundOff_Type;
  showItemDescription?: boolean;
  tnCTemplateId?: string;
  showDispatchDetail?: boolean;
  showFreightType?: boolean;
  showRCM?: boolean;
}

export interface ItemSettings {
  preSuf: string;
  start: string;
  isSuffix: boolean;
  notes: string;
  title?: string;
  InventoryManage?: boolean;
}

export interface IDueOn {
  duration: number;
  durationUnit: TimeUnits;
  // ordinal: Ordinals;
  // ordinalUnit: DayUnits;
  // ordinalTarget: OrdinalTargets;
  // type: DueOnTypes;
  creditLimit?: number;
}

export interface IPaymentTerms {
  bank: IIdName;
  showBank: boolean;
  notes: string;
  showNotes: boolean;
}
export interface ITDSDetails {
  isTDSEnabled: boolean;
  tdsDeductorType: number;
}

export interface ITCSDetails {
  isTCSEnabled: boolean;
  tcsCollecteeType: number;
}

export interface IGstDetails {
  isGSTEnabled: boolean;
  gstDetails: IGstDetails;
  scheme: CompanyGSTTypes;
  registrationDate: string | undefined;
  registrationNo: string | undefined;
  submitType: VatSubmitType | undefined;
  gst: IGstShort[] | undefined;
  gstRegDateString: string | undefined;
}
export interface ITrialBalanceSettings {
  hideDecimals: boolean;
  roundToMillion: boolean;
  roundToThousand: boolean;
  showZeros: boolean;
  showNegative: boolean;
  showAccounts: IIdName<number>;
  showCustomCols: boolean;
  showAccBasis: boolean;
  showDatePrepared: boolean;
  showTimePrepared: boolean;
  enableNoteReferencing: boolean;
  notes: string;
  detailed: boolean;
  monthly: boolean;
}
export interface ITemplateSettings {
  type: InvoiceTemplates;
  showTaxNo: boolean;
  showTaxColumn: boolean;
  taxInclusive: boolean;
  name: string;
  paperSize: string;
  measureIn: string;
  topMargin: number;
  bottomMargin: number;
  addressPadding: number;
  font: string;
  fontSize: number;
  headingFontSize: number;
  taxSubtotals: number;
  psCreditCard?: {
    name: string;
    id: 0;
  };
  psPayPal?: {
    name: string;
    id: 0;
  };
  psBankPayments?: {
    name: string;
    id: 0;
  };
  contactDetails: string;
  showUnitPriceAndQty: boolean;
  showPaymentAdviceCutAway: boolean;
  showRegisteredAddress: boolean;
  hideDisc: boolean;
  showContactAcNo: boolean;
  showColumnHeading: boolean;
  showLogo: boolean;
  logoAlign: string;
  showCompanyAddress: boolean;
  showGSTIN: boolean;
  showPan: boolean;
  showEmail: boolean;
  showPhone: boolean;
  showWebsite: boolean;
  showShipping: boolean;
  showEBillNo: boolean;
  showEInvoiceNo: boolean;
  showItemCode: boolean;
  showUnit: boolean;
  showHSN: boolean;
  showTaxableValue: boolean;
  showBankDetail: boolean;
  showDispatchDetail: boolean;
}
export interface ICompanyInfo {
  companyContactDetails: {
    name: string;
    mobile: number;
    email: any;
  };
  name: string;
  panNo: string;
  gstNumber: string;
  appStartDate: string;
  fydate: string;
  financialYearBegin: string;
  regAddress: {
    city: string;
    state: number;
    pincode: string;
    country: string;
    building: string;
    street: string;
    landmark: string;
    district: string;
  };
  startDateString: string;
  businessType: BusinessTypes;
}

interface ICompanyContact {
  phone: string;
  email: string;
  mobile: string;
}

export interface IPeriod {
  from: Dayjs;
  to: Dayjs;
}

export interface DefaultLedger {
  defaultLedgerId: string;
  voucherType: Voucher_Type;
  voucherTitle: string;
}

export interface Vsettings {
  basicSettings: EntitySettings;
  billSettings: EntitySettings;
  invoiceSettings: EntitySettings;
  orderSettings: EntitySettings;
  returnNoteSettings: EntitySettings;
}

export interface vouchersSettings {
  settingType: SettingsSections;
  settings: Vsettings | any;
  title: string;
  system?: boolean;
}

export interface ICompanySettings {
  // DefaultLedgers
  defaultLedgers: DefaultLedger[];

  // All Setting Customization
  vouchersSettings: vouchersSettings[];
  // Sales Invoice
  // invoice: EntitySettings;
  //Sales Estimate
  // estimate: EntitySettings;
  //Sales Receipt
  // receipt: EntitySettings;
  ///CreditNote
  // crNote: EntitySettings;
  ///Contra
  // contra: EntitySettings;
  //Purchase Invoice
  // pInvoice: EntitySettings;
  //Purchase Order
  // pOrder: EntitySettings;
  //Purchase Payment
  // payment: EntitySettings;
  //Purchase CreditNote
  // drNote: EntitySettings;

  //Item
  // item: ItemSettings;
  //Expenses
  // expenses: EntitySettings;
  //Income
  // income: EntitySettings;
  //Journal
  // journal: EntitySettings;

  //Delivery challan
  // dChallan: EntitySettings;
  //Sales Order
  // sOrder: EntitySettings;
  //Asset
  // asset: EntitySettings;
  //Dividend
  // dividend: EntitySettings;

  //DueOn
  dueOn: IDueOn;
  //paymentTerms
  paymentTerms: IPaymentTerms;
  //Gst Details
  gstDetails: IGstDetails;
  //TBSettings
  tbSettings: ITrialBalanceSettings;
  //company information
  companyLogo: any;
  //template settings
  templateSettings: ITemplateSettings[];
  //template settings
  defaultTemplate: InvoiceTemplates;
  //company information
  companyInfo: ICompanyInfo;
  // company contact details
  companyContactDetails: ICompanyContact;
  //template settings
  defaultTemplateSettings: ITemplateSettings;
  //pdfContent
  pdfContent?: any;
  tdsDetails?: ITDSDetails;
  tcsDetails?: ITCSDetails;
}

export interface ICompanyInformation {
  setting: ICompanySettings;
  gsts: any[];
  transactions: number;
  section: SettingsSections;
  templateUrls: Record<InvoiceTemplates, string>;
  period: IPeriod;
}

export enum RoundOff_Type {
  Normal,
  Ceiling,
  Floor,
}

class SettingService {
  //private route = "/v1/api/Acc_New_CompanySettings/";
  private routes = "/v1/api/Acc_New_CompanySettings/"; // this is for new companysSettrings
  //https://localhost:7142/v1/api/CompanySettings/648985973b462a99cead6bbe'post'

  PostSetting = (clientID: string, params: any) =>
    ApiUtility.post(`${this.routes}${clientID}`, params);

  //https://localhost:7142/v1/api/CompanySettings/6463611020350584c0d5c017

  // getSetting = (clientId: string) => {};

  //https://localhost:7142/v1/api/CompanySettings/6463611020350584c0d5c017/64f8184e546060b017eb53ae

  getSetting = (clientID: string, id?: string) =>
    ApiUtility.get(`${this.routes}${clientID}/${id}`);

  getCompanySettings = (clientID: string, sections: SettingsSections) =>
    ApiUtility.get(`${this.routes}${clientID}?section=${sections}`);

  updateCompanySettings = (
    clientId: string,
    sections: SettingsSections,
    params: ICompanySettings,
    voucherTitle?: string
  ) =>
    ApiUtility.post(
      `${this.routes}${clientId}?section=${sections}&VoucherTitle=${voucherTitle}`,
      params
    );
  // https://localhost:7142/v1/api/CompanysSettings/650c285fcd07a89b4aa10fa3?section=17

  addSettingCustomization = (clientId: string, params: ICompanySettings) =>
    ApiUtility.post(`${this.routes}${clientId}/CreateVoucherTemplate`, params);

  deleteSettingCustomization = (clientId: string, VoucherTitle: string) =>
    ApiUtility.delete(
      `${this.routes}${clientId}/DeleteVoucherTemplate?VoucherTitle=${VoucherTitle}`
    );
}
export default new SettingService();
