import React from "react";
import { hookstate, State, useHookstate } from "@hookstate/core";
import { devtools } from "@hookstate/devtools";
import { AuthService, Roles } from "../Services/AuthService";
import { IUserProfile } from "../Types/CommonTypes";
import { ApiUtility } from "../Utilities/ApiUtility.axios";
import { Utils } from "../Utilities/Utils";
import { InMemoryJWT } from "../Utilities/InMemoryJWT";
import { FullPageSpinner } from "../Common/FullPageSpinner";
import { getIndianStates } from "../CapsitechAccountsComponents/Services/ClientService";

export enum UserStatus {
  Active = 0,
  DeActive = 1,
}

interface IUserContextState {
  loaded: boolean;
  user: Partial<IUserProfile>;
  indianState: Partial<any>;
  status?: UserStatus;
}
interface IUserAppConfigState {
  dashboard: string;
  striped: boolean;
  pageLength: number;
}

export const PAGE_LENGTH = 15;
const DefaultAppConfig: IUserAppConfigState = {
  dashboard: "dashboard",
  striped: false,
  pageLength: PAGE_LENGTH,
};

export const stateUserContext = hookstate<IUserContextState>(
  {
    loaded: false,
    user: {},
    indianState: {},
  },
  devtools({ key: "user-context-state" })
);
export const stateUserAppConfig = hookstate<IUserAppConfigState>(
  DefaultAppConfig,
  devtools({ key: "user-app-config-state" })
);

const wrapUserContextState = (
  s: State<IUserContextState>,
  sConfig: State<IUserAppConfigState>
) => {
  console.log("s", s);

  const isAuthenticated = () => {
    const user = s.user.get();
    const indianState = s.indianState.get();
    // console.log("stateOptions wrapUserContextState", user, indianState);

    if (AuthService.getAuthToken() && user && user.id) return true;
    return false;
  };

  const isIndianState = () => {
    const state = s.indianState.get();

    if (state) return true;
    return false;
  };

  const isInRole = (roles: string | string[]) => {
    let exists = false;
    if (isAuthenticated()) {
      const uRoles = s.user.roles.get();
      if (uRoles) {
        if (typeof roles === "string") exists = uRoles.includes(roles);
        else exists = uRoles.some((v) => roles.includes(v));
      }
    }
    return exists;
  };

  const setCurrentUser = (user?: IUserProfile, indianState?: any) => {
    if (user) {
      s.merge({
        user: user,
        indianState: indianState,
        status: UserStatus.Active,
        loaded: true,
      });
    } else {
      s.merge({ user: {}, status: UserStatus.DeActive, loaded: true });
    }
  };

  // const setIndianState = (indianState? : any)=>{
  //    if (indianState) {
  //      s.merge({ user: user, status: UserStatus.Active, loaded: true });
  //    } else {
  //      s.merge({ user: {}, status: UserStatus.DeActive, loaded: true });
  //    }
  // };
  // debugger
  return {
    isAuthenticated,
    isInRole,
    getUser: () => (isAuthenticated() ? s.user.get() : undefined),
    getUserStatus: () => (isAuthenticated() ? s.status.get() : undefined),
    getUserId: () => (isAuthenticated() ? s.user.id.get() : ""),
    getUserName: () => (isAuthenticated() ? s.user.fullName.get() : ""),
    getUserPrimaryRole: () => (isAuthenticated() ? s.user.role.get() : ""),
    getUserThumbUrl: () => (isAuthenticated() ? s.user.thumbUrl.get() : ""),
    getUserRoles: (): string[] =>
      (isAuthenticated() ? s.user.roles.get({ noproxy: true }) : []) as any,
    getAllIndStates: () => (isIndianState() ? s.indianState.get() : []),
    getUserConfig: () => (isAuthenticated() ? s.user.config.get() : undefined),
    isAdmin: () => isInRole(Roles.Admin),
    isEmployee: () => isInRole(Roles.Employee),
    getProducts: () => (isAuthenticated() ? s.user.products.get() : []),
    // userData,
    // setUserData,
    // refreshCurrentUser: async () => {
    //   const user = await ApiUtility.getResult(`/auth/profile`, {});
    //   Utils.setSession("ao-user", user);
    //   setCurrentUser(user);
    //   return user;
    // },
    setCurrentUser,
    // setIndianState,
  };
};

export const useUser = () =>
  wrapUserContextState(
    useHookstate(stateUserContext),
    useHookstate(stateUserAppConfig)
  );

interface IUserContextProps {
  getUserDetailsFromServer: () => Promise<void>;
}

export const UserContext = React.createContext<IUserContextProps>({} as any);
// const [userData, setUserData] = React.useState<any>();
export function UserProvider(props: { children?: any }) {
  // debugger
  const state = useHookstate(stateUserContext);
  const getUserDetailsFromServer = async () => {
    //  debugger
    let user = Utils.getSession("ao-user");
    let indianState = Utils.getLocal("IndianState");
    console.log("stateOptions", indianState);
    // if (!user) {
    //   user = await ApiUtility.getResult(`/auth/profile`, {});
    //   Utils.setSession("ao-user", user);
    // }
    if (user) {
      state.merge({
        user: user,
        status: UserStatus.Active,
        loaded: true,
        indianState: indianState,
      });
    } else state.merge({ loaded: true, user: {}, indianState: {} });
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const authToken = await InMemoryJWT.getRefreshedToken(true);
      if (authToken) {
        getUserDetailsFromServer();
      } else {
        state.merge({ loaded: true });
      }
    };

    fetchData();
    return () => {
      InMemoryJWT.abortRefreshToken();
      console.debug("User context unloaded");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!state.loaded.value) {
    return <FullPageSpinner label="Loading..." />;
  }
  //console.debug('user-context rendered');
  return (
    <UserContext.Provider
      value={{
        getUserDetailsFromServer,
      }}
      {...props}
    />
  );
}

export function useUserContext() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUserContext must be used within a UserProvider`);
  }
  return context;
}
