import { Button, Card, Col, Popover, Row, Spin, Table, Tooltip } from "antd";
import React from "react";
import AddLocationModal from "./AddLocationModal";
import LocationService from "../../Services/LocationService";
import { useClient } from "../../Context/ClientContext";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  FormOutlined,
  LoadingOutlined,
  PlusCircleFilled,
  PlusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { notificationContext } from "../../Common/PageRoute";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import Search from "antd/es/transfer/search";
import { useTheme } from "../../Context/ThemeContext";

const AssetLocation = () => {
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const { openNotification } = React.useContext(notificationContext);
  const [isModelOpen, setIsModelOpen] = React.useState<boolean>(false);
  const [locationDataList, setLocationDataList] = React.useState<any[]>();
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [page, setPage] = React.useState(1);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [id, setId] = React.useState<any>({ id: "", name: "" });
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");

  React.useEffect(() => {
    getLocationList();
  }, [(page - 1) * pageSize, pageSize, refresh, search]);

  const getLocationList = async () => {
    setLoading(true);
    let res: any = await LocationService.getLocationList(
      currentClient?.id,
      (page - 1) * pageSize,
      pageSize,
      search
    );
    if (res?.result) {
      console.log("res", res);
      setLocationDataList(
        res.result?.map((d: any, index: number) => ({
          SNo: index + 1,
          name: d?.locationName,
          id: d?.id,
        })) || []
      );
      setLoading(false);
      setTotalRecords(res?.totalRecords);
    } else {
      setLoading(false);
    }
  };

  const deleteLocationData = async () => {
    let res = await LocationService?.deleteLocationDatabyId(
      currentClient?.id,
      id?.id
    );
    if (res?.status) {
      getLocationList();
      openNotification("success", "Deleted successfull");
      setConfirmation(false);
    } else {
      openNotification("error", res?.message);
      setConfirmation(false);
    }
  };

  const columns: ColumnsType<any> = [
    { title: "S No.", dataIndex: "SNo", width: "10%" },
    { title: "Location Name", dataIndex: "name" },
    {
      title: "Action",
      dataIndex: "",
      render: (record: any) => (
        <>
          <Row gutter={10}>
            <Col>
              <Tooltip title="Edit">
                <FormOutlined
                  onClick={() => {
                    setId({ id: record?.id, name: record?.name });
                    setIsModelOpen(true);
                  }}
                />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title="Delete" color="red">
                <DeleteOutlined
                  style={{ color: "red" }}
                  onClick={() => {
                    setId({ id: record?.id, name: record?.name });
                    setConfirmation(true);
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        </>
      ),
    },
  ];
  return (
    <>
      <div>
        <Card
          size="small"
          className="TableCard"
          style={{
            marginTop: 35,
            boxShadow: "0px 0px 10px 0px #96A1C84D",
          }}
        >
          <div style={{ padding: "0px 0px 3px 0px" }}>
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 20 }} />}
              spinning={loading}
            >
              <Row justify={"space-between"}>
                <Col>
                  <Button
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      setIsModelOpen(true);
                      setId("");
                    }}
                  >
                    Add Location
                  </Button>
                </Col>
                <Col>
                  <Search
                    placeholder="Search..."
                    onChange={(e: any) => setSearch(e.target.value)}
                  />
                </Col>
              </Row>
              <div style={{ marginTop: "4px" }}>
                <Table
                  className={`Tabel-style table-${
                    themeData?.componentSize ?? "middle"
                  }`}
                  columns={columns}
                  dataSource={locationDataList}
                  pagination={{
                    total: totalRecords,
                    current: page,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    onChange: (page: number, pageSize: number) => {
                      setPage(page);
                      setPageSize(pageSize);
                    },
                    pageSizeOptions: ["10", "15", "25", "50", "100"],
                  }}
                  scroll={
                    pageSize > 15
                      ? { x: 1500, y: window.innerHeight - 300 }
                      : undefined
                  }
                />
              </div>
            </Spin>
          </div>
        </Card>
      </div>

      {isModelOpen && (
        <AddLocationModal
          onCancel={() => setIsModelOpen(false)}
          isModelOpen={isModelOpen}
          id={id?.id}
          setRefresh={setRefresh}
        />
      )}

      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            deleteLocationData();
          }}
          text={`Are you sure you want to delete this data "${id?.name}"? `}
        />
      )}
    </>
  );
};

export default AssetLocation;
