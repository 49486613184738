import { Button, Form, Input, Spin } from "antd";
import Modal from "antd/es/modal/Modal";
import React from "react";
import LocationService from "../../Services/LocationService";
import { useClient } from "../../Context/ClientContext";
import { LoadingOutlined } from "@ant-design/icons";
import { notificationContext } from "../../Common/PageRoute";

interface Modelprops {
  onCancel: any;
  isModelOpen: boolean;
  id: any;
  setRefresh: any;
}
const AddLocationModal = ({
  isModelOpen,
  onCancel,
  id,
  setRefresh,
}: Modelprops) => {
  const [form] = Form.useForm();
  const { currentClient } = useClient();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [btnLoading, setBtnLoading] = React.useState<boolean>(false);
  const { openNotification } = React.useContext(notificationContext);

  console.log("id", id);

  const addLocationData = () => {
    form.validateFields().then(async (val) => {
      setBtnLoading(true);
      let res = await LocationService?.postLocationData(
        currentClient?.id,
        id,
        val
      );
      if (res?.status) {
        setBtnLoading(false);
        setRefresh((x: boolean) => !x);
        openNotification(
          "success",
          `Location ${id ? "Edit Successfully" : "Add Successfully"}`
        );
        onCancel(false);
      } else {
        setBtnLoading(false);
        openNotification("error", res?.message);
      }
    });
  };

  React.useEffect(() => {
    if (id !== "") {
      const getDatabyId = async () => {
        setLoading(true);
        let res = await LocationService?.getLocationDatabyId(
          currentClient?.id,
          id
        );
        if (res) {
          form?.setFieldsValue({
            locationName: res?.result?.locationName,
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      };
      getDatabyId();
    }
  }, []);

  return (
    <div>
      <Modal
        open={isModelOpen}
        onCancel={() => onCancel(false)}
        title={`${id ? "Update" : "Add"} Location`}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => onCancel(false)}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button
              loading={btnLoading}
              type="primary"
              onClick={addLocationData}
            >
              {id ? "Edit" : "Save"}
            </Button>
          </div>
        }
      >
        <Spin
          indicator={<LoadingOutlined style={{ fontSize: 20 }} />}
          spinning={loading}
        >
          <Form form={form} style={{ marginTop: "20px" }}>
            <Form.Item name="locationName" label="Location Name">
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
};

export default AddLocationModal;
