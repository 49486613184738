import { Limit_Type, Transaction_Type } from "../../Services/TdsTcsServices";

export interface ITdsNature {
  key: Number;
  section: String;
  title: String;
  thresholdLimit: Number | string;
  term: Limit_Type;
  type: Transaction_Type;
  effectiveFrom: Date | any;
  effectiveTo: Date | any;
  rates: {
    indHUF: Number;
    others: Number;
    ifNotPAN: Number;
  };
  form: String;
}

export const TDSNature: ITdsNature[] = [
  {
    key: 1,
    section: "192",
    title: "Salaries",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 0,
      others: 0,
      ifNotPAN: 0,
    },
    form: "24Q",
  },
  {
    key: 2,
    section: "192A",
    title: "Premature withdrawal from EPF",
    thresholdLimit: 50000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 10,
      others: 0,
      ifNotPAN: 20,
    },
    form: "",
  },
  {
    key: 3,
    section: "193",
    title: "Interest on Securities",
    thresholdLimit: 10000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 10,
      others: 10,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 4,
    section: "194",
    title: "Dividends",
    thresholdLimit: 5000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 10,
      others: 10,
      ifNotPAN: 20,
    },
    form: "26Q",
  },

  {
    key: 5,
    section: "194A",
    title: "Interest (Banks)",
    thresholdLimit: 40000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 10,
      others: 10,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 6,
    section: "194A",
    title: "Interest - Senior Citizen (Banks)",
    thresholdLimit: 50000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 10,
      others: 0,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 7,
    section: "194A",
    title: "Interest (Others)",
    thresholdLimit: 5000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 10,
      others: 10,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 8,
    section: "194B",
    title: "Winning from Lotteries",
    thresholdLimit: 10000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 30,
      others: 30,
      ifNotPAN: 30,
    },
    form: "26Q",
  },
  {
    key: 9,
    section: "194BA",
    title: "Winnings from online games",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 30,
      others: 30,
      ifNotPAN: 30,
    },
    form: "26Q",
  },
  {
    key: 10,
    section: "194BB",
    title: "Winning from Horse Race",
    thresholdLimit: 10000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 30,
      others: 30,
      ifNotPAN: 30,
    },
    form: "26Q",
  },
  {
    key: 11,
    section: "194C",
    title: "Contractor - Single Transaction",
    thresholdLimit: 30000,
    term: Limit_Type.PerTransaction,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 1,
      others: 2,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 12,
    section: "194C",
    title: "Contractor - During the F.Y.",
    thresholdLimit: 100000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 1,
      others: 2,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 13,
    section: "194C",
    title: "Transporter (44AE) declaration with PAN",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 0,
      others: 0,
      ifNotPAN: 0,
    },
    form: "26Q",
  },
  {
    key: 14,
    section: "194D",
    title: "Insurance Commission (15G - 15H allowed)",
    thresholdLimit: 15000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 5,
      others: 10,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 15,
    section: "194DA",
    title: "Payment received - Life insurance Policy (from 01/10/2024)",
    thresholdLimit: 100000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/10/2024",
    effectiveTo: "",
    rates: {
      indHUF: 2,
      others: 2,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 16,
    section: "194DA",
    title: "Payment received - Life insurance Policy (upto 30/09/2024)",
    thresholdLimit: 100000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "30/09/2024",
    rates: {
      indHUF: 5,
      others: 5,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 17,
    section: "194EE",
    title: "Payment received - National Savings Scheme (NSS)",
    thresholdLimit: 2500,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 10,
      others: 10,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 18,
    section: "194F",
    title: "Repurchase Units by MFs (Upto 30/09/2024)",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "30/09/2024",
    rates: {
      indHUF: 20,
      others: 20,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 19,
    section: "194F",
    title: "Repurchase Units by MFs (From 01/10/2024)",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/10/2024",
    effectiveTo: "",
    rates: {
      indHUF: 0,
      others: 0,
      ifNotPAN: 0,
    },
    form: "26Q",
  },
  {
    key: 20,
    section: "194G",
    title: "Commission - Lottery (Upto 30/09/2024)",
    thresholdLimit: 15000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "30/09/2024",
    rates: {
      indHUF: 5,
      others: 5,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 21,
    section: "194G",
    title: "Commission - Lottery (From 01/10/2024)",
    thresholdLimit: 15000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/10/2024",
    effectiveTo: "",
    rates: {
      indHUF: 2,
      others: 2,
      ifNotPAN: 20,
    },
    form: "26Q",
  },

  {
    key: 22,
    section: "194H",
    title: "Commission / Brokerage (Upto 30/09/2024)",
    thresholdLimit: 15000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "30/09/2024",
    rates: {
      indHUF: 5,
      others: 5,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 23,
    section: "194H",
    title: "Commission / Brokerage (From 01/10/2024)",
    thresholdLimit: 15000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/10/2024",
    effectiveTo: "",
    rates: {
      indHUF: 2,
      others: 2,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 24,
    section: "194I(a)",
    title: "Rent for Plant & Machinery",
    thresholdLimit: 240000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 2,
      others: 2,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 25,
    section: "194I(b)",
    title: "Rent for Land, Building & Furniture",
    thresholdLimit: 240000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 10,
      others: 10,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 26,
    section: "194IA",
    title: "Transfer of certain immovable property other than agriculture land",
    thresholdLimit: 5000000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 1,
      others: 1,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 27,
    section: "194IB",
    title:
      "Rent payment by individual / HUF not covered u/s 194I (Upto 30/09/2024)",
    thresholdLimit: 50000,
    term: Limit_Type.Monthly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "30/09/2024",
    rates: {
      indHUF: 5,
      others: 5,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 28,
    section: "194IB",
    title:
      "Rent payment by individual / HUF not covered u/s 194I (From 01/10/2024)",
    thresholdLimit: 50000,
    term: Limit_Type.Monthly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/10/2024",
    effectiveTo: "",
    rates: {
      indHUF: 2,
      others: 2,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 29,
    section: "194IC",
    title: "Payment under specified Joint Development Agreement",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 10,
      others: 10,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 30,
    section: "194J(a)",
    title:
      "Fees - Technical Services, Call Centre, Royalty, Distribution / Exhibition of Cinematographic Films, etc.",
    thresholdLimit: 30000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 2,
      others: 2,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 31,
    section: "194J(b)",
    title: "Fees - All other Professional Services",
    thresholdLimit: 30000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 10,
      others: 10,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 32,
    section: "194K",
    title: "Payment of dividend by mutual Funds",
    thresholdLimit: 5000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 10,
      others: 10,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 33,
    section: "194LA",
    title:
      "Compensation on transfer of certain immovable property other than agricultural land",
    thresholdLimit: 250000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 10,
      others: 10,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 34,
    section: "194LB",
    title:
      "Income by way of interest from infrastructure debt fund (non-resident)",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 5,
      others: 5,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 35,
    section: "194LBA",
    title: "Certain income from units of a business trust",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/10/2014",
    effectiveTo: "",
    rates: {
      indHUF: 10,
      others: 10,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 36,
    section: "194LBB",
    title: "Income in respect of investment of investment fund (Resident)",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 10,
      others: 10,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 37,
    section: "194LBB",
    title: "Income in respect of investment of investment fund (Non-resident)",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 30,
      others: 40,
      ifNotPAN: 0,
    },
    form: "26Q",
  },
  {
    key: 38,
    section: "194LBC",
    title: "Income in respect of investment in securitization trust ",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 25,
      others: 30,
      ifNotPAN: 0,
    },
    form: "26Q",
  },
  {
    key: 39,
    section: "194LD",
    title: "Interest on certain bonds and Govt. Securities",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/06/2013",
    effectiveTo: "",
    rates: {
      indHUF: 5,
      others: 5,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 40,
    section: "194M",
    title:
      "Payment made for Contracts, Brokerage or Professional Fees by individual & HUF (other than Section 194C, 194H & 194J) (Upto 30/09/2024)",
    thresholdLimit: 5000000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "30/09/2024",
    rates: {
      indHUF: 5,
      others: 5,
      ifNotPAN: 20,
    },
    form: "26Q",
  },
  {
    key: 41,
    section: "194M",
    title:
      "Payment made for Contracts, Brokerage or Professional Fees by individual & HUF (other than Section 194C, 194H & 194J) (From 01/10/2024)",
    thresholdLimit: 5000000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/10/2024",
    effectiveTo: "",
    rates: {
      indHUF: 2,
      others: 2,
      ifNotPAN: 20,
    },
    form: "26Q",
  },

  //TODO: 194N is pending, will be created after discussion with Modi Ji
  // 42-45 keys are preserved for it

  {
    key: 46,
    section: "194O",
    title: "TDS on e-commerce participants (Upto 30/09/2024)",
    thresholdLimit: 500000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "30/09/2024",
    rates: {
      indHUF: 1,
      others: 1,
      ifNotPAN: 5,
    },
    form: "26Q",
  },
  {
    key: 47,
    section: "194O",
    title: "TDS on e-commerce participants (From 01/10/2024)",
    thresholdLimit: 500000,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/10/2024",
    effectiveTo: "",
    rates: {
      indHUF: 0.1,
      others: 0.1,
      ifNotPAN: 5,
    },
    form: "26Q",
  },
  {
    key: 0,
    section: "",
    title: "",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 0,
      others: 0,
      ifNotPAN: 0,
    },
    form: "",
  },
  {
    key: 0,
    section: "",
    title: "",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 0,
      others: 0,
      ifNotPAN: 0,
    },
    form: "",
  },
  {
    key: 0,
    section: "",
    title: "",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 0,
      others: 0,
      ifNotPAN: 0,
    },
    form: "",
  },
  {
    key: 0,
    section: "",
    title: "",
    thresholdLimit: 0,
    term: Limit_Type.Yearly,
    type: Transaction_Type.Domestic,
    effectiveFrom: "01/01/1900",
    effectiveTo: "",
    rates: {
      indHUF: 0,
      others: 0,
      ifNotPAN: 0,
    },
    form: "",
  },
];
