import {
  Button,
  Card,
  Col,
  DatePicker,
  Popover,
  Row,
  Select,
  Space,
  Spin,
  Table,
  TableColumnsType,
  TableProps,
} from "antd";
import React, { useContext } from "react";
import { useClient } from "../../../Context/ClientContext";
import { dayjs } from "../../../../Utilities/dayjs";
import ReportsServiceNew from "../../../Services/ReportsServiceNew";
import { notificationContext } from "../../../Common/PageRoute";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { voucherType } from "../VoucherDetailsByLedger";
import { getIndianStates } from "../../../Services/ClientService";
import { useTheme } from "../../../Context/ThemeContext";
import { Utils } from "../../../../Utilities/Utils";
import InvoiceExportService from "../../../Services/InvoiceExportDetails";

const GSTR1 = () => {
  const { themeData } = useTheme();
  const { currentClient, companySettings } = useClient();
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const { RangePicker } = DatePicker;
  const { openNotification } = useContext(notificationContext);
  const [reportType, setReportType] = React.useState<any>(0);

  const [loading, setLoading] = React.useState(false);
  const [gstReportsData, setGstReportsData] = React.useState<any>();
  const [filterDate, setFilterDate] = React.useState<any>(
    JSON.parse(localStorage.getItem("LockPeriod")!)
      ? {
          from: JSON.parse(localStorage.getItem("LockPeriod")!).from,
          to: JSON.parse(localStorage.getItem("LockPeriod")!).to,
        }
      : {
          from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
          to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
        }
  );
  const [exportType, setExportType] = React.useState<number>(-1);
  const [exportGstData, setExportGstData] = React.useState<any[]>([]);

  React?.useEffect(() => {
    if (currentClient?.id && (exportType === -1 || exportType)) {
      getList(
        currentClient?.id,
        dayjs(filterDate?.from, "DD/MM/YYYY").toISOString(),
        dayjs(filterDate?.to, "DD/MM/YYYY").endOf("day").toISOString(),
        reportType
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    filterDate?.from,
    filterDate?.to,
    reportType,
    exportType,
  ]);

  const getList = async (
    clientId: string,
    fromDate: string = "",
    toDate: string = "",
    reportType: any
  ) => {
    if (exportType < 1) {
      setLoading(true);
    }
    await ReportsServiceNew?.getGSTReports(
      clientId,
      fromDate,
      toDate,
      reportType
    ).then((res: any) => {
      if (res) {
        setLoading(false);
        let gstData = res?.result?.flatMap((r: any, index: number) => {
          return r?.taxDetail?.map((t: any) => {
            return {
              key: index,
              partyGSTNo: r?.partyGSTNo,
              partyName: r?.partyName,
              number: r?.number,
              voucherType: voucherType[r?.voucherType],
              hsnsac: t?.hsnsac,
              date: dayjs(r?.date).format("DD-MM-YYYY"),
              invoiceValue: Utils?.getFormattedNumber(r?.invoiceValue),
              supplyState: getIndianStates(r?.supplyState),
              taxableAmount: Utils?.getFormattedNumber(t?.taxableAmount),
              igstAmt: Utils?.getFormattedNumber(t?.igstAmt),
              cgstAmt: Utils?.getFormattedNumber(t?.cgstAmt),
              sgstAmt: Utils?.getFormattedNumber(t?.sgstAmt),
              rate:
                t?.igstRate === 0
                  ? Number(t?.cgstRate) + Number(t?.sgstRate)
                  : t?.igstRate,
            };
          });
        });
        if (exportType >= 1) {
          setExportGstData(gstData);
          if (exportType === 1) {
            exportCsv(gstData);
          } else if (exportType === 2) {
            exportPDF(gstData);
          } else if (exportType === 3) {
            exportExcel(gstData);
          }
        } else {
          setGstReportsData(gstData);
        }
      }
    });
  };

  const columns: TableColumnsType<any> = [
    {
      title: "GSTIN of Suppliers",
      dataIndex: "partyGSTNo",
      key: "partyGSTNo",
    },
    {
      title: "Trade / Legal Name",
      dataIndex: "partyName",
      key: "partyName",
    },
    {
      title: "Invoice Details",
      children: [
        {
          title: "Invoice Number",
          dataIndex: "number",
          key: "number",
          align: "center",
        },
        {
          title: "Invoice Type",
          dataIndex: "voucherType",
          key: "voucherType",
          render: (_, record: any) => <>{record?.voucherType}</>,
        },
        {
          title: "Invoice Date",
          dataIndex: "date",
          key: "date",
          align: "center",
        },
        {
          title: "Invoice Value",
          dataIndex: "invoiceValue",
          key: "invoiceValue",
          align: "right",
        },
      ],
    },
    {
      title: "Place of Suppliers",
      dataIndex: "supplyState",
      key: "supplyState",
      render: (_: any, record: any) => <>{record?.supplyState}</>,
    },
    {
      title: "Supply Attract Reverse Charge",
      dataIndex: "supply",
      key: "supply",
      render: (_: any, record: any) => (
        <>
          <p>No</p>
        </>
      ),
    },
    {
      title: "Rate(%)",
      dataIndex: "rate",
      key: "rate",
      align: "center",
    },
    {
      title: "HSN/SAC",
      dataIndex: "hsnsac",
      key: "hsnsac",
      align: "center",
    },
    {
      title: "Taxable Value",
      dataIndex: "taxableAmount",
      key: "taxableAmount",
      align: "right",
    },
    {
      title: "Tax Amount",

      children: [
        {
          title: "Integrated Tax",
          dataIndex: "igstAmt",
          key: "igstAmt",
          align: "right",
        },
        {
          title: "Central Tax",
          dataIndex: "cgstAmt",
          key: "cgstAmt",
          align: "right",
        },
        {
          title: "State/UT Tax",
          dataIndex: "sgstAmt",
          key: "sgstAmt",
          align: "right",
        },
      ],
    },
  ];

  const getQuarterPreset: any = (startMonth: number) => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() + startMonth).startOf("month"),
      fromDate.month(fromDate.month() + startMonth + 2).endOf("month"),
    ];
  };
  const thisYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month()).startOf("month"),
      fromDate.month(fromDate.month() + 11).endOf("month"),
    ];
  };
  const lastYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() - 12).startOf("month"),
      fromDate.month(fromDate.month() - 1).endOf("month"),
    ];
  };

  const getLast15Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(15, "day").startOf("day"); // Start 15 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };
  const getLast7Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(7, "day").startOf("day"); // Start 7 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };

  const getThisMonth = () => {
    const now = dayjs();
    return [now.startOf("month"), now.endOf("month")];
  };
  const getLastMonth = () => {
    const now = dayjs();
    const lastMonth = now.subtract(1, "month");
    return [lastMonth.startOf("month"), lastMonth.endOf("month")];
  };

  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => {});
  };

  let path = "GST Report";

  // const columnsA = [
  //   // First row (main headers)
  //   [
  //     { content: "GSTIN of Suppliers", rowSpan: 2 },
  //     { content: "Trade/Legal Name", rowSpan: 2 },
  //     { content: "Invoice Details", colSpan: 4 },
  //     { content: "Place of Suppliers", rowSpan: 2 },
  //     { content: "Supply Attract Reverse Charge", rowSpan: 2 },
  //     { content: "Rate(%)", rowSpan: 2 },
  //     { content: "HSN/SAC", rowSpan: 2 },
  //     { content: "Taxable Value", rowSpan: 2 },
  //     { content: "Tax Amount", colSpan: 3 },
  //   ],
  //   // Second row (subcolumns for Invoice Details and Tax Amount)
  //   [
  //     { content: "Invoice Number" },
  //     { content: "Invoice Type" },
  //     { content: "Invoice Date" },
  //     { content: "Invoice Value" },
  //     { content: "Integrated Tax" },
  //     { content: "Central Tax" },
  //     { content: "State/UT Tax" },
  //   ],
  // ];
  const columnsA = [
    { content: "GSTIN of Suppliers", rowSpan: 2 },
    { content: "Trade / Legal Name", rowSpan: 2 },
    { content: "Invoice Detail", colSpan: 4 },
    { content: "Place of Suppliers", rowSpan: 2 },
    { content: "Supply Attract Reverse Charges", rowSpan: 2 },
    { content: "Rate(%)", rowSpan: 2 },
    { content: "HSN/SAC", rowSpan: 2 },
    { content: "Taxable Value", rowSpan: 2 },
    { content: "Tax Amount", colSpan: 3 },
  ];

  const columnsB = [
    { content: "Invoice Number" },
    { content: "Invoice Type" },
    { content: "Invoice Date" },
    { content: "Invoice Value" },
    { content: "Integrated Tax" },
    { content: "Central Tax" },
    { content: "State/UT Tax" },
  ];

  const headCols = [columnsA, columnsB];

  const fieldMapping = {
    "GSTIN of Suppliers": (item: any) => item?.partyGSTNo,
    "Trade / Legal Name": (item: any) => item?.partyName,
    "Invoice Number": (item: any) => item?.number,
    "Invoice Type": (item: any) => item?.voucherType,
    "Invoice Date": (item: any) => item?.date,
    "Invoice Value": (item: any) => item?.invoiceValue,
    "Place of Suppliers": (item: any) => item?.supplyState,
    "Supply Attract Reverse Charges": (item: any) => "No",
    "Rate(%)": (item: any) => item?.rate,
    "HSN/SAC": (item: any) => item?.hsnsac,
    "Taxable Value": (item: any) => item?.taxableAmount,
    "Integrated Tax": (item: any) => item?.igstAmt,
    "Central Tax": (item: any) => item?.cgstAmt,
    "State/UT Tax": (item: any) => item?.sgstAmt,
  };

  let type = 1;

  const exportCsv = (exportData: any[]) => {
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      filterDate,
      path,
      currentClient?.businessName,
      type
    );
    setExportType(0);
  };

  const exportPDF = (exportData: any[]) => {
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      headCols,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path,
      null,
      type,
      "landscape"
    );
    setExportType(0);
  };

  const exportExcel = async (exportData: any[]) => {
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      headCols,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path,
      null,
      type
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportGstData.length > 0) {
              exportCsv(exportGstData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportGstData.length > 0) {
              exportPDF(exportGstData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSX"
          onClick={() => {
            if (exportGstData.length > 0) {
              exportExcel(exportGstData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSX
        </Button>
      </div>
    </Space>
  );

  return (
    <div>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined />}
        style={{
          justifyContent: "center",
          marginTop: "180px",
          textAlign: "center",
        }}
      >
        <Card
          className="ca-card-body"
          style={{
            boxShadow: "0px 0px 10px 0px #96A1C84D",
            height: "90vh",
            padding: "10px",
          }}
        >
          <Row gutter={10}>
            <Col>
              <Select
                placeholder="Select..."
                // value={selectedOption}
                style={{ width: 160 }}
                onChange={(val: any) => {
                  setReportType(val);
                  setExportGstData([]);
                  setExportType(-1);
                }}
                options={[
                  { value: 0, label: "GSTR1" },
                  { value: 1, label: "GSTR2" },
                  { value: 2, label: "GSTR3" },
                ]}
              />
            </Col>
            <Col>
              <RangePicker
                format="DD/MM/YYYY"
                defaultValue={[
                  dayjs(filterDate?.from, "DD/MM/YYYY"),
                  dayjs(filterDate?.to, "DD/MM/YYYY"),
                ]}
                onChange={(e: any) => {
                  if (e === null) {
                    setFilterDate({});
                  } else {
                    setFilterDate({
                      ...filterDate,
                      from: dayjs(e[0]).format("DD/MM/YYYY"),
                      to: dayjs(e[1]).format("DD/MM/YYYY"),
                    });
                    localStorage.setItem(
                      "LockPeriod",
                      JSON.stringify({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      })
                    );
                  }
                  openNotification("success", `Period locked.`);
                  setExportGstData([]);
                  setExportType(-1);
                }}
                style={{
                  width:
                    width <= 464 ? "210px" : width == 375 ? "200px" : "250px",
                  border: "1px solid #f1f3ff",
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: getLast7Days(),
                  },
                  {
                    label: "Last 15 Days",
                    value: getLast15Days(),
                  },
                  {
                    label: "This Month",
                    value: getThisMonth(),
                  },
                  {
                    label: "Last Month",
                    value: getLastMonth(),
                  },
                  {
                    label: "Quarter 1",
                    value: getQuarterPreset(0),
                  },
                  {
                    label: "Quarter 2",
                    value: getQuarterPreset(3),
                  },
                  {
                    label: "Quarter 3",
                    value: getQuarterPreset(6),
                  },
                  {
                    label: "Quarter 4",
                    value: getQuarterPreset(9),
                  },
                  {
                    label: "This Year",
                    value: thisYear(),
                  },
                  {
                    label: "Last Year",
                    value: lastYear(),
                  },
                ]}
              />
            </Col>
            <Col>
              {gstReportsData?.length === 0 ? (
                <>
                  <Button type="default" icon={<DownloadOutlined />} disabled>
                    Export
                  </Button>
                </>
              ) : (
                <>
                  <Popover
                    content={content}
                    placement="rightTop"
                    trigger="hover"
                  >
                    <Button
                      type="default"
                      icon={<DownloadOutlined />}
                      // onClick={() => exportCsv()}
                    >
                      Export
                    </Button>
                  </Popover>
                </>
              )}
            </Col>
          </Row>
          <div style={{ width: "100%", margin: "0 auto" }}>
            <Table
              bordered
              dataSource={gstReportsData}
              size="small"
              style={{ marginTop: "30px" }}
              columns={columns}
              // className="ca-viewmodel-table"
              className={`ca-viewmodel-table Tabel-style table-${
                themeData?.componentSize ?? "middle"
              }`}
              scroll={
                gstReportsData?.length > 15 ? { x: 700, y: 620 } : { x: 600 }
              }
            />
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default GSTR1;
