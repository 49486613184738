import React from "react";
import { Route, Routes } from "react-router-dom";
import GroupTable from "./Group/GroupTable";
import LedgerTable from "./Ledger/LedgerTable";
import Items from "../Purchase/Items";
import { ItemType } from "../../Services/ItemsService";
import UnitsItems from "../ItemInventory/UnitsItems";
import ItemGroups from "../ItemInventory/ItemGroups";
import Departments from "./Department/Departments";
import ItemLocations from "../ItemInventory/ItemLocations";
import ItemCategory from "../ItemInventory/Category/ItemCategory";

const MasterNestedRoute = () => {
  return (
    <Routes>
      <Route path="/group" element={<GroupTable />} />
      {/* <Route path={"/"} element={<SubSaleInvoice />} /> */}
      <Route path="/ledger" element={<LedgerTable showName={"Ledger"}/>} />
      {/* <Route path="/order" element={<SalesOrder />} />
      <Route path="/deliveryChallan" element={<DeliveryChallan />} />
      <Route path="/quotation" element={<Quotation />} />
      <Route path="/paymentIn" element={<PaymentIn />} />
      <Route path="/try" element={<Add_TryDummy />} />
      <Route path="*" element={<ResourceNotFound pageHeight="100%" />} /> */}
      <Route path="/items" element={<Items itemPage={ItemType.All} />} />
      <Route path="/itemgroups" element={<ItemGroups />} />
      <Route path="/itemCategory" element={<ItemCategory />} />
      <Route path="/itemlocations" element={<ItemLocations />} />
      <Route path="/units" element={<UnitsItems />} />
      <Route path="/departments" element={<Departments />} />
    </Routes>
  );
};

export default MasterNestedRoute;
