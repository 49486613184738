import React from "react";
import { dayjs } from "../../../../Utilities/dayjs";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useClient } from "../../../Context/ClientContext";
import { notificationContext } from "../../../Common/PageRoute";
import ChartofAccountServicesNew from "../../../Services/ChartofAccountServicesNew";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../../Services/LedgerService";
import AddGroup from "../../Master/Group/AddGroup";
import { BankAccountTypes } from "../../../Services/ClientService";
import EntryLevelKeys from "../../../Common/EntryLevelKeys";
import DocumentPreview from "../../../Common/DocumentPreview";

const AddBankAccountModel: React.FC<{
  open: boolean;
  onCancel: any;
  bankAccountTrigger: any;
  curentBankAccountId?: any;
  id?: any;
  // triggerBankAccountDetails?: any;
  type?: string;
  allBankAccount?: any;
  defaultGroup?: any;
}> = (props) => {
  console.log(props.allBankAccount, "props");

  const [form] = Form.useForm();
  const { currentClient } = useClient();
  const autoCompleteRef = React.useRef<any>(null);

  const { openNotification } = React.useContext(notificationContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modelLoading, setModelLoading] = React.useState<boolean>(false);
  const [openModel, setOpenModel] = React.useState<{
    open: boolean;
    param: any;
  }>({
    open: false,
    param: "",
  });
  const [groupData, setGroupData] = React.useState<any[]>([]);

  const [bankViewDetails, setBankViewDetails] = React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [editRefresh, setEditRefresh] = React.useState<{
    displayName: string;
    openingBalance: number;
  }>({ displayName: "", openingBalance: 0 });
  const [saveButtonLoading, setSaveButtonLoading] =
    React.useState<boolean>(false);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [groupList, setGropList] = React?.useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = React.useState<boolean>(false);
  const [bankTypeValue, setBankTypeValue] = React.useState<BankAccountTypes>(
    BankAccountTypes.CurrentAccount
  );
  const [deleteUploadId, setDeleteUploadId] = React.useState<string[]>([]);
  const [previewImage, setPreviewImage] = React.useState<{
    isBaseConvert: boolean;
    path: any;
  }>({ isBaseConvert: false, path: "" });

  const [groupDropdownLoading, setGroupDropdownLoading] =
    React.useState<boolean>(false);
  // const [type, setType] = React.useState<InventoryType>(
  //   props.id ? InventoryType.Update : InventoryType.Duplicate
  // );

  // Post Bank Account Api

  // console.log(editRefresh);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (autoCompleteRef.current) {
        autoCompleteRef.current.focus();
      }
    }, 100);
    return () => clearTimeout(timer);
  }, []);
  // --------- InitialValues in Add Bank Account Model ---------
  const initialValues = {
    bankAccountType: 1,
    date: dayjs(),
  };

  // const getGroupLabel = (value: any) => {
  //   const group = groupList?.find((group: any) => group?.value === value);
  //   return group ? group?.label : value;
  // };

  React?.useEffect(() => {
    getGroupList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  const getGroupList = async () => {
    setGroupDropdownLoading(true);
    let res = await ChartofAccountServicesNew?.getGroupList(
      currentClient?.id!,
      currentClient?.productModule,
      "",
      "",
      "",
      "",
      SearchLedgerByGroupsTypes?.BankAndCash
    );
    if (res?.items?.length > 0) {
      console.log("res", res.items);

      const dataSetInApi = (allgroupList: any[]) => {
        let result: any[] = [];
        allgroupList.forEach((x: any) => {
          const node: any = {
            label: x?.group_Name,
            value: x?.id,
            title: x?.parent,
          };
          // console.log("index........................", node);
          result.push(node);
          if (x?.children?.length > 0) {
            result = result.concat(dataSetInApi(x?.children));
          }
        });
        return result;
      };

      const flattenedData = dataSetInApi(res?.items);
      // console.log("flattenedData", flattenedData);
      setGropList(flattenedData);
      setGroupDropdownLoading(false);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    const getListData = async () => {
      await ChartofAccountServicesNew.getGroupList(
        currentClient?.id!,
        currentClient?.productModule
      )
        .then((res: any) => {
          if (res.items) {
            setGroupData(res.items);
            //  if (props.editData.id) {
            //    setGroupType(props.editData?.group_Type);
            //    form.setFieldsValue(props.editData);
            //  }
          } else {
            setGroupData([]);
            console.log("Error", res);
          }
        })
        .catch((err: any) => {
          console.log("Error", err);
        });
      setLoading(false);
      //  setTotalRecords(res?.totalRecords);
    };
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  React.useEffect(() => {
    if (props?.id && groupData?.length > 0) {
      setLoading(true);
      const editBankAccount = props.allBankAccount?.find(
        (x: any) => x.id === props?.id
      );
      console.log("editBankAccount", editBankAccount);

      findParentGroup(editBankAccount?.parent);
      form.setFieldsValue({
        ...editBankAccount,
        parent: editBankAccount?.parent,
        bankDetail: editBankAccount?.bankDetail
          ? editBankAccount?.bankDetail
          : [{}],
      });

      setModelLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.id, groupData]);

  const findParentGroup = (parent: string, defaultGroupName?: string) => {
    console.log("parent", parent, defaultGroupName, "defaultGroupName");
    setBankViewDetails(false);

    var x = groupData.find((x) => x.id === parent);
    console.log("findgroup....", x);
    if (
      !x?.editable ||
      defaultGroupName === "Bank Account" ||
      defaultGroupName === "Bank OD A/c"
    ) {
      if (
        x?.group_Name === "Bank Account" ||
        defaultGroupName === "Bank Account" ||
        defaultGroupName === "Bank OD A/c"
      ) {
        setBankViewDetails(true);
      }
    } else {
      findParentGroup(x?.parent);
    }
  };

  const saveLedger = () => {
    form.validateFields().then(async (value) => {
      console.log("post Data....", value);
      setSaveButtonLoading(true);
      let data = {
        ...value,
        bankDetail: new Array(value?.bankDetail),
        gstRate: {
          rate: value?.gstRate?.title ?? value?.gstRate,
          id: value?.gstRate?.value,
          name: value?.gstRate?.label,
        },
        parent: value?.parent?.value ?? value?.parent,
        order: value?.order ?? 1000,
        //  contactInformation: value?.contactInformation?.map(
        //    (index: any, i: number) => {
        //      console.log(checkbox);
        //      if (i === checkbox) {
        //        return Object.assign(index, { isDefault: true });
        //      } else if (i !== checkbox) {
        //        return Object.assign(index, { isDefault: false });
        //      }
        //    }
        //  ),
        system: false,
        isActive: true,
        isAudited: false,
        editable: true,
        deleteUploadId: deleteUploadId,
      };

      await LedgerService.postLedger(currentClient?.id!, data, props?.id)
        .then((res: any) => {
          if (res.result) {
            // setProps1(true);
            props.bankAccountTrigger();
            // props?.setRefresh((x: boolean) => !x);

            props.onCancel(false);

            openNotification(
              "success",
              `Bank Account ${props?.id ? "Edit successfully" : "Add successfully"}`
            );
            //  props.setOpenDrawer({});
          } else if (res?.message) {
            openNotification("error", res?.message);
          }
          setSaveButtonLoading(false);
        })
        .catch((ex: any) => {
          console.error(ex);
          openNotification("error", ex?.message);
          setSaveButtonLoading(false);
        });
    });
  };

  EntryLevelKeys({
    save: () => saveLedger(),
    cancel: () => props.onCancel(false),
  });

  React?.useEffect(() => {
    if (props?.id) {
      // setModelLoading(true);
      // getDetailsbyId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const getDetailsbyId = async () => {
  //   let res = await LedgerService?.getLedgerDetails(
  //     currentClient!.id,
  //     props?.id
  //   );
  //   if (res?.result) {
  //     console.log();

  //      findParentGroup(res.result?.parent);
  //     // setSelectedIndex(res?.result?.parent);
  //     form?.setFieldsValue({
  //       ledgerName: res?.result?.ledgerName,
  //       aliasName: res?.result?.aliasName,
  //       order: res?.result?.order,
  //       balance: res?.result?.balance,
  //       parent: res?.result?.parent,
  //       bankDetail:
  //         res?.result?.bankDetail?.length > 0 && res?.result?.bankDetail[0],
  //     });
  //     setModelLoading(false);
  //   } else {
  //     console.error("error..", res.errors);
  //     setModelLoading(false);
  //   }
  // };

  // console.log("----", selectedIndex);

  return (
    <div>
      <Modal
        open={props.open}
        title={
          <Row>
            <Col sm={15} xs={23} style={{ fontSize: "17px" }}>
              {!props?.id
                ? "Add Bank/Cash Account"
                : "Update Bank/Cash Account"}
            </Col>
            <Col sm={11} xs={12}>
              {/* <Checkbox
                disabled
                checked={primaryAccount}
                onChange={(e: any) => {
                  setPrimaryAccount(e.target.checked);

                }}
              >
                Primary account
              </Checkbox> */}
            </Col>
          </Row>
        }
        width={750}
        onCancel={() => props.onCancel()}
        // closable={props.onCancel}
        style={{ maxHeight: `calc(100% - 50px)`, overflowY: "auto" }}
        maskClosable={false}
        // centered
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.onCancel();
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => saveLedger()}
              loading={saveButtonLoading}
              className="modelSaveButtonMargin"
            >
              {!props?.id ? "Add" : "Update"}
            </Button>
          </div>
        }
      >
        <Divider className="ca-model-css" />
        <Spin indicator={antIcon} spinning={modelLoading}>
          <Form
            form={form}
            name="item"
            autoCapitalize="false"
            requiredMark={false}
            initialValues={initialValues}
            colon={false}
            labelAlign="left"
            labelCol={{ xs: 10, sm: 9 }}
            wrapperCol={{ xs: 14, sm: 16 }}
            labelWrap={true}
            // layout="vertical"
          >
            <Spin indicator={antIcon} spinning={modelLoading}>
              <Row
                style={{ justifyContent: "space-between" }}
                gutter={{ sm: 14 }}
              >
                <Col sm={12} xs={24}>
                  <Form.Item
                    name="ledgerName"
                    label="Bank Account Name"
                    rules={[
                      {
                        required: true,
                        message: "Bank Account name is required",
                        pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                      },
                    ]}
                    className="input-item"
                  >
                    <Input ref={autoCompleteRef} />
                  </Form.Item>
                </Col>
                <Col sm={12} xs={24}>
                  <Form.Item
                    label="Alias/Short name"
                    name="aliasName"
                    className="input-item"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                {/* <Col sm={12} xs={24}>
                  <Form.Item name="order" label="Order" className="input-item">
                    <Input />
                  </Form.Item>
                </Col> */}

                <Col sm={12} xs={24}>
                  <Form.Item
                    label="Bank Group"
                    name="parent"
                    className="input-item"
                    style={{ width: "100%" }}
                    rules={[
                      { required: true, message: "Account Type is required" },
                    ]}
                  >
                    <Select
                      labelInValue
                      options={groupList}
                      // onChange={handleParentGroupChange}
                      loading={groupDropdownLoading}
                      onChange={(e) => findParentGroup(e.title, e.label)}
                      dropdownRender={(customerOptions) => (
                        <>
                          {customerOptions}
                          <Divider style={{ margin: "5px 0px" }} />
                          <Button
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                            type="link"
                            onClick={() =>
                              setOpenModel({ open: true, param: "" })
                            }
                          >
                            New Group
                          </Button>
                        </>
                      )}
                    />
                  </Form.Item>
                </Col>
                <Col sm={12} xs={24}>
                  <Form.Item
                    label="Upload"
                    name="attachments"
                    valuePropName="fileList"
                    className="input-item"
                    getValueFromEvent={(e) => {
                      return e.fileList;
                    }}
                    // className="m-0"
                  >
                    <Upload
                      // multiple
                      maxCount={1}
                      accept={".png, .jpg, .jpeg, .pdf"}
                      // accept={".png, .jpg, .jpeg"}
                      beforeUpload={() => {
                        return false;
                      }}
                      onRemove={(e: any) => {
                        if (props?.id && e?.id) {
                          setDeleteUploadId([...deleteUploadId, e?.id]);
                        }
                      }}
                      onPreview={(e: any) => {
                        console.log("preview", e);
                        if (props?.id && e?.path) {
                          setPreviewImage({
                            isBaseConvert: false,
                            path: e,
                          });
                        } else if (e.type?.split("/")[0] === "image") {
                          setPreviewImage({
                            isBaseConvert: true,
                            path: e,
                          });
                        }
                      }}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        style={{ backgroundColor: "ButtonFace" }}
                      />
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>

              <>
                {bankViewDetails && (
                  <>
                    <Divider
                      orientation="left"
                      orientationMargin="0"
                      className="dividertext"
                      style={{ marginTop: "11px", marginBottom: "11px" }}
                    >
                      <p style={{ fontWeight: 600 }}>Bank Details</p>
                    </Divider>
                    <Row
                      style={{ justifyContent: "space-between" }}
                      gutter={{ sm: 14 }}
                    >
                      <Col sm={12} xs={24}>
                        <Form.Item
                          name={["bankDetail", "accountHolderName"]}
                          label="Holder name"
                          rules={[
                            {
                              // required: true,
                              pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                              message: "Enter Valid Account Holder Name",
                            },
                          ]}
                          className="input-item"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Account Number"
                          name={["bankDetail", "accountNumber"]}
                          className="input-item"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col sm={12} xs={24}>
                        <Form.Item
                          name={["bankDetail", "accountType"]}
                          label="Account Type"
                          className="input-item"
                        >
                          <Select
                            onChange={(val) => {
                              setBankTypeValue(val);
                            }}
                            options={[
                              {
                                label: "Saving Account",
                                value: BankAccountTypes.SavingAccount,
                              },
                              {
                                label: "Current Account",
                                value: BankAccountTypes.CurrentAccount,
                              },
                              {
                                label: "Cash Credit Account",
                                value: BankAccountTypes.CashCreditAccount,
                              },
                              {
                                label: "Overdraft Account",
                                value: BankAccountTypes.OverdraftAccount,
                              },
                            ]}
                            style={{
                              width: window.innerWidth <= 425 ? "226px" : " ",
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Bank name"
                          name={["bankDetail", "bankName"]}
                          className="input-item"
                          rules={[
                            {
                              pattern: /^[A-Za-z][ A-Za-z0-9_()&-.]*$/,
                              message: "Enter Valid Bank Name",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="IFSC code"
                          name={["bankDetail", "ifsCode"]}
                          className="input-item"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              pattern: /^[A-Z]{4}[0][0-9]{6}$/,
                              message: "Input valid IFSC Code ",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="Branch"
                          name={["bankDetail", "branch"]}
                          className="input-item"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              pattern: /^[A-Za-z][ A-Za-z]*$/,
                              message: "Enter Valid Branch Name",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="OD Limit"
                          name={["bankDetail", "odLimit"]}
                          className="input-item"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              pattern: /^[0-9]*$/,
                              message: "Please enter a valid amount.",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col sm={12} xs={24}>
                        <Form.Item
                          label="UPI Id"
                          name={["bankDetail", "UPIId"]}
                          className="input-item"
                          style={{ width: "100%" }}
                          rules={[
                            {
                              pattern:
                                /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}$/,
                              message: "Please enter a valid UPI Id!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      {(bankTypeValue === BankAccountTypes.CashCreditAccount ||
                        bankTypeValue ===
                          BankAccountTypes.OverdraftAccount) && (
                        <Col sm={12} xs={24}>
                          <Form.Item
                            name={["bankDetail", "oDLimit"]}
                            label={
                              bankTypeValue ===
                              BankAccountTypes.CashCreditAccount
                                ? "CC Limit"
                                : "Over Draft Limit"
                            }
                            className="input-item"
                          >
                            <InputNumber
                              controls={false}
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </>
                )}
              </>
            </Spin>
          </Form>
          <Divider style={{ margin: "0px" }} />
        </Spin>
      </Modal>
      {openModel.open && (
        <AddGroup
          open={openModel.open}
          editData={openModel?.param}
          onCancel={() => setOpenModel({ open: false, param: "" })}
          setTrigger={() => setTrigger(!trigger)}
          groupData={groupData}
        />
      )}
      {previewImage.path && (
        <DocumentPreview
          open={true}
          onCancel={() =>
            setPreviewImage({
              path: "",
              isBaseConvert: false,
            })
          }
          documentDetails={previewImage}
        />
      )}
    </div>
  );
};

export default AddBankAccountModel;
