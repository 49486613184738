import React, { useContext } from "react";
import {
  DeleteTwoTone,
  EditOutlined,
  PlusOutlined,
  RetweetOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  InputRef,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  TableColumnType,
  Tag,
  Tooltip,
} from "antd";
import AddGroup from "./AddGroup";
import { ColumnsType } from "antd/es/table";
import { useTheme } from "../../../Context/ThemeContext";
import { useClient } from "../../../Context/ClientContext";
import search from "../../../Images/search.svg";

import ChartofAccountServicesNew, {
  GroupTypes,
  Nature_new,
  Statements,
} from "../../../Services/ChartofAccountServicesNew";
import { FilterDropdownProps } from "antd/es/table/interface";
import { useKeyboardShortcuts } from "../../../Common/KeyboardShortcuts ";
import { NavLink } from "react-router-dom";
import { notificationContext } from "../../../Common/PageRoute";
import ChartofAccountsServices from "../../../Services/ChartofAccountsServices";
// import {ConfirmationModal} from "../../../Common/ConfirmationModal";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";
import {
  LedgerStatus,
  SearchLedgerByGroupsTypes,
} from "../../../Services/LedgerService";

interface IItemListData {
  key: string;
  parent: string;
  id: string;
  group_Name: string;
  aliasName: string;
  group_Type: GroupTypes;
  parent_Name: string;
  nature: Nature_new;
  status: LedgerStatus;
  statement: Statements;
  order: number;
  editable: boolean;
}

type DataIndex = keyof IItemListData;

const GroupTable = () => {
  const { themeData } = useTheme();
  const { currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [searchGroupName, setSearchGroupName] = React.useState<string>("");
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [groupDataList, setGroupDataList] = React.useState<any[]>([]);
  const [groupData, setGroupData] = React.useState<any[]>([]);
  const [filterGroupTypes, setFilterGroupTypes] = React.useState<GroupTypes>();
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [openModel, setOpenModel] = React.useState<{
    open: boolean;
    param: any;
  }>({
    open: false,
    param: "",
  });
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [searchColumns, setSearchColumns] = React.useState<{
    groupName: string;
    aliasName: string;
    order: string;
    parentGroup: string;
  }>({ groupName: "", aliasName: "", order: "", parentGroup: "" });
  const [filterGroupId, setFilterGroupId] = React.useState<string>("");
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const [ledgerStatus, setLedgerStatus] = React.useState<
    LedgerStatus | undefined
  >(LedgerStatus.active);
  const [selectChange, setSelectChange] = React.useState<boolean>(true);
  const searchInput = React.useRef<InputRef>(null);

  const handleSearch = (
    selectedKeys: string[],
    confirm: any,
    // confirm: FilterDropdownProps["confirm"],
    dataIndex: DataIndex
  ) => {
    if (dataIndex === "group_Name") {
      setSearchColumns({
        ...searchColumns,
        groupName: selectedKeys[0],
      });
    } else if (dataIndex === "aliasName") {
      setSearchColumns({
        ...searchColumns,
        aliasName: selectedKeys[0],
      });
    } else if (dataIndex === "order") {
      setSearchColumns({
        ...searchColumns,
        order: selectedKeys[0],
      });
    } else if (dataIndex === "parent_Name") {
      setSearchColumns({
        ...searchColumns,
        parentGroup: selectedKeys[0],
      });
    }
    // setSearchColumns([...searchColumns, selectedKeys[0]]);
    // setSearchGroupName(selectedKeys[0]);
    console.log("Search.......", selectedKeys, dataIndex);

    confirm();
    // setSearchColumns(selectedKeys[0]);
    // setSearchedColumn(dataIndex);
  };

  const handleReset = (
    clearFilters: () => void,
    confirm: FilterDropdownProps["confirm"]
  ) => {
    clearFilters();
    setSearchColumns({
      groupName: "",
      aliasName: "",
      order: "",
      parentGroup: "",
    });
    confirm();
    // setSearchColumns("");
  };

  const getColumnSearchProps = (
    dataIndex: DataIndex
  ): TableColumnType<IItemListData> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div onKeyDown={(e) => e.stopPropagation()} style={{ padding: 8 }}>
        <Input
          // allowClear
          ref={searchInput}
          placeholder={`Search..`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={(e) =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            // icon={<SearchOutlined />}

            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>

          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
    ),
  });

  // --------- Table list data ---------
  React.useEffect(() => {
    setLoading(true);
    getListData(
      searchGroupName,
      filterGroupTypes,
      // searcCholumns.groupName,
      // searchColumns.aliasName,
      // searchColumns.order,
      "",
      filterGroupId,
      SearchLedgerByGroupsTypes.All,
      ledgerStatus,
      searchColumns.parentGroup
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    trigger,
    searchGroupName,
    filter,
    filterGroupTypes,
    searchColumns,
    filterGroupId,
    ledgerStatus,
  ]);

  const getListData = async (
    search: string,
    GroupType?: GroupTypes,
    groupName?: string,
    // aliasName?: string,
    // order?: string,
    groupId?: string,
    SearchLedgerByGroupsTypes?: SearchLedgerByGroupsTypes,
    ledgerStatus?: LedgerStatus,
    groupSearch?: string
  ) => {
    await ChartofAccountServicesNew.getGroupList(
      currentClient?.id!,
      currentClient?.productModule,
      search,
      filter.sortCol,
      filter.sortDir,
      // GroupType,
      // groupName,
      // aliasName,
      // order,
      groupId,
      SearchLedgerByGroupsTypes,
      groupSearch,
      ledgerStatus
    )
      .then((res: any) => {
        if (res.items.length > 0) {
          setGroupDataList(res.items);
        } else if (res?.items.length === 1) {
          const x = [...res?.items[0], ...res?.items[0].children];
          console.log("...........", x);

          setGroupDataList(res?.items[0].children);
        } else {
          setGroupDataList([]);
          console.log("Error", res);
        }
        setLoading(false);
        setTotalRecords(res?.totalRecords);
      })
      .catch((err: any) => {
        setLoading(false);
        console.log("Error", err);
      });
  };

  React.useEffect(() => {
    setLoading(true);
    const getListData = async () => {
      await ChartofAccountServicesNew.getGroupList(
        currentClient?.id!,
        currentClient?.productModule
      )
        .then((res: any) => {
          if (res.items?.length > 0) {
            console.log("data..........", res.items);

            setGroupData(res.items);
            //  if (props.editData.id) {
            //    setGroupType(props.editData?.group_Type);
            //    form.setFieldsValue(props.editData);
            //  }
            setLoading(false);
          } else {
            setGroupData([]);
            console.log("Error", res);
            setLoading(false);
          }
        })
        .catch((err: any) => {
          setLoading(false);
          console.log("Error", err);
        });
      //  setTotalRecords(res?.totalRecords);
    };
    getListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  // --------- Table Columns  ---------
  const columns: ColumnsType<IItemListData> = [
    {
      title: "Group Name",
      dataIndex: "group_Name",
      key: "group_Name",
      width: "30%",
      sorter: true,
      defaultSortOrder: "ascend",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <NavLink
            to={`/accounts/clients/${currentClient?.id}/master/group/${record.id}`}
            state={{
              groupList: groupData,
            }}
          >
            {record?.group_Name}
          </NavLink>
        </Skeleton>
      ),
      // ...getColumnSearchProps("group_Name"),
    },
    {
      title: "Alias/Short Name",
      dataIndex: "aliasName",
      key: "aliasName",
      width: "25%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.aliasName}
        </Skeleton>
      ),
      // ...getColumnSearchProps("aliasName"),
    },
    {
      title: "Parent Group",
      dataIndex: "parent_Name",
      key: "parent_Name",
      width: "30%",
      // sorter: true,
      render: (_, record) => {
        return (
          <Skeleton
            active
            loading={loading}
            paragraph={{ rows: 1 }}
            title={false}
          >
            {record?.parent_Name}
          </Skeleton>
        );
      },
      ...getColumnSearchProps("parent_Name"),
    },
    {
      title: "Action",
      dataIndex: "isActive",
      width: "10%",
      render: (_, record) => (
        <Skeleton
          active
          paragraph={{
            rows: 1,
          }}
          loading={loading}
          title={false}
          className="cursor"
        >
          <div
            style={{
              display: "flex",
              gap: "15px",
              justifyContent: "flex-start",
            }}
          >
            {record.status === LedgerStatus.active ? (
              <>
                <Tooltip
                  title={
                    record?.editable
                      ? "Edit"
                      : "Default groups are not editable"
                  }
                  placement="top"
                  color={record?.editable ? "#444653" : "rgb(216 207 207)"}
                >
                  <EditOutlined
                    // className="ca-edit-btn"
                    style={{
                      color: record?.editable ? "#444653" : "rgb(216 207 207)",
                    }}
                    onClick={() => {
                      record?.editable &&
                        setOpenModel({
                          open: true,
                          param: record,
                        });
                    }}
                  />
                </Tooltip>

                <Tooltip
                  title={
                    record?.editable
                      ? "Delete"
                      : "Default groups are not Delete"
                  }
                  placement="top"
                  color={record?.editable ? "red" : "rgb(216 207 207)"}
                >
                  <DeleteTwoTone
                    className="ca-delete-btn cursor"
                    twoToneColor={
                      record?.editable ? "#ff879d" : "rgb(216 207 207)"
                    }
                    onClick={() => {
                      if (record?.editable) {
                        console.log(record, "record");
                        setOpenModel({ ...openModel, param: record });
                        setConfirmation(true);
                        setSelectChange(false);
                      }
                    }}
                  />
                </Tooltip>
              </>
            ) : (
              <Tooltip title="Revive Ledger">
                <SyncOutlined
                  onClick={() => {
                    setSelectChange(true);
                    setConfirmation(true);
                    setOpenModel({ ...openModel, param: record });
                  }}
                />
              </Tooltip>
            )}
          </div>
        </Skeleton>
      ),
    },
  ];

  //----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "number",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("sort", sort);

    setFilterGroupTypes(sort?.group_Type);
  };

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        // marginTop: "6px",
        // padding: "48px 0px 0px 0px",
        marginTop: 35,
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <React.Fragment>
        <Row
          justify="space-between"
          style={{
            // paddingTop: props?.partyId ? "10px" : "0px"
            // marginTop: 10,
            padding: "0px 0px 3px 0px",
          }}
        >
          <Col>
            <Space size={width <= 768 ? 465 : 5}>
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  // shortcut="Ctrl+A"
                  onClick={() => setOpenModel({ open: true, param: "" })}
                >
                  Group
                </Button>
              </Col>
              {/* <Button
              style={{
                marginLeft: "10px",
              }}
              icon={<ArrowDownOutlined />}
            >
              Export
            </Button> */}
            </Space>
          </Col>

          <Col
            style={{
              marginTop: width <= 768 ? 15 : "",
            }}
          >
            <Row gutter={width <= 768 ? 98 : 8}>
              <Col>
                <Input
                  allowClear
                  style={{ width: "200px", border: "1px solid #f1f3ff" }}
                  placeholder="Search Group.."
                  onChange={(e: any) => {
                    setLoading(true);
                    if (!e.cancelable) {
                      const searchGroupName = setTimeout(() => {
                        setPage(1);
                        setSearchGroupName(e.target.value);
                      }, 1000);
                      return () => clearTimeout(searchGroupName);
                    } else {
                      // console.log("Click cancle............");
                      setSearchGroupName(e.target.value);
                    }
                  }}
                  suffix={
                    loading || searchGroupName ? (
                      ""
                    ) : (
                      <img alt="Search.." src={search} />
                    )
                  }
                />
              </Col>
              <Col>
                <Select
                  placeholder="Filter Group"
                  showSearch
                  allowClear
                  style={{ width: "200px" }}
                  onChange={(e: string) => setFilterGroupId(e)}
                  filterOption={(input, option) =>
                    (option?.label?.toString() ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={groupData?.map((value: any, index: number) => ({
                    key: index + value?.id,
                    value: value?.id,
                    label: value?.group_Name,
                  }))}
                />
              </Col>

              <Col>
                <Select
                  defaultValue={LedgerStatus.active}
                  style={{ width: "100px" }}
                  onChange={(e: LedgerStatus) => {
                    setPage(1);
                    setLedgerStatus(e);
                  }}
                  options={[
                    { value: LedgerStatus.all, label: "All" },
                    { value: LedgerStatus.active, label: "Active" },
                    { value: LedgerStatus.deactive, label: "Delete" },
                  ]}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* --------- Table --------- */}
        <div>
          <Table
            columns={columns}
            dataSource={
              loading
                ? (Array.from({ length: pageSize }, (_, index) => ({
                    key: `loading-${index}`,
                  })) as IItemListData[])
                : groupDataList
            }
            locale={{
              triggerDesc: "",
              triggerAsc: "",
              cancelSort: "",
            }}
            onChange={tableSort}
            className={`Tabel-style table-${
              themeData?.componentSize ?? "middle"
            }`}
            pagination={{
              total: totalRecords,
              current: page,
              pageSize: pageSize,
              showSizeChanger: true,
              onChange: (page: number, pageSize: number) => {
                setPage(page);
                setPageSize(pageSize);
              },
              pageSizeOptions: ["10", "15", "25", "50", "100"],
              showTotal: (totalRecords, page) =>
                `${page[0]}-${page[1]} of ${totalRecords} items`,
            }}
            scroll={
              pageSize > 15
                ? { x: 1500, y: window.innerHeight - 300 }
                : undefined
            }
          />
        </div>
        {openModel.open && (
          <AddGroup
            open={openModel.open}
            editData={openModel?.param}
            onCancel={() => setOpenModel({ open: false, param: "" })}
            setTrigger={() => setTrigger(!trigger)}
            groupData={groupData}
          />
        )}
        {confirmation && (
          <ConfirmationModal
            open={confirmation}
            onNo={() => {
              setConfirmation(false);
              setOpenModel({
                open: false,
                param: "",
              });
            }}
            onYes={async () =>
              await ChartofAccountServicesNew.deleteGroup(
                currentClient?.id!,
                openModel?.param?.id
              )
                .then((res: any) => {
                  if (res.result) {
                    console.log("res", res);
                    setTrigger((x: boolean) => !x);
                    openNotification(
                      "success",
                      `${selectChange ? "Active" : "Delete"} Successfully`
                    );
                    setConfirmation(false);
                  } else if (res?.message) {
                    openNotification("error", res?.message);
                    setConfirmation(false);
                  }
                })
                .catch((err: any) => {
                  openNotification("error", err);
                  setConfirmation(false);
                  console.log("error", err);
                })
            }
            text={`Are you sure you want to ${
              selectChange ? "Active" : "Delete"
            } Group "${openModel?.param?.group_Name}"?`}
          />
        )}
      </React.Fragment>
    </Card>
  );
};

export default GroupTable;
