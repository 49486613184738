import React from "react";
import { Route, Routes } from "react-router-dom";
import CreditNotes from "./CreditNotes";
import Quotation from "./Quotation";
import PaymentIn from "./PaymentIn";
import SubSaleInvoice from "./SubSalesInvoice";
import Items from "../Purchase/Items";
import ResourceNotFound from "../../Common/ResourceNotFound";
import DeliveryChallan from "./DeliveryChallan";
import SalesOrderDashboard from "./SalesOrderDashboard";
import { ItemType } from "../../Services/ItemsService";
import Customers from "./Customers";
import LedgerTable from "../Master/Ledger/LedgerTable";
import { SearchLedgerByGroupsTypes } from "../../Services/LedgerService";

const SalesNestedRoute = () => {
  return (
    <Routes>
      <Route path="/invoices" element={<SubSaleInvoice />} />
      {/* <Route path={"/"} element={<SubSaleInvoice />} /> */}
      <Route path="/creditNotes" element={<CreditNotes />} />
      <Route path="/receipts" element={<PaymentIn />} />
      <Route path="/quotation" element={<Quotation />} />
      <Route path="/order" element={<SalesOrderDashboard />} />
      <Route path="/deliveryChallan" element={<DeliveryChallan />} />
      <Route path="/items" element={<Items itemPage={ItemType.SalesItems} />} />
      <Route
        path="/customers"
        element={
          <LedgerTable
            ledgerByGroupsType={SearchLedgerByGroupsTypes.DebtorsOnly}
            showName={"Customers"}
          />
        }
      ></Route>

      {/* <Route path="/try" element={<Add_TryDummy />} /> */}
      <Route path="*" element={<ResourceNotFound pageHeight="100%" />} />
    </Routes>
  );
};

export default SalesNestedRoute;
