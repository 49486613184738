import {
  DownCircleFilled,
  DownloadOutlined,
  EyeTwoTone,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
} from "antd";
import React, { useContext } from "react";
import { dayjs } from "../../../Utilities/dayjs";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";
import ReportsServiceNew from "../../Services/ReportsServiceNew";
import Table, { ColumnsType } from "antd/es/table";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import ChartofAccountServicesNew from "../../Services/ChartofAccountServicesNew";
import InvoiceExportService from "../../Services/InvoiceExportDetails";

import VoucherServices from "../../Services/VoucherServices";
import { Utils } from "../../../Utilities/Utils";
const { RangePicker } = DatePicker;

const BRSReport = ({ Type }: any) => {
  const { currentClient, companySettings } = useClient();
  const [width, setWidth] = React.useState<any>(window.innerWidth);

  const [data, setData] = React?.useState<any[]>([]);
  const [ledgerId, setLedgerId] = React?.useState<any>();
  const [groupData, setGroupData] = React.useState<any[]>([]);
  const [groupId, setGroupId] = React?.useState<any>();
  const [loading, setLoading] = React?.useState<any>(false);
  const [voucherList, setVoucherList] = React?.useState<any>();
  const { openNotification } = useContext(notificationContext);
  const [ledgers, setLedgers] = React?.useState<any>();
  const [exportType, setExportType] = React.useState<number>(-1);
  const [exportBRSData, setExportBRSData] = React.useState<any[]>([]);
  const [ledDropdownLoading, setLedDropdownLoading] = React.useState(false);
  const [filterDate, setFilterDate] = React.useState<any>(
    JSON.parse(localStorage.getItem("LockPeriod")!)
      ? {
        from: JSON.parse(localStorage.getItem("LockPeriod")!).from,
        to: JSON.parse(localStorage.getItem("LockPeriod")!).to,
      }
      : {
        from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
        to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
      }
  );

  React?.useEffect(() => {
    if (currentClient?.id && (exportType === -1 || exportType)) {
      getAllReconcileHistory();
    }
  }, [
    currentClient?.id,
    ledgerId?.value,
    filterDate?.from,
    filterDate?.to,
    exportType,
  ]);

  console.log("ffff", ledgerId?.value);

  const getAllReconcileHistory = async () => {
    if (exportType < 1) {
      setLoading(true);
    }
    let res =
      ledgerId?.value &&
      (await VoucherServices?.GetAllReconcileHistory(
        currentClient?.id,
        ledgerId?.value,
        dayjs(filterDate?.from, "DD/MM/YYYY").toISOString(),
        dayjs(filterDate?.to, "DD/MM/YYYY").endOf("day").toISOString()
      ));
    if (res) {
      // setLoading(false);

      let brsData = res?.result?.map((d: any, index: number) => {
        return {
          closingBalAmount: Utils?.getFormattedNumber(d?.closingBalAmount),
          closingBalDate: dayjs(d?.closingBalDate).format("DD/MM/YYYY"),
          creditTotalAmount: Utils?.getFormattedNumber(d?.creditTotalAmount),
          debitTotalAmount: Utils?.getFormattedNumber(d?.debitTotalAmount),
          openingBalAmount: Utils.getFormattedNumber(d?.openingBalAmount),
          openingBalDate: dayjs(d?.openingBalDate).format("DD/MM/YYYY"),
          reconcileDate: dayjs(d?.reconcileDate).format("DD/MM/YYYY"),
          reconciledVouchersNo: d?.reconciledVouchersNo,
          voucherList: d?.voucherList,
        };
      });
      if (exportType >= 1) {
        setExportBRSData(brsData);
        if (exportType === 1) {
          exportCsv(brsData);
        } else if (exportType === 2) {
          exportPDF(brsData);
        } else if (exportType === 3) {
          exportExcel(brsData);
        }
      } else {
        setData(brsData);
      }
    }
    setLoading(false);
  };

  const columns: ColumnsType<any> = [
    {
      title: "Reconcile On",
      dataIndex: "reconcileDate",
    },
    {
      title: "Opening Balance Date",
      dataIndex: "openingBalDate",
    },
    {
      title: "Closing Balance Date",
      dataIndex: "closingBalDate",
    },
    {
      title: "Opening Balance",
      dataIndex: "openingBalAmount",
      align: "right",
    },
    {
      title: "Closing Balance",
      dataIndex: "closingBalAmount",
      align: "right",
    },
    {
      title: "Credit Amount",
      dataIndex: "creditTotalAmount",
      align: "right",
    },
    {
      title: "Debit Amount",
      dataIndex: "debitTotalAmount",
      align: "right",
    },
    {
      title: "Vouchers No",
      dataIndex: "reconciledVouchersNo",
      align: "center",

      render: (text, record) => (
        <Popover
          content={content(record.voucherList)}
          title="Vouchers Details"
          trigger="hover"
        >
          {text}{" "}
          <span style={{ marginTop: "5px" }}>
            <EyeTwoTone />
          </span>
        </Popover>
      ),
    },
  ];

  const content = (voucherList: any) => (
    <div>
      {voucherList?.map((v: any, index: any) => (
        <p key={index}>{v?.name}</p>
      ))}
    </div>
  );

  React.useEffect(() => {
    getLedgerData();
  }, [currentClient?.id]);

  const getLedgerData = async () => {
    setLedDropdownLoading(true);
    let res = await LedgerService.getLedgersByGroupName(
      currentClient?.id,
      SearchLedgerByGroupsTypes?.BankOnly
    );
    if (res) {
      setLedgers(res);
      setLedDropdownLoading(false);
    } else {
      setLedDropdownLoading(false);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    const getListData = async () => {
      await ChartofAccountServicesNew.getGroupList(
        currentClient?.id!,
        currentClient?.productModule
      )
        .then((res: any) => {
          if (res.items) {
            setGroupData(res.items);
            // setLoading(false);
          } else {
            setGroupData([]);
            console.log("Error", res);
          }
        })
        .catch((err: any) => {
          console.log("Error", err);
        });
    };
    getListData();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getQuarterPreset: any = (startMonth: number) => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() + startMonth).startOf("month"),
      fromDate.month(fromDate.month() + startMonth + 2).endOf("month"),
    ];
  };
  const thisYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month()).startOf("month"),
      fromDate.month(fromDate.month() + 11).endOf("month"),
    ];
  };
  const lastYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() - 12).startOf("month"),
      fromDate.month(fromDate.month() - 1).endOf("month"),
    ];
  };

  const getLast15Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(15, "day").startOf("day"); // Start 15 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };
  const getLast7Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(7, "day").startOf("day"); // Start 7 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };

  const getThisMonth = () => {
    const now = dayjs();
    return [now.startOf("month"), now.endOf("month")];
  };
  const getLastMonth = () => {
    const now = dayjs();
    const lastMonth = now.subtract(1, "month");
    return [lastMonth.startOf("month"), lastMonth.endOf("month")];
  };

  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => { });
  };
  let path = "Bank Reconcilation Report";
  const columnsA = [
    "Reconcile On",
    "Opening Balance Date",
    "Closing Balance Date",
    "Opening Balance",
    "Closing Balance",
    "Credit Amount",
    "Debit Amount",
    "Vouchers No",
  ];

  const fieldMapping = {
    "Reconcile On": (item: any) => item?.reconcileDate,
    "Opening Balance Date": (item: any) => item?.openingBalDate,
    "Closing Balance Date": (item: any) => item?.closingBalDate,
    "Opening Balance": (item: any) => item?.openingBalAmount,
    "Closing Balance": (item: any) => item?.closingBalAmount,
    "Credit Amount": (item: any) => item?.creditTotalAmount,
    "Debit Amount": (item: any) => item?.debitTotalAmount,
    "Vouchers No": (item: any) =>
      item?.voucherList?.map((v: any, index: any) => v?.name),
  };

  const exportCsv = (exportData: any[]) => {
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      filterDate,
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (exportData: any[]) => {
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const exportExcel = async (exportData: any[]) => {
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const exportContent = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportBRSData.length > 0) {
              exportCsv(exportBRSData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportBRSData.length > 0) {
              exportPDF(exportBRSData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSX"
          onClick={() => {
            if (exportBRSData.length > 0) {
              exportExcel(exportBRSData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSX
        </Button>
      </div>
    </Space>
  );

  console.log("id", ledgerId);

  return (
    <div>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined />}
        style={{
          justifyContent: "center",
          marginTop: "180px",
          textAlign: "center",
        }}
      >
        <Card
          // className="ca-card-body"
          style={{
            boxShadow: "0px 0px 10px 0px #96A1C84D",
            height: "90vh",
            padding: "0px",
          }}
        >
          <div>
            <Row>
              <Col>
                <h3 style={{ color: "rgb(57, 92, 210) ", padding: "0px 2px" }}>
                  Bank Reconcilation Report
                </h3>
              </Col>
            </Row>
            <Row justify={"space-between"} style={{ marginTop: "7px" }}>
              <Col>
                <Row>
                  <Col style={{ marginRight: "10px" }}>
                    <Select
                      showSearch
                      allowClear
                      optionFilterProp="label"
                      loading={ledDropdownLoading}
                      style={{ marginBottom: "3px", width: "200px" }}
                      placeholder="Select Ledger"
                      labelInValue
                      onChange={(val: any) => {
                        setLedgerId(val);
                        setExportBRSData([]);
                        setExportType(-1);
                      }}
                      options={ledgers?.map((l: any) => ({
                        key: l?.key,
                        value: l?.id,
                        label: l?.ledgerName,
                      }))}
                    />{" "}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col>
                    {data?.length === 0 ? (
                      <>
                        <Button
                          type="default"
                          icon={<DownloadOutlined />}
                          disabled
                        >
                          Export
                        </Button>
                      </>
                    ) : (
                      <>
                        <Popover
                          content={exportBRSData}
                          placement="rightTop"
                          trigger="hover"
                        >
                          <Button
                            type="default"
                            icon={<DownloadOutlined />}
                          // onClick={() => exportCsv()}
                          >
                            Export
                          </Button>
                        </Popover>
                      </>
                    )}
                  </Col>
                  <Col>
                    <span style={{ margin: "0px 10px" }}>Date</span>
                    <RangePicker
                      defaultValue={[
                        dayjs(filterDate.from, "DD/MM/YYYY"),
                        dayjs(),
                        // dayjs(filterDate.to, "DD/MM/YYYY"),
                      ]}
                      allowClear
                      format="DD/MM/YYYY"
                      style={{
                        width:
                          width <= 464
                            ? "210px"
                            : width === 375
                              ? "200px"
                              : "250px",
                        border: "1px solid #f1f3ff",
                      }}
                      onChange={(e: any) => {
                        if (e === null) {
                          setFilterDate({});
                        } else {
                          setFilterDate({
                            ...filterDate,
                            from: dayjs(e[0]).format("DD/MM/YYYY"),
                            to: dayjs(e[1]).format("DD/MM/YYYY"),
                          });
                          localStorage.setItem(
                            "LockPeriod",
                            JSON.stringify({
                              ...filterDate,
                              from: dayjs(e[0]).format("DD/MM/YYYY"),
                              to: dayjs(e[1]).format("DD/MM/YYYY"),
                            })
                          );
                          openNotification("success", `Period locked.`);
                        }
                        //console.log(e, "dateCheck");
                        setExportBRSData([]);
                        setExportType(-1);
                      }}
                      presets={[
                        {
                          label: "Today",
                          value: [dayjs().add(0, "d"), dayjs()],
                        },
                        {
                          label: "Last 7 Days",
                          value: getLast7Days(),
                        },
                        {
                          label: "Last 15 Days",
                          value: getLast15Days(),
                        },
                        {
                          label: "This Month",
                          value: getThisMonth(),
                        },
                        {
                          label: "Last Month",
                          value: getLastMonth(),
                        },
                        {
                          label: "Quarter 1",
                          value: getQuarterPreset(0),
                        },
                        {
                          label: "Quarter 2",
                          value: getQuarterPreset(3),
                        },
                        {
                          label: "Quarter 3",
                          value: getQuarterPreset(6),
                        },
                        {
                          label: "Quarter 4",
                          value: getQuarterPreset(9),
                        },
                        {
                          label: "This Year",
                          value: thisYear(),
                        },
                        {
                          label: "Last Year",
                          value: lastYear(),
                        },
                      ]}
                    />
                  </Col>

                  <Col>
                    <DownloadOutlined
                      style={{ color: "rgb(22, 119, 255)", paddingTop: "9px" }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <div style={{ marginTop: "20px" }}>
              <Table
                dataSource={data}
                columns={columns}
                // columns={columns.filter((cl: any) =>
                //   selectedColumns.includes(cl?.dataIndex)
                // )}
                scroll={data?.length > 15 ? { x: 700, y: 620 } : { x: 600 }}
                size="small"
              />
            </div>
          </div>
        </Card>
      </Spin>
    </div>
  );
};

export default BRSReport;
