import {
  Button,
  Col,
  Input,
  Modal,
  notification,
  Row,
  Select,
  Space,
  Spin,
  Table,
} from "antd";
import Search from "antd/es/input/Search";
import React from "react";
import ItemsService, {
  FilterCategoryInItems,
} from "../../../Services/ItemsService";
import { useClient } from "../../../Context/ClientContext";
import { LedgerStatus } from "../../../Services/LedgerService";
import { ColumnsType, TableRowSelection } from "antd/es/table/interface";
import { IndentStyle } from "typescript";
import { LoadingOutlined, SearchOutlined } from "@ant-design/icons";
import { notificationContext } from "../../../Common/PageRoute";

interface IProps {
  open: boolean;
  onCancel: (value: boolean) => void;
  setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  //   editData?: any;
  id: any;
}

// React.useEffect(() => {
//   getItemData();
// }, []);
// const getItemData = async() => {
// let res = await
// };
const MoveToCategoryItemModal = (props: IProps) => {
  const { currentClient } = useClient();
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [searchItem, setSearchItem] = React.useState<string>("");
  const [itemList, setItemList] = React.useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [btnLoading, setBtnLoading] = React.useState(false);
  const { openNotification } = React.useContext(notificationContext);

  const [isfilterCategoryInItems, setIsfilterCategoryInItems] =
    React.useState<FilterCategoryInItems>(
      FilterCategoryInItems.NotAvailableItems
    );
  React.useEffect(() => {
    if (currentClient?.id) {
      setLoading(true);
      getItemLocationsList(
        currentClient?.id,
        props?.id,
        isfilterCategoryInItems,
        (page - 1) * pageSize,
        pageSize,
        searchItem,
        filter?.sortCol,
        filter?.sortDir
      );
    } else if (currentClient?.id === null) {
      console.error("No client found.");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    trigger,
    props.id,
    searchItem,
    filter.sortCol,
    filter.sortDir,
    isfilterCategoryInItems,
    page,
    pageSize,
    // exportType,
  ]);

  // --------- Get Units TableData API ---------
  const getItemLocationsList = async (
    clientId: string | undefined,
    categoryId: string,
    isCategoryInItem: FilterCategoryInItems,
    start: number,
    length: number,
    search?: string,
    sortCol?: string,
    sortDir?: string,
    status?: LedgerStatus
  ) => {
    await ItemsService.getCategoryToItemList(
      clientId,
      categoryId,
      isCategoryInItem,
      start,
      length,
      search,
      sortCol,
      sortDir,
      status
    )
      .then((res: any) => {
        if (res.result?.items?.length > 0) {
          let itemCatData = res.result?.items?.map((v: any, i: number) => {
            return {
              // companyId: v?.companyId,\
              key: i,
              number: v?.number,
              name: v?.name,
              id: v?.id,
              stockQty: v?.stockQty,
              status: v?.status,
            };
          });
          setItemList(itemCatData);
          setLoading(false);
        } else if (res.result?.items?.length === 0) {
          setItemList([]);
          setLoading(false);
        }
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        // setItemList([]);
      });
  };

  console.log("data", itemList);

  const onSelectChange = (
    newSelectedRowKeys: React.Key[],
    selectedRowsData: any[]
  ) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRows(selectedRowsData);

    console.log("Selected Row Keys:", newSelectedRowKeys);
    console.log("Selected Rows Data:", selectedRowsData);
  };

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  console.log("selectedRow", selectedRows);

  const colomns: ColumnsType<any> = [
    {
      title: "Category Name",
      dataIndex: "name",
      key: "id",
    },
    {
      title: "Stock Quantity",
      dataIndex: "stockQty",
      key: "id",
      align: "end",
    },
  ];

  const moveCatData = async () => {
    let add = selectedRows?.map((i: any) => {
      return i.id;
    });
    setBtnLoading(true);
    let res = await ItemsService?.moveCategoryData(
      currentClient?.id,
      props.id,
      isfilterCategoryInItems,
      add
    );
    if (res.status) {
      console.log("res", res);
      openNotification("success", "Save successfully");
      props.onCancel(false);
      setBtnLoading(false);
    } else {
      setBtnLoading(false);
      openNotification("error", res.message);
    }
  };

  console.log("type", isfilterCategoryInItems);

  return (
    <>
      <div>
        <Modal
          open={props.open}
          onCancel={() => props.onCancel(false)}
          title="Move Category Item"
          width={600}
          footer={
            <Space style={{ marginTop: "0px" }}>
              <Button
                onClick={() => {
                  props.onCancel(false);
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                // style={{ width: "80px" }}
                // className="modelSaveButtonMargin"
                loading={btnLoading}
                onClick={moveCatData}
              >
                {isfilterCategoryInItems ===
                FilterCategoryInItems.AvailableItems
                  ? "Remove Items"
                  : "Add Items"}
              </Button>
            </Space>
          }
        >
          <Row gutter={20}>
            <Col span={12}>
              <Select
                value={isfilterCategoryInItems}
                style={{ width: "100%" }}
                options={[
                  {
                    value: FilterCategoryInItems.AvailableItems,
                    label: "Available Items",
                  },
                  {
                    value: FilterCategoryInItems.NotAvailableItems,
                    label: "Not Available Items",
                  },
                ]}
                onChange={(val: any) => {
                  setIsfilterCategoryInItems(val);
                  setSelectedRowKeys([]);
                }}
              />
            </Col>
            <Col span={12}>
              {/* <Search onChange={(e: any) => setSearchItem(e.target.value)} /> */}
              <Input
                allowClear
                style={{ width: "100%" }}
                placeholder="Search"
                onChange={(e: any) => setSearchItem(e.target.value)}
                suffix={loading || searchItem ? "" : <SearchOutlined />}
              />
            </Col>
          </Row>
          <div style={{ marginTop: "30px" }}>
            <Spin spinning={loading} indicator={<LoadingOutlined />}>
              <Table
                rowSelection={rowSelection}
                columns={colomns}
                dataSource={itemList}
                pagination={false}
              />
            </Spin>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default MoveToCategoryItemModal;
