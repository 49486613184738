import { ApiUtility } from "../../Utilities/ApiUtility.axios";
import { Voucher_Type } from "./VoucherServices";
class TermsAndConditions {
  private routes = "/v1/API/Acc_TnC/";

  getTermsAndConditionList = (
    companyId: string | undefined,
    voucherType: any,
    start?: number,
    length?: number,
    search?: string,
    voucherTitle?: string,
  ) =>  
    ApiUtility.get(
      `${this.routes}${companyId}/GetTnCList?voucherType=${voucherType}`,
      {
        voucherTitle,
        start,
        length,
        search,
      }
    );

  getTermsAndConditionbyId = (
    companyId: string | undefined,
    templateId: string,
    voucherType: any,
    VoucherTitle?: string
  ) =>
    // ApiUtility.get(
    //   `${this.routes}${companyId}/${templateId}?voucherType=${voucherType}&VoucherTitle=${voucherTitle}`
    // );
    ApiUtility.get(`${this.routes}${companyId}/${templateId}`, {
      voucherType,
      VoucherTitle,
    });

  // https: //localhost:7142/v1/API/Acc_TnC/672db523a21ee7a43617b364?voucherType=6&VoucherTitle=672db523a21ee7a43617b364
  saveData = (
    companyId: string | undefined,
    templateId: string,
    voucherType: Voucher_Type,
    voucherTitle: string,
    model: any
  ) =>
    ApiUtility.post(
      `${this.routes}${companyId}${
        templateId && "/" + templateId
      }?voucherType=${voucherType}&VoucherTitle=${voucherTitle}`,
      model
    );

  deleteDatabyId = (companyId: string | undefined, templateId: string) =>
    ApiUtility.delete(`${this.routes}${companyId}/${templateId}`);
}

export default new TermsAndConditions();
