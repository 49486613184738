import { Button, Form, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import ProductService, {
  ApplicationUserStatus,
} from "../../Services/ProductService";

interface Props {
  onCancel: (isOpen: boolean) => void;
  isModelOpen: boolean;
  setProductData: (product: any) => void;
  productData?: any;
}

const AddProductDetails: React.FC<Props> = ({
  onCancel,
  isModelOpen,
  setProductData,
  productData,
}) => {
  console.log("props", productData, setProductData);

  const [form] = Form.useForm();
  const [productList, setProductList] = useState<any[]>([]);
  useEffect(() => {
    getProductDetails();
  }, []);

  const getProductDetails = async () => {
    try {
      let res = await ProductService?.getProductDetails(
        0,
        0,
        "",
        ApplicationUserStatus?.Active
      );
      setProductList(
        res?.result.map((p: any, index: number) => ({
          key: index,
          value: p.id,
          label: p.name,
          title: {
            productModule: p?.productModule,
          },
        }))
      );
    } catch (error) {
      console.error("Error fetching product details");
    }
  };

  const saveData = () => {
    form.validateFields().then((values) => {
      console.log(productData?.id === values?.name?.value);

      // if (productData?.id !== values?.name?.value) {
      const updatedProduct = {
        id: values?.name?.value,
        name: values?.name?.label,
        productModule: values?.name?.title?.productModule,
      };
      setProductData(updatedProduct);
      form.resetFields();
      // } else {
      //   console.log("Already exist");
      // }
    });
  };

  console.log("productData=>>>", productData);

  useEffect(() => {
    if (productData) {
      form.setFieldsValue({
        name: {
          label: productData.name,
          value: productData.id,
          title: { productModule: productData.productModule },
        },
      });
    } else {
      form.resetFields();
    }
  }, [productData, form]);

  return (
    <Modal
      title={productData ? "Edit Product" : "Add Product"}
      onCancel={() => onCancel(false)}
      open={isModelOpen}
      footer={
        <>
          <Button onClick={() => onCancel(false)}>Cancel</Button>
          <Button type="primary" onClick={() => saveData()}>
            Save
          </Button>
        </>
      }
    >
      <Form form={form}>
        <Form.Item
          name="name"
          label="Product Name"
          rules={[{ required: true }]}
        >
          <Select options={productList} labelInValue />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddProductDetails;
