import React, { useEffect } from "react";
import { useHookstate } from "@hookstate/core";
import { useLocation } from "react-router-dom";
// import ClientService from "../Services/ClientService";
import ClientService, { Product_Module } from "../Services/ClientService";
import { FullPageSpinner } from "../../Common/FullPageSpinner";
import SettingService, {
  ICompanyInfo,
  ICompanyInformation,
  ICompanySettings,
  SettingsSections,
} from "../Services/SettingService";
import { InvoiceSkeleton, LayoutSkeleton } from "../../Common/Skeleton";
import { AccountGroups, CompanyGSTTypes } from "../../Types/CommonConstants";
import { setCurrentClientData } from "../Redex/ClientRedusSlice";
import { useDispatch } from "react-redux";

export interface IClient {
  addresses?: any[];
  appStartDate: any;
  autoNumber: number;
  businessStart: any;
  //clientType: string | null;
  gstType: CompanyGSTTypes;
  createdBy: any;
  clientId?: any;
  defaultContact: any;
  email: string;
  id: string;
  isShippingSame: boolean;
  businessName: string;
  note: string;
  panNo: string;
  phone: string;
  practiceId: number;
  recordStatus: number;
  state: number;
  registeredAddress: any;
  registrationNo: string;
  shippingAddress: any;
  status: number;
  type: number;
  updatedBy: any;
  yearEnd: any;
  productModule?: Product_Module;
}

interface IAccountGroups {
  createdBy: any;
  id: number;
  name: string;
  orderNo: number;
  updatedBy: any;
}

interface IAccountTypes {
  businessType: any[];
  code: string;
  createdBy: any;
  group: number;
  id: number;
  name: string;
  nature: number;
  updatedBy: any;
}

interface IGstSlabs {
  rate: number;
  name: string;
  id: number;
}

export enum IUpdateCompanySettings {
  onlyUpdate,
  addNew,
  delete,
}

interface IGstSlabs extends Array<IGstSlabs> {}

interface IClientContextProps {
  currentClient?: IClient;
  //clientAccountGroups?: IAccountGroups;
  //clientAccountTypes?: IAccountTypes;
  gstSlabs?: IGstSlabs;
  companySettings?: ICompanyInformation;
  updateCompanySettings: (
    section: SettingsSections,
    setting: Partial<ICompanyInformation>,
    val: any,
    voucherTitle?: string,
    addNewSettingCustomization?: IUpdateCompanySettings
  ) => any;
  //getAccountTypesOptions: (skipgroups?: AccountGroups[]) => any[];

  loading?: boolean;
  activeKey?: string;
  setActiveKey?: any;
  totalDue?: number;
  setTotalDue?: any;
  setSalesInvoiceList?: any;
  salesInvoiceList?: any;
  setStatus?: any;
  status?: any;
  expensesList?: any;
  setExpensesList?: any;
  incomeList?: any;
  setIncomeList?: any;
  estimatehide?: any;
  SetEstimateHide?: any;
  DisplayResult?: boolean;
  setDisplayResult?: any;
  makeNewInvoice?: boolean;
  setMakeNewInvoice?: any;
}

interface IClientDataProviderProps {
  clientId: string;
  children?: any;
}

const ClientContext = React.createContext<Partial<IClientContextProps>>({});

const ClientDataProvider = ({
  clientId,
  children,
}: IClientDataProviderProps) => {
  // console.log("id", clientId);
  const location = useLocation();
  const dispatch = useDispatch();
  const state = useHookstate<Partial<IClientContextProps>>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [skeletonLoading, setSkeletonLoading] = React.useState<boolean>(false);
  const [activeKey, setActiveKey] = React.useState<string>("1");
  const [totalDue, setTotalDue] = React.useState<number>(0);
  const [status, setStatus] = React.useState<any>();
  const [DisplayResult, setDisplayResult] = React.useState<boolean>(false);
  const [makeNewInvoice, setMakeNewInvoice] = React.useState<boolean>(true);
  const [allAccounts, setAllAccounts] = React.useState<any>();
  const currentClient = state.currentClient.get({ noproxy: true });
  //const clientAccountGroups = state.clientAccountGroups.get({ noproxy: true });
  //const clientAccountTypes = state.clientAccountTypes.get({ noproxy: true });
  const gstSlabs = state.gstSlabs.get({ noproxy: true });
  const companySettings = state.companySettings.get({ noproxy: true });

  const updateCompanySettings = async (
    section: SettingsSections,
    setting: Partial<ICompanyInformation>,
    val: ICompanySettings,
    voucherTitle?: string,
    addNewSettingCustomization?: IUpdateCompanySettings
  ) => {
    // console.log("setting", setting, state.companySettings);
    // state.companySettings.merge({ ...setting });
    const result =
      addNewSettingCustomization === IUpdateCompanySettings.addNew
        ? await SettingService.addSettingCustomization(clientId!, val)
        : addNewSettingCustomization === IUpdateCompanySettings.delete
        ? await SettingService.deleteSettingCustomization(
            clientId!,
            voucherTitle!
          )
        : await SettingService.updateCompanySettings(
            clientId!,
            section,
            val,
            voucherTitle
          );

    // debugger;
    if (result.status) {
      state.companySettings.merge({ ...setting });
    }
    return result;
  };

  const getAccountTypesOptions = (skipgroups?: AccountGroups[]) => {
    let groups: any[] = allAccounts?.groups || [];
    if (skipgroups && skipgroups?.length > 0)
      groups = groups.filter((v) => !skipgroups.includes(v.id));
    const accountTypes: any[] = allAccounts?.types || [];
    groups.forEach((g) => {
      g.label = g.name;
      g.options = accountTypes
        .filter((t) => t.group === g.id)
        .map((t) => ({ value: t.id, label: t.name }));
    });
    return groups;
  };

  const setCompanyDetails: any = async (clientId: string) => {
    const splittedpath = location.pathname.split("/");
    const Dpath = splittedpath[splittedpath.length - 1];

    if (Dpath === "dashboard") {
      setSkeletonLoading(true);
    } else {
      setLoading(true);
    }

    await Promise.all([
      ClientService.getClient(clientId!),
      //ClientService.clientAccountsProduction(),
      SettingService.getCompanySettings(clientId!, SettingsSections.All),
    ])
      .then((values) => {
        console.log("Values", values);
        setAllAccounts(values[1].result);

        dispatch(setCurrentClientData(values[0]?.result));
        state.merge({
          currentClient: values[0]?.result,
          //clientAccountGroups: values[1].result?.groups,
          gstSlabs: values[1]?.result?.gstSlabs,
          //clientAccountTypes: values[1].result?.types,
          companySettings: values[1]?.result,
        });
        setLoading(false);
        setSkeletonLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        setSkeletonLoading(false);
      });
  };

  React.useEffect(() => {
    if (clientId) setCompanyDetails(clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  // const splittedpath = location.pathname.split("/");
  // let InvPath = splittedpath[splittedpath.length - 1];

  // if (loading) {
  //   return <FullPageSpinner />;
  // } else if (skeletonLoading) {
  //   return <LayoutSkeleton />;
  // } else if (loading && InvPath === "addInvoice") {
  //   return <InvoiceSkeleton loading={loading} setLoading={setLoading} />;
  // }

  let splittedpath = location.pathname.split("/");
  // if (loading) return <FullPageSpinner />;

  let path =
    splittedpath[splittedpath.length - 2] +
    splittedpath[splittedpath.length - 1];
  console.log(path, "this is splitted path");

  // let Dpath = splittedpath[splittedpath.length - 1];
  let InvPath = splittedpath[splittedpath.length - 1];
  if (loading) {
    return <FullPageSpinner />;
  } else if (skeletonLoading) {
    return <LayoutSkeleton />;
  } else if (loading && InvPath === "addInvoice") {
    return <InvoiceSkeleton loading={loading} setLoading={setLoading} />;
  }

  // if (loading && path === "salesinvoices") {
  //   return <FullPageSpinner />;
  // } else if (loading && Dpath === "dashboard") {
  //   return <LayoutSkeleton />;
  // } else if (loading && InvPath === "addInvoice") {
  //   return <InvoiceSkeleton loading={loading} setLoading={setLoading} />;
  // }
  // console.log(Dpath, "this is splitted dpath path");
  return (
    <div>
      <ClientContext.Provider
        value={{
          currentClient: currentClient as IClient,
          //clientAccountGroups: clientAccountGroups as IAccountGroups,
          //clientAccountTypes: clientAccountTypes as IAccountTypes,
          gstSlabs: gstSlabs as IGstSlabs,
          companySettings: companySettings as any,
          updateCompanySettings,
          //getAccountTypesOptions,
          loading,
          activeKey,
          setActiveKey,
          totalDue,
          setTotalDue,
          setStatus,
          status,
          DisplayResult,
          setDisplayResult,
          makeNewInvoice,
          setMakeNewInvoice,
        }}
        children={children}
      />
    </div>
  );
};

export default ClientDataProvider;

export function useClient() {
  const context = React.useContext(ClientContext);
  if (context === undefined) {
    throw new Error("ClientData context not defined");
  }
  return context;
}
