import React, { useContext, useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Space,
  Tag,
  Tooltip,
  Skeleton,
  Progress,
  notification,
  Popover,
  Select,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import {
  PlusOutlined,
  DownloadOutlined,
  FileTextOutlined,
  RetweetOutlined,
  EditOutlined,
  DeleteTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useClient } from "../../Context/ClientContext";
import { useTheme } from "../../Context/ThemeContext";
import noteContext from "../../Context/StyleContext";
import SalesService, {
  DBRecordStatus,
  EstimateStatus,
} from "../../Services/SalesService";
import { dayjs } from "../../../Utilities/dayjs";
import { Utils } from "../../../Utilities/Utils";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { notificationContext } from "../../Common/PageRoute";
import search from "../../Images/search.svg";
import { FiDownload } from "react-icons/fi";
import { HiDotsHorizontal } from "react-icons/hi";
import VoucherServices, {
  OrderStatus,
  Voucher_Type,
  VoucherFilterStatus,
} from "../../Services/VoucherServices";
import ViewEstimateModel from "./ViewEstimateModel";
import { number } from "echarts";
import QuotationServices from "../../Services/QuotationServices";
import { percentCal } from "../../Common/CAPComponents";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import InvoiceExportService from "../../Services/InvoiceExportDetails";
import DraftService from "../../Services/DraftService";
import DownloadService, { ViewType } from "../../Services/DownloadService";
import ViewPreviewModel from "./ViewPreviewModel";

export interface ISalesOrderListData {
  key: React.Key;
  id: string;
  number: string;
  // invoices: { id: string; name: string };
  customerName: { id: string; name: string };
  name: string;
  date: string;
  suppliedQty: number;
  totalQty: number;
  invAmount: number;
  totalInvoiceAmount: number;
  dueBalance: number;
  deleteType: number;
  orderStatus: OrderStatus;
}

interface ISalesInvoiceProps {
  salesOrderList?: any;
  setSalesOrderList: (value: any) => void;
  loading?: boolean;
  setLoading: (value: boolean) => void;
  partyId?: string;
}

const OrderTable = (props: ISalesInvoiceProps) => {
  const { RangePicker } = DatePicker;
  const [open1, setIsOpen1] = React.useState<string>("");
  const location = useLocation();
  const { windowWidth } = useContext(noteContext);
  const { openNotification } = useContext(notificationContext);
  const { currentClient, companySettings } = useClient();
  const { themeData } = useTheme();
  const navigate = useNavigate();
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [salesOrderList, setSalesOrderList] = useState<ISalesOrderListData[]>(
    []
  );
  const [exportType, setExportType] = useState<number>(-1);

  const [exportsalesOrderData, setExportSalesOrderData] = useState<
    ISalesOrderListData[]
  >([]);
  const [openPopArray, setOpenPopArray] = useState<boolean[]>([]);
  const [searchCustomer, setSearchCustomer] = React.useState<string>("");
  const [filterDate, setFilterDate] = React.useState<any>(
    JSON.parse(localStorage.getItem("LockPeriod")!)
      ? {
          from: JSON.parse(localStorage.getItem("LockPeriod")!).from,
          to: JSON.parse(localStorage.getItem("LockPeriod")!).to,
        }
      : {
          from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
          to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
        }
  );
  const [invoiceId, setInvoiceId] = React.useState<string>("");
  const [estimateStatus, setEstimateStatus] = React.useState<number>(0);
  const [deleteEstimate, setDeleteEstimate] = React.useState<any>({
    status: DBRecordStatus.Active,
    open: false,
    name: "",
  });
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [estimateId, setEstimateId] = React.useState<string>("");
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [confirmationModel, setConfirmationModel] =
    React.useState<boolean>(false);
  const [refresh, setRefresh] = React?.useState(false);
  const [orderStatus, setOrderStatus] = React.useState<any>(
    OrderStatus.Pending
  );
  let splitPathInVchType: Voucher_Type =
    location.pathname.split("/")[4] === "sales"
      ? Voucher_Type.SalesOrder
      : Voucher_Type.PurchaseOrder;

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const [loadingButtons, setLoadingButtons] = useState<any>({});
  const downloadAndView = async (
    cliendId: string,
    voucherId: string,
    type: ViewType
    // invId: any
  ) => {
    if (cliendId && voucherId) {
      setLoadingButtons((prev: any) => ({ ...prev, [voucherId]: true }));
      await DownloadService.downloadPdf(
        cliendId,
        voucherId,
        type,
        splitPathInVchType
      )
        .then((res) => {
          console.log("res", res);
          if (res) {
            console.log("download sucess");
            setLoadingButtons((prev: any) => ({ ...prev, [voucherId]: false }));
          }
        })
        .catch((ex) => {
          openNotification("error", ex.message);
        });
    } else {
      openNotification("error", "Invalid Details");
      setLoadingButtons(false);
    }
  };
  // Define rowSelection to bind with the Table component
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // Ensure selectedRows are derived from selectedRowKeys
  const selectedRows = salesOrderList.filter((item: any) =>
    selectedRowKeys.includes(item.key)
  );

  console.log("Selected Row Keys:", selectedRowKeys); // Should show the array of selected keys
  console.log("Selected Row Data:", selectedRows); // Should show the data of the selected rows

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  //-------- Estimate Delivery Challan --------
  // React.useEffect(() => {
  //   if (deleteEstimate.open) {
  //     SalesService.deleteSalesOrder(
  //       currentClient?.id!,
  //       estimateId,
  //       deleteEstimate.status
  //     )
  //       .then((res: any) => {
  //         if (res?.result) {
  //           setTrigger((x) => !x);
  //           console.log(res?.result);
  //           openNotification(
  //             "success",
  //             `${deleteEstimate.status === 1 ? "Delete" : "Active"} SalesOrder`
  //           );
  //           setDeleteEstimate({});
  //         }
  //       })
  //       .catch((ex: any) => {
  //         setDeleteEstimate({});
  //         console.error("Delete Api Error", ex);
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [deleteEstimate.open]);

  // --------- Table Columns  ---------
  const columns: ColumnsType<ISalesOrderListData> = [
    {
      title:
        splitPathInVchType === Voucher_Type.SalesOrder ? "SO. No." : "PO. No.",
      dataIndex: "number",
      width: width <= 1440 ? "10%" : "9%",
      render: (_, record: ISalesOrderListData) => (
        <Skeleton
          active
          loading={props.loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.orderStatus !== OrderStatus.Deleted ? (
            <span
              style={{ color: "#1677FF", cursor: "pointer" }}
              onClick={() => {
                // setIsOpen(true);
                setIsOpen1("viewPreview");
                setInvoiceId(record?.id);
              }}
            >
              {record?.number}
            </span>
          ) : (
            <span style={{ color: "red" }}>{record?.number}</span>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      width: "10%",
      sorter: true,
      key: "date",
      render: (_, record) => (
        <Skeleton
          active
          loading={props.loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title: "Total Qty",
      dataIndex: "totalQty",
      align: "right",
      width: "8%",
      key: "date",
      render: (_, record) => (
        <Skeleton
          active
          loading={props.loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.totalQty}
        </Skeleton>
      ),
    },
    {
      title:
        splitPathInVchType === Voucher_Type.SalesOrder
          ? "Supplied Qty."
          : "Received Qty",
      dataIndex: "suppliedQty",
      align: "right",
      width: "9%",
      key: "date",
      render: (_, record) => (
        <Skeleton
          active
          loading={props.loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.suppliedQty}
        </Skeleton>
      ),
    },
    {
      title: "Pending Qty",
      dataIndex: "suppliedQty",
      width: "9%",
      key: "suppliedQty",
      align: "right",
      render: (_, record) => (
        <Skeleton
          active
          loading={props.loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.totalQty - record?.suppliedQty}
        </Skeleton>
      ),
    },
    {
      title: "Amount",
      dataIndex: "invAmount",
      sorter: true,
      align: "right",
      key: "invAmount",
      render: (_, record) => (
        <Skeleton
          active
          loading={props.loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
        >
          {record?.orderStatus !== OrderStatus.Deleted ? (
            <>₹ {Utils.getFormattedNumber(record?.invAmount)}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ₹ {Utils.getFormattedNumber(record?.invAmount)}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Status",
      dataIndex: "address",
      width: "7%",
      // align: "center",
      render: (_, record) => (
        <>
          <Progress
            style={{ padding: "0px", margin: "0px" }}
            percent={Math.round((record?.suppliedQty / record?.totalQty) * 100)}
          />
        </>
      ),
    },
    orderStatus !== VoucherFilterStatus.Draft
      ? {
          title: "   ",
          dataIndex: "icon",
          width: "3%",
          align: "center",
          render: (_, record) => (
            <Skeleton
              active
              loading={props.loading}
              paragraph={{ rows: 1 }}
              title={false}
              className="cursor"
            >
              {/* <FiDownload style={{ color: "#395cd2" }} /> */}
              <Button
                size="small"
                icon={<FiDownload />}
                style={{ border: "none", color: "#395cd2" }}
                loading={!!loadingButtons[record.id]}
                onClick={() =>
                  downloadAndView(
                    currentClient?.id!,
                    record?.id,
                    ViewType.Pdf
                    // record.invNumber?.id
                  )
                }
              />
            </Skeleton>
          ),
        }
      : { title: "   ", dataIndex: "icon", width: "3%" },
    {
      title: "   ",
      dataIndex: "icon2",
      width: "3%",
      align: "center",
      render: (_, record) => (
        <div>
          {record?.orderStatus === OrderStatus.Deleted ? (
            <Skeleton
              active
              loading={props.loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <SyncOutlined />
            </Skeleton>
          ) : (
            <Skeleton
              active
              loading={props.loading}
              paragraph={{ rows: 1 }}
              title={false}
            >
              <Tooltip title="Edit" placement="top" color="#3D4998">
                {orderStatus === VoucherFilterStatus.Draft ? (
                  <Link
                    to={`/accounts/clients/${currentClient?.id}/${
                      splitPathInVchType === Voucher_Type.SalesOrder
                        ? "sales/editSalesOrderDraft"
                        : "purchase/editPurchaseOrderDraft"
                    }/${record?.id}`}
                  >
                    <EditOutlined
                      className="ca-edit-btn"
                      onClick={() =>
                        `/accounts/clients/${currentClient?.id}/${
                          splitPathInVchType === Voucher_Type.SalesOrder
                            ? "sales/editSalesOrderDraft"
                            : "purchase/editPurchaseOrderDraft"
                        }/${record?.id}`
                      }
                      style={{ color: "#444653" }}
                    />
                  </Link>
                ) : (
                  <Link
                    to={`/accounts/clients/${currentClient?.id}/${
                      splitPathInVchType === Voucher_Type.SalesOrder
                        ? "sales/editSalesOrder"
                        : "purchase/editPurchaseOrder"
                    }/${record?.id}`}
                  >
                    <EditOutlined
                      className="ca-edit-btn"
                      onClick={() =>
                        `/accounts/clients/${currentClient?.id}/${
                          splitPathInVchType === Voucher_Type.SalesOrder
                            ? "sales/editSalesOrder"
                            : "purchase/editPurchaseOrder"
                        }/${record?.id}`
                      }
                      style={{ color: "#444653" }}
                    />
                  </Link>
                )}
              </Tooltip>
            </Skeleton>
          )}
        </div>
      ),
    },
    orderStatus !== VoucherFilterStatus.Draft
      ? {
          title: "   ",
          dataIndex: "icon3",
          width: "3%",
          align: "center",
          render: (_, record) => (
            <div>
              {record?.orderStatus === OrderStatus.Deleted ? (
                <></>
              ) : (
                <Skeleton
                  active
                  loading={props.loading}
                  paragraph={{ rows: 1 }}
                  title={false}
                >
                  {record?.totalQty - record?.suppliedQty > 0 ? (
                    <Tooltip
                      title="Create Invoice"
                      placement="top"
                      color="#64CA31"
                    >
                      <Link
                        // splitPathInVchType
                        to={`/accounts/clients/${currentClient?.id}/${
                          splitPathInVchType === Voucher_Type.SalesOrder
                            ? "sales/addInvoice"
                            : "purchase/addPurchaseBill"
                        }/order/${record.id}`}
                      >
                        <FileTextOutlined style={{ color: "#868ea5" }} />
                      </Link>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Complete Order" placement="top">
                      <FileTextOutlined style={{ color: "green" }} />
                    </Tooltip>
                  )}
                </Skeleton>
              )}
            </div>
          ),
        }
      : {
          title: "   ",
          dataIndex: "icon3",
          width: "3%",
        },
    {
      title: "   ",
      dataIndex: "icon3",
      width: "3%",
      align: "center",
      render: (_, record, index) => (
        <div>
          {record.orderStatus !== OrderStatus.Deleted ? (
            <>
              {" "}
              {/* {!record?.id && record.deleteType === 0 && ( */}
              <Skeleton
                active
                loading={props.loading}
                paragraph={{ rows: 1 }}
                title={false}
              >
                <Tooltip title="Delete" placement="top" color="red">
                  <DeleteTwoTone
                    className="ca-delete-btn"
                    twoToneColor="#ff879d"
                    onClick={() => {
                      setConfirmationModel(true);
                      setEstimateId(record.id);
                      // deleteData(record?.id);
                      setDeleteEstimate({
                        ...deleteEstimate,
                        status: DBRecordStatus.Deleted,
                        name: record.number,
                      });
                      handleOpenChange(index, false);
                    }}
                  />
                </Tooltip>
              </Skeleton>
              {/* )} */}
              {!record?.id && record.deleteType === 1 && (
                <Skeleton
                  active
                  loading={props.loading}
                  paragraph={{ rows: 1 }}
                  title={false}
                >
                  <Tooltip
                    title="Revive Sales Order"
                    placement="top"
                    color="green"
                  >
                    <RetweetOutlined
                      className="ca-invoice-btn"
                      style={{ color: "#64CA31" }}
                      onClick={() => {
                        setConfirmationModel(true);
                        setEstimateId(record.id);
                        setDeleteEstimate({
                          ...deleteEstimate,
                          status: DBRecordStatus.Active,
                          name: record.number,
                        });
                      }}
                    />
                  </Tooltip>
                </Skeleton>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  if (!props?.partyId) {
    columns.splice(1, 0, {
      title: "Party Name",
      dataIndex: "customerName",
      width: "25%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={props.loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.customerName?.name}
        </Skeleton>
      ),
    });
  }

  const handleOpenChange = (columnIndex: number, newOpen: boolean) => {
    const updatedOpenPopArray = [...openPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenPopArray(updatedOpenPopArray);
  };

  const deleteData = async (id: string) => {
    let res =
      orderStatus === VoucherFilterStatus.Draft
        ? await DraftService.deleteDraft(currentClient?.id!, id)
        : await QuotationServices?.deleteData(currentClient?.id, id);
    // let res = await QuotationServices?.deleteData(currentClient?.id, id);
    if (res?.result) {
      setRefresh((x: boolean) => !x);
      notification.success({
        message: "",
        description: "Deleted successfully.",
        placement: "topRight",
      });
    } else {
      notification.error({
        message: "",
        description: res?.message || "An error occurred while Delete.",
        placement: "topRight",
      });
    }
  };

  // --------- Get SalesOrder TableData API ---------

  // --------- Get TableData API ---------
  React.useEffect(() => {
    if (currentClient?.id && (exportType === -1 || exportType)) {
      getOrderList(
        currentClient?.id,
        splitPathInVchType,
        dayjs(filterDate.from, "DD/MM/YYYY").toISOString(),
        dayjs(filterDate.to, "DD/MM/YYYY").endOf("day").toISOString(),
        exportType >= 1 ? 0 : (page - 1) * pageSize,
        exportType >= 1 ? 0 : pageSize,
        filter.sortCol,
        filter.sortDir,
        undefined,
        searchCustomer,
        orderStatus
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    filterDate.from,
    filterDate.to,
    searchCustomer,
    refresh,
    exportType,
    orderStatus,
  ]);

  const getOrderList = async (
    clientId: string,
    Voucher_Type: Voucher_Type,
    fromDate: string = "",
    toDate: string = "",
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    partyId?: any,
    search?: string,
    status?: OrderStatus
  ) => {
    try {
      if (exportType < 1) {
        props?.setLoading(true);
        // props.setLoadSkeleton(true);
      }
      console.log("Order Status: ", orderStatus);

      let res: any =
        orderStatus === VoucherFilterStatus.Draft
          ? await DraftService.getVoucherDraftList(
              clientId,
              Voucher_Type,
              fromDate,
              toDate,
              start,
              length,
              sortCol,
              sortDir,
              partyId,
              search
            )
          : await QuotationServices.getQuotationList(
              clientId,
              Voucher_Type,
              fromDate,
              toDate,
              start,
              length,
              sortCol,
              sortDir,
              partyId,
              search,
              status
            );
      if (res?.result?.voucherItems?.length > 0) {
        console.log("res.items = ", res?.result?.voucherItems);
        // table list data

        let orderList = res?.result?.voucherItems?.map(
          (v: any, index: number) => {
            return {
              key: index,
              id: v?.vId,
              date: dayjs(v?.date).format("DD/MM/YYYY"),
              number: v?.number,
              customerName: {
                id: v?.pLedger[0].id,
                name: v?.pLedger[0]?.ledgerName,
              },
              invAmount: v?.invAmount,
              totalQty: v?.totalQty,
              suppliedQty: v?.suppliedQty,
              refundAmount: v?.refundAmount,
              receivedAmount: v?.receivedAmount,
              dueBalance: v?.dueAmount,
              orderStatus: v?.orderStatus,
            };
          }
        );

        if (exportType >= 1) {
          setExportSalesOrderData(orderList);
          if (exportType === 1) {
            exportCsv(orderList);
          } else if (exportType === 2) {
            exportPDF(orderList);
          } else if (exportType === 3) {
            exportExcel(orderList);
          }
        } else {
          //-----For Quotation/Estimate Graphs-----//
          props?.setSalesOrderList({
            totalInvoiceAmount: res?.result?.totalAmount,
            suppliedQty: res?.result?.totalSuppliedQty,
            totalQty: res?.result?.totalQty,
            pending: res?.result?.totalQty - res?.result?.totalSuppliedQty,
            totalReceivedAmount:
              res?.result?.totalSuppliedQty +
              res?.result?.totalQty -
              res?.result?.totalSuppliedQty,
          });
          setTotalRecords(res?.totalRecords);
          setSalesOrderList(orderList);
        }
      } else {
        setSalesOrderList([]);
      }

      props?.setLoading(false);
    } catch (ex: any) {
      console.error(ex.message);
      props?.setLoading(false);
      setSalesOrderList([]);
    }
  };

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "AutoNumber",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("sort", filter, filter.column);
  };

  //----- Short-Cut for Sales Order -----

  //----- Short-Cut for Sales Order TimeCondition -----

  let path =
    splitPathInVchType === Voucher_Type.SalesOrder
      ? "Sales Order List"
      : "Purchase Order List";

  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => {
      if (col === "Amount") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc, data) => acc + (data?.invAmount || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(
                props?.salesOrderList?.totalInvoiceAmount
              )
        }`;
      } else if (col === "Total Qty") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc, data) => acc + (data?.totalQty || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(props?.salesOrderList?.totalQty)
        }`;
      } else if (col === "Supplied Qty") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc, data) => acc + (data?.suppliedQty || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(props?.salesOrderList?.suppliedQty)
        }`;
      } else if (col === "Pending Qty") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc, data) =>
                    acc + (data?.totalQty - data?.suppliedQty || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(
                props?.salesOrderList?.totalQty -
                  props?.salesOrderList?.suppliedQty
              )
        }`;
      } else if (col === "Party Name") {
        return "Total";
      } else {
        return ""; // Empty string for other columns
      }
    });
  };

  const columnsA = [
    splitPathInVchType === Voucher_Type.SalesOrder ? "SO. No." : "PO. No.",
    "Party Name",
    "Date",
    "Total Qty",
    splitPathInVchType === Voucher_Type.SalesOrder
      ? "Supplied Qty"
      : "Received Qty",
    "Pending Qty",
    "Amount",
  ];

  const fieldMapping = {
    "SO. No.": (item: any) => item?.number,
    "PO. No.": (item: any) => item?.number,
    "Party Name": (item: any) => item?.customerName?.name,
    Date: (item: any) => item?.date,
    "Total Qty": (item: any) => Utils.getFormattedNumber(item?.totalQty),
    "Supplied Qty": (item: any) => Utils.getFormattedNumber(item?.suppliedQty),
    // "Received Qty": (item: any) => Utils.getFormattedNumber(item?.suppliedQty),
    "Pending Qty": (item: any) =>
      Utils.getFormattedNumber(item?.totalQty - item?.suppliedQty),
    Amount: (item: any) => Utils.getFormattedNumber(item?.invAmount),
  };
  const exportCsv = (invExportData: ISalesOrderListData[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;

    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      filterDate,
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (invExportData: ISalesOrderListData[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;

    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const exportExcel = async (invExportData: ISalesOrderListData[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportsalesOrderData.length > 0) {
              exportCsv(exportsalesOrderData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportsalesOrderData.length > 0) {
              exportPDF(exportsalesOrderData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSX"
          onClick={() => {
            if (exportsalesOrderData.length > 0) {
              exportExcel(exportsalesOrderData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSX
        </Button>
      </div>
    </Space>
  );

  const getQuarterPreset: any = (startMonth: number) => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() + startMonth).startOf("month"),
      fromDate.month(fromDate.month() + startMonth + 2).endOf("month"),
    ];
  };
  const thisYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month()).startOf("month"),
      fromDate.month(fromDate.month() + 11).endOf("month"),
    ];
  };
  const lastYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() - 12).startOf("month"),
      fromDate.month(fromDate.month() - 1).endOf("month"),
    ];
  };

  const getLast15Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(15, "day").startOf("day"); // Start 15 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };
  const getLast7Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(7, "day").startOf("day"); // Start 7 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };

  const getThisMonth = () => {
    const now = dayjs();
    return [now.startOf("month"), now.endOf("month")];
  };
  const getLastMonth = () => {
    const now = dayjs();
    const lastMonth = now.subtract(1, "month");
    return [lastMonth.startOf("month"), lastMonth.endOf("month")];
  };

  return (
    <>
      {/* <Card
    //   size="small"
    //   className="TableCard"
    //   style={{
    //     marginTop: "6px",
    //     padding: "48px 0px 0px 0px",
    //     boxShadow: "0px 0px 10px 0px #96A1C84D",
    //   }}
    // > */}
      <Row
        justify="space-between"
        style={{
          // paddingTop: props?.partyId ? "10px" : "0px"
          // marginTop: 10,
          padding: "0px 0px 3px 0px",
        }}
      >
        <Col>
          <Space>
            {/* <Tooltip
              title={
                splitPathInVchType === Voucher_Type.PurchaseOrder ? (
                  <>
                    Add Purchase Order
                    <br />
                    <Tag>
                      <kbd>Shift + F12</kbd>
                    </Tag>
                  </>
                ) : (
                  <>
                    Add Sales Order
                    <br />
                    <Tag>
                      <kbd>Ctrl + Shift + O</kbd>
                    </Tag>
                  </>
                )
              }
            > */}
            <Button
              type="primary"
              id="gfg"
              icon={<PlusOutlined />}
              onClick={() => {
                splitPathInVchType === Voucher_Type.PurchaseOrder
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/addOrder`
                    )
                  : props?.partyId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/sales/addOrder/${props?.partyId}`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/sales/addOrder`
                    );
              }}
            >
              {splitPathInVchType === Voucher_Type.SalesOrder
                ? "Sales Order"
                : "Purchase Order"}
            </Button>
            {/* </Tooltip> */}

            {salesOrderList?.length === 0 ? (
              <>
                <Button type="default" icon={<DownloadOutlined />} disabled>
                  Export
                </Button>
              </>
            ) : (
              <>
                <Popover content={content} placement="rightTop" trigger="hover">
                  <Button
                    type="default"
                    icon={<DownloadOutlined />}
                    // onClick={() => exportCsv()}
                  >
                    Export
                  </Button>
                </Popover>
              </>
            )}
          </Space>
        </Col>

        <Col
          style={{
            marginTop: width <= 768 ? 15 : "",
          }}
        >
          <Row gutter={width <= 768 ? 63 : 8}>
            <Col>
              <Input
                style={{ width: "200px", border: "1px solid #f1f3ff" }}
                placeholder="Search..."
                allowClear
                onChange={(e: any) => {
                  props?.setLoading(true);
                  if (!e.cancelable) {
                    const data = setTimeout(() => {
                      setPage(1);
                      setSearchCustomer(e.target.value);
                    }, 1000);
                    return () => clearTimeout(data);
                  } else {
                    setSearchCustomer(e.target.value);
                  }
                  setExportSalesOrderData([]);
                  setExportType(-1);
                }}
                suffix={
                  props?.loading || searchCustomer ? (
                    ""
                  ) : (
                    <img alt="search" src={search} />
                  )
                }
              />
            </Col>
            <Col>
              <RangePicker
                defaultValue={[
                  dayjs(filterDate?.from, "DD/MM/YYYY"),
                  dayjs(filterDate?.to, "DD/MM/YYYY"),
                ]}
                format="DD/MM/YYYY"
                style={{
                  width:
                    width <= 464 ? "230px" : width == 375 ? "200px" : "250px",
                  border: "1px solid #f1f3ff",
                }}
                onChange={(e: any) => {
                  if (e === null) {
                    setFilterDate({});
                  } else {
                    setFilterDate({
                      ...filterDate,
                      from: dayjs(e[0]).format("DD/MM/YYYY"),
                      to: dayjs(e[1]).format("DD/MM/YYYY"),
                    });
                    localStorage.setItem(
                      "LockPeriod",
                      JSON.stringify({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      })
                    );
                    openNotification("success", `Period locked.`);
                  }
                  setExportSalesOrderData([]);
                  setExportType(-1);
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: getLast7Days(),
                  },
                  {
                    label: "Last 15 Days",
                    value: getLast15Days(),
                  },
                  {
                    label: "This Month",
                    value: getThisMonth(),
                  },
                  {
                    label: "Last Month",
                    value: getLastMonth(),
                  },
                  {
                    label: "Quarter 1",
                    value: getQuarterPreset(0),
                  },
                  {
                    label: "Quarter 2",
                    value: getQuarterPreset(3),
                  },
                  {
                    label: "Quarter 3",
                    value: getQuarterPreset(6),
                  },
                  {
                    label: "Quarter 4",
                    value: getQuarterPreset(9),
                  },
                  {
                    label: "This Year",
                    value: thisYear(),
                  },
                  {
                    label: "Last Year",
                    value: lastYear(),
                  },
                ]}
              />
            </Col>
            {/* <Col>
              <Select
                style={{ width: windowWidth === 1024 ? 80 : 120 }}
                value={estimateStatus}
                onSelect={(e) => {
                  setPage(1);
                  setEstimateStatus(e);
                }}
                options={[
                  { value: EstimateStatus.All, label: "All" },
                  { value: EstimateStatus.Active, label: "Active" },
                  { value: EstimateStatus.Pending, label: "Pending" },
                  { value: EstimateStatus.Accpected, label: "Accpected" },
                  { value: EstimateStatus.Deleted, label: "Deleted" },
                ]}
              />
            </Col> */}
            <Col>
              <Select
                // clearIcon={true}
                defaultValue={OrderStatus.Pending}
                style={{ width: 120 }}
                allowClear={false}
                options={[
                  { value: OrderStatus.All, label: "All" },
                  { value: OrderStatus.Pending, label: "Pending" },
                  { value: OrderStatus.Completed, label: "Completed" },
                  { value: OrderStatus.Deleted, label: "Deleted" },
                  { value: VoucherFilterStatus.Draft, label: "Draft" },
                ]}
                onSelect={(val: any) => {
                  setPage(1);
                  setOrderStatus(val);
                  setExportType(-1);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        loading={false}
        locale={{
          triggerDesc: "",
          triggerAsc: "",
          cancelSort: "",
        }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={
          props?.loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as ISalesOrderListData[])
            : salesOrderList
        }
        scroll={
          pageSize > 14
            ? { x: window.innerWidth * 0.7, y: window.innerHeight - 580 }
            : undefined
        }
        onChange={tableSort}
        summary={() => {
          return (
            <Table.Summary fixed>
              <Table.Summary.Row
                style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
              >
                <Table.Summary.Cell
                  index={1}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell
                  index={2}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={3} className="fw600">
                  Total
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={4}
                  className="fw600"
                ></Table.Summary.Cell>
                <Table.Summary.Cell index={5} className="fw600" align="right">
                  {Utils.getFormattedNumber(props?.salesOrderList?.totalQty)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={6} className="fw600" align="right">
                  {Utils.getFormattedNumber(props?.salesOrderList?.suppliedQty)}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={7} className="fw600" align="right">
                  {Utils.getFormattedNumber(
                    props?.salesOrderList?.totalQty -
                      props?.salesOrderList?.suppliedQty
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={8} className="fw600" align="right">
                  ₹{" "}
                  {Utils.getFormattedNumber(
                    props?.salesOrderList?.totalInvoiceAmount
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={9} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={10} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={11} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={12} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
                <Table.Summary.Cell index={12} className="fw600">
                  {"   "}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, page) =>
            `${page[0]}-${page[1]} of ${totalRecords} items`,
        }}
      />
      {open && (
        <ViewEstimateModel
          open={open}
          onCancel={() => setIsOpen(false)}
          estimateId={invoiceId}
          voucharType={splitPathInVchType}
        />
      )}
      {open1 === "viewPreview" && (
        <ViewPreviewModel
          open={open1 === "viewPreview"}
          onCancel={() => setIsOpen1("")}
          voucherId={invoiceId}
          invVoucharType={splitPathInVchType}
          partyId={currentClient?.id}
        />
      )}
      {confirmationModel && (
        <ConfirmationModal
          open={confirmationModel}
          text={`Do you really want to ${
            deleteEstimate.status === 1 ? "Delete" : "Active"
          }  this SalesOrder"${deleteEstimate.name}"?`}
          onNo={() => setConfirmationModel(false)}
          onYes={() => {
            setConfirmationModel(false);
            deleteData(estimateId);
          }}
        />
      )}
    </>
  );
};
export default OrderTable;
