import Axios from "axios";
import { ApiUtility, IApiResponse } from "../Utilities/ApiUtility.axios";
import { Utils } from "../Utilities/Utils";
import {
  AOUSERTOKEN,
  InMemoryJWT,
  LocalStorageKeyToken,
} from "../Utilities/InMemoryJWT";
import { message } from "antd";

export interface IUserLoginResponse {
  name?: string;
  token: string;
  tokenExpiry: number;
}

export const AuthService = {
  login: async (
    username: string,
    password: string
  ): Promise<IUserLoginResponse | null> => {
    const response = await Axios.post<IApiResponse<IUserLoginResponse>>(
      "/API/Auth/Login",
      { username, password } as any,
      ApiUtility._axiosOptions({ withCredentials: true })
    );

    if (response.status === 200) {
      if (response.data && response.data.status) {
        const { result } = response.data;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        InMemoryJWT.setToken(result.token, result.tokenExpiry);
        Utils.setSession("ao-user", result);
        return response.data.result;
      } else if (response.data && response.data.message)
        throw message.error(response.data.message);
    }
    if (response.status === 400) {
      throw message.error("Bad data request.");
    }
    return null;
  },
  changePassword: async (id: any, value: any) => {
    return await Axios.post(
      `/API/Auth/ChangePassword/${id}`,
      { ...value },
      ApiUtility._axiosOptions({ withCredentials: true })
    );
  },

  loginAs: async (pid: string, id: string) => {
    const response = await Axios.post<IApiResponse<IUserLoginResponse>>(
      "/auth/" + pid + "/loginas/" + id,
      {} as any,
      ApiUtility._axiosOptions({
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        withCredentials: true,
      })
    );
    if (response.status === 200) {
      if (response.data && response.data.status) {
        const { result } = response.data;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        InMemoryJWT.setToken(result.token, result.tokenExpiry);

        return response.data.result;
      } else if (response.data && response.data.message)
        throw message.error(response.data.message);

      return null;
    }
  },

  forgotpassword: async (email: string) => {
    const response = await Axios.postForm<IApiResponse>(
      "API/Auth/ForgotPassword",
      { email },
      ApiUtility._axiosOptions({ withCredentials: true })
    );

    if (response.status === 200) {
      if (response.data && response.data.status && response) {
        return response.data.result;
      } else if (response.data && response.data.message)
        throw message.error(response.data.message);

      return null;
    }
  },

  resetPassword: async (
    email: string,
    password: string,
    confirmPassword: string,
    token: string
  ) => {
    const response = await Axios.post<IApiResponse>(
      "API/Auth/ResetPassword",
      { email, password, confirmPassword, token },
      ApiUtility._axiosOptions({ withCredentials: true })
    );
    if (response.status === 200) {
      if (response.data && response.data.status) {
        return response.data.result;
      } else if (response.data && response.data.message)
        throw message.error(response.data.message);

      return null;
    }
  },

  logout: async () => {
    // remove user from local storage to log user out
    InMemoryJWT.ereaseToken(true);
    return Promise.resolve();
  },

  getAuthToken: (): string | null => {
    let aoUser = Utils.getSession(AOUSERTOKEN);
    return aoUser?.token || null;
  },

  isAuthenticated: (): boolean | false => AuthService.getAuthToken() !== null,

  userRole() {
    if (AuthService.getAuthToken() != null) {
      let user = Utils.getSession("ao-user");
      return user.role;
    }
  },
};

export const Roles = {
  Admin: "ADMIN",
  Manager: "MANAGER",
  Employee: "EMPLOYEE",
};
