import React, { useEffect, useState } from "react";
import { dep } from "./Depreciation.js";
import {
  Row,
  Button,
  Input,
  Modal,
  Col,
  Form,
  Select,
  TreeSelect,
  Switch,
  Divider,
  Spin,
  Card,
  Table,
  Upload,
  InputNumber,
} from "antd";
import { useClient } from "../../Context/ClientContext";
import FixedAssetsServiceNew, {
  appendObjectToFormData,
} from "../../Services/FixedAssetsServiceNew";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { dayjs } from "../../../Utilities/dayjs";
import { Utils } from "../../../Utilities/Utils";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import ChartofAccountServiceNew from "../../Services/ChartofAccountServicesNew";
import { notificationContext } from "../../Common/PageRoute";
// import AddGroup from "../Master/Group/AddGroup.js";
import ChartofAccountServicesNew from "../../Services/ChartofAccountServicesNew";
import AddGroup from "../Master/Group/AddGroup";
import AddLedger from "../Master/Ledger/AddLedger";
import { ColumnsType } from "antd/es/table/InternalTable.js";
import AssetInvoiceDetail from "./AssetInvoiceDetail";
import { useLocation, useNavigate } from "react-router-dom";
import { useKeyboardShortcuts } from "../../Common/KeyboardShortcuts ";
import EntryLevelKeys from "../../Common/EntryLevelKeys";
// import DocumentPreview from "../../Common/DocumentPreview.js";
// import DocumentPreview from "../../../CapsitechAccountsComponents/Common/DocumentPreview.js";

export enum PmtMethod {
  bank,
  cash,
  fullCredit,
  partialCredit,
}

export enum CalcMethod {
  WrittenDownValue,
  StraightLine,
}
export interface InvoiceDetail {
  key: number;
  date: string;
  voucherDate: string;
  invNumber: string;
  taxCredit: boolean;
  price: number;
  gst: {};
  purPayMethod: PmtMethod;
  partiallyPaidAmount: number;
  purPayDate: string;
  purPaymentLedger: string;
  purPartyLedger: string;
  purPayRef: string;
  qty: number;
  childAssetDetail: ChildAsset[];
  voucherRefs: any;
}
export interface ChildAsset {
  childAssetName: string;
  key: number;
  childAssetNumber: string;
  location: string;
  model: string;
  identityCode: string;
  warrantyExpDate: string;
  lifeCycleDuration: number;
  residualValue: number;
  depreciationStartDate: string;
  description: string;
}

const AssetModel = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  // const [childform] = Form.useForm();
  const location = useLocation();
  const [isDepreciable, setDepreciable] = React.useState<boolean>(true);
  const [singleAssetType, setSingleAssetType] = React.useState<boolean>(true);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [payLedgers, setPayLedgers] = useState<any>([]);
  const [fixedAssetsGroups, setFixedAssetsGroups] = useState<any>([]);
  const [categoryValue, setCategoryValue] = useState<string>("");
  const [voucherDate, setVoucherDate] = useState<string>(dayjs().toISOString());
  const [depMethod, setDepMethod] = useState<CalcMethod>(
    CalcMethod.WrittenDownValue
  );
  const [invoiceDetaildata, setInvoiceDetail] = React.useState<InvoiceDetail[]>(
    []
  );
  const { currentClient, companySettings } = useClient();
  const { openNotification } = React.useContext(notificationContext);
  const [saveButtonLoading, setSaveButtonLoading] = useState<boolean>(false);
  const gstOption = companySettings?.setting?.gstDetails?.gst;
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: "",
  });
  const [deleteUploadId, setDeleteUploadId] = React.useState<string[]>([]);
  const [previewImage, setPreviewImage] = React.useState<{
    isBaseConvert: boolean;
    path: any;
  }>({ isBaseConvert: false, path: "" });
  const [groupDropdownloading, setGroupDropdownloading] = React.useState(false);

  const editAssetId: string = location?.state?.data?.id;
  // console.log("Asset ID: ", editAssetId);

  useEffect(() => {
    const getGroupFixedAssets = async (clientId: String) => {
      if (clientId) {
        // ------- Get Fixed Assets Groups APi ------
        setGroupDropdownloading(true);
        await ChartofAccountServiceNew.getGroupList(
          currentClient?.id!,
          currentClient?.productModule,
          "",
          "",
          "",
          "",
          SearchLedgerByGroupsTypes.FixedAssets
        )
          .then((res: any) => {
            if (res?.items?.length > 0) {
              setFixedAssetsGroups(res?.items);
              setGroupDropdownloading(false);
            } else {
              setGroupDropdownloading(false);
            }
          })
          .catch((err: any) => {
            console.error("error......", err?.message);
            setGroupDropdownloading(false);
          });
      }
    };
    getGroupFixedAssets(currentClient?.id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]); //, searchSupplier.search]);

  const GetGroupList = (GrpList: any[]) => {
    return GrpList?.map((itm: any) => {
      const list: any = {
        key: itm?.id,
        value: itm?.id,
        title: itm?.group_Name,
      };
      if (itm?.children?.length > 0) {
        list.children = GetGroupList([...itm?.children]);
      }
      return list;
    });
  };

  const AssetsGroupOptions = GetGroupList(fixedAssetsGroups);

  function searchCategory(CategoryData: any, searchId: string): any {
    for (const child of CategoryData) {
      if (child.Id === searchId) {
        return child;
      }
      if (child.Children.length > 0) {
        const result = searchCategory(child.Children, searchId);
        if (result) {
          return result;
        }
      }
    }
  }

  const SetDepRatenLife = (SelCategory: string, SelDepMethod: CalcMethod) => {
    if (SelCategory !== "") {
      const depFilterReco = searchCategory(dep, SelCategory);
      form.setFieldValue("lifeCycleDuration", depFilterReco?.Life);
      if (SelDepMethod === CalcMethod.WrittenDownValue) {
        form.setFieldValue(
          "depRate",
          Math.round(depFilterReco?.WDVRate * 100) / 100
        );
      } else {
        form.setFieldValue(
          "depRate",
          Math.round((100 / depFilterReco?.Life) * 100) / 100
        );
      }
    }
  };

  const handleSLMDepRate = (val: any) => {
    if (depMethod === CalcMethod.StraightLine) {
      form.setFieldValue("depRate", Math.round((100 / val) * 100) / 100);
    } else {
      return;
    }
  };

  const onAssetDepMethodChange = (selValue: CalcMethod) => {
    setDepMethod(selValue);
    SetDepRatenLife(categoryValue, selValue);
  };

  function onAssetCategoryChange(selValue: string) {
    setCategoryValue(selValue);
    SetDepRatenLife(selValue, depMethod);
  }

  const GetCategoryList = (DepList: any[]) => {
    return DepList?.map((itm: any) => {
      const list: any = {
        key: itm.Id,
        value: itm.Id,
        title: itm.Title,
      };
      if (itm.Children.length > 0) {
        list.children = GetCategoryList([...itm.Children]);
      }
      return list;
    });
  };
  const AssetCategoryOptions = GetCategoryList(dep);

  const getVoucherTitle = companySettings?.setting?.vouchersSettings?.find(
    (x: any) => x?.title === "ASSET"
  );

  useEffect(() => {
    if (!editAssetId) {
      setLoading(true);
      FixedAssetsServiceNew.getAssetMaxNo(
        currentClient?.id!,
        voucherDate,
        getVoucherTitle?.title
      )
        .then((res) => {
          form.setFieldValue("number", res?.result);
          form.setFieldValue("date", dayjs());
        })
        .catch((err: any) => {
          openNotification("error", err.message);
          console.error(err.message);
        });
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  const onSubmit = () => {
    form
      .validateFields()
      .then((values: any) => {
        let formValues = { ...values };
        let formData = new FormData();
        appendObjectToFormData(formData, formValues);
        appendObjectToFormData(formData, { invoiceDetail: invoiceDetaildata });

        if (invoiceDetaildata?.length > 0) {
          setSaveButtonLoading(true);
          const asset = {
            ...values,
            // date: values.date,
            isDepreciable: isDepreciable,
            invoiceDetail: invoiceDetaildata,
            deleteUploadId: deleteUploadId,
          };
          console.log("Post Data: ", asset);
          FixedAssetsServiceNew.PostFixedAssets(
            currentClient?.id!,
            asset,
            editAssetId
          )
            .then((res: any) => {
              if (res.result) {
                openNotification(
                  "success",
                  `Asset ${
                    editAssetId ? "Edit successfully" : "Add successfully"
                  }`
                );
                // setIsModalOpen(false);
                // setTrigger((x) => !x);
                form.resetFields();
                setSaveButtonLoading(false);
                navigate(
                  `/accounts/clients/${currentClient?.id}/asset/fixedAsset`
                );
              } else setSaveButtonLoading(false);
            })
            .catch((error) => {
              openNotification("error", error);
              setSaveButtonLoading(false);
            });
        } else {
          openNotification("error", `Please Add Purchase Detail`);
        }
      })

      .catch(({ errorFields, err }) => {
        form.scrollToField(errorFields[0].name[0]);
        setSaveButtonLoading(false);
      });
  };

  // useKeyboardShortcuts("s", ["altKey"], () => onSubmit());
  EntryLevelKeys({ save: onSubmit });
  const [addLedgerResponse, setAddLedgerResponse] = useState<any>(null);

  useEffect(() => {
    if (addLedgerResponse) {
      const exists = payLedgers.some(
        (option: any) => option?.id === addLedgerResponse.id
      );
      if (exists) {
        form.setFieldValue("purPaymentLedger", addLedgerResponse?.id);
        setAddLedgerResponse(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payLedgers]);

  React.useEffect(() => {
    setLoading(true);
    const getAssestDetails = async () => {
      let res = await FixedAssetsServiceNew?.getAssetDetails(
        currentClient?.id,
        location?.state?.data?.id
      );
      if (res.status) {
        setInvoiceDetail(res?.result?.invoiceDetail);
        setDepMethod(res?.result?.depMethod);
        setDepreciable(res?.result?.isDepreciable);
        setSingleAssetType(res?.result?.assetType === 0 ? true : false);
        form?.setFieldsValue({
          ...res?.result,
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    getAssestDetails();
  }, []);

  const columns: ColumnsType<InvoiceDetail> = [
    {
      title: "Sr.No.",
      dataIndex: "key",
      key: "key",
      render: (val: string, _, index: number) => index + 1,
    },
    {
      title: "Purchase Date",
      dataIndex: "date",
      key: "date",
      render: (val: any) =>
        val !== undefined && dayjs(val).format("DD/MM/YYYY"),
    },
    {
      title: "Voucher Date",
      dataIndex: "voucherDate",
      key: "voucherDate",
      render: (val: any) =>
        val !== undefined && dayjs(val).unix() > 1
          ? dayjs(val).format("DD/MM/YYYY")
          : undefined,
    },
    {
      title: "Invoice Number",
      dataIndex: "invNumber",
      key: "invNumber",
      align: "center",
      render: (val: string) => val,
    },
    {
      title: "Total Amount",
      dataIndex: "price",
      key: "price",
      align: "right",
      render: (val: string) => Utils.getFormattedNumber(val),
    },
    {
      title: "Quantity",
      dataIndex: "qty",
      key: "qty",
      align: "center",
      render: (val: string) => val,
    },
    {
      title: "Payment Date",
      dataIndex: "purPayDate",
      key: "purPayDate",
      render: (val: string) =>
        val !== undefined && dayjs(val).unix() > 1
          ? dayjs(val).format("DD/MM/YYYY")
          : undefined,
    },
    {
      title: "Payment Method",
      dataIndex: "purPayMethod",
      key: "purPayMethod",
      align: "center",
      render: (val: any) =>
        val === PmtMethod.bank
          ? "Bank"
          : val === PmtMethod.cash
          ? "Cash"
          : val === PmtMethod.partialCredit
          ? "Partial Payment"
          : "Credit",
    },
    {
      title: "Action",
      dataIndex: "key",
      render: (val: any) => (
        <>
          <Button
            onClick={() => {
              setOpenModel({
                type: "AssestInvoiceModal",
                param: {
                  type: "edit",
                  selKey: val,
                },
              });
              // setPurchaseDetailCount(purchaseDetailCount + 1);
              // setSelIndex(undefined);
            }}
            icon={<EditOutlined />}
            size="small"
          />
          <Button
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setInvoiceDetail((prevValues) => {
                let newValues = prevValues.filter((itm) => itm.key !== val);
                return newValues;
              });
              // setSelIndex(undefined);
              // setPurchaseDetailCount(purchaseDetailCount - 1);
            }}
            icon={<DeleteOutlined style={{ color: "red" }} />}
            size="small"
          />
        </>
      ),
    },
  ];

  console.log("Invoice Detail Data: ", invoiceDetaildata);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Card
        size="small"
        className="TableCard"
        style={{
          overflowY: "scroll",
          height: "100%",
          boxShadow: "0px 0px 10px 0px #96A1C84D",
        }}
        title={
          <div style={{ padding: "10px" }}>
            <h3>Add Asset</h3>
          </div>
        }
        extra={
          <Button
            size="middle"
            onClick={onSubmit}
            type="primary"
            loading={saveButtonLoading}
          >
            Save Asset
          </Button>
        }
      >
        <Row>
          <Col span={24}>
            <Spin indicator={antIcon} spinning={loading}>
              <Form
                form={form}
                name="basic"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                //style={{ maxWidth: 1000 }}
                autoComplete="off"
                requiredMark={false}
                layout="vertical"
                size="middle"
                //onFinishFailed={}
                // initialValues={{
                //   invoiceDetail: [{}],
                // }}
                initialValues={{
                  assetType: 0,
                  // group: AssetsGroupOptions[0]?.value,
                }}
                // initialValues={editAsset?.id ? {} : initialValue}
              >
                <div className="inputboxmb">
                  <Card size="small">
                    <Row gutter={6}>
                      <Col lg={10} md={10}>
                        <Form.Item
                          name="name"
                          label="Asset Name"
                          rules={[
                            {
                              required: true,
                              message: "Name required",
                              pattern: /^[ A-Za-z0-9_/(/)-]*$/,
                            },
                          ]}
                          className="inputboxmb"
                        >
                          <Input autoFocus />
                        </Form.Item>
                      </Col>
                      <Col lg={4} md={4}>
                        <Form.Item
                          name="number"
                          label="Asset Reference"
                          className="inputboxmb"
                        >
                          <Input type="string" disabled />
                        </Form.Item>
                      </Col>

                      <Col lg={6} md={6}>
                        <Form.Item
                          name="assetType"
                          label="Asset Type"
                          className="inputboxmb"
                          rules={[
                            {
                              required: true,
                              message: "Type required",
                            },
                          ]}
                        >
                          <Select
                            onChange={(val) => {
                              val === 0
                                ? setSingleAssetType(true)
                                : setSingleAssetType(false);
                            }}
                            placeholder="Select asset type"
                            options={[
                              { value: 0, label: "Single" },
                              { value: 1, label: "Collective" },
                            ]}
                          />
                        </Form.Item>
                      </Col>

                      <Col lg={4} md={4}>
                        <Form.Item
                          name="isDepreciable"
                          valuePropName="checked"
                          style={{ display: "flex", flexDirection: "row" }}
                          labelCol={{ lg: 24 }}
                          wrapperCol={{ lg: 24 }}
                          className="inputboxmb"
                        >
                          <span>
                            Depreciable Asset
                            <br />
                            <Switch
                              style={{ margin: "10px 4px 0px" }}
                              checked={isDepreciable}
                              onChange={(isChecked) => {
                                setDepreciable(isChecked);
                                //console.log(isChecked, isDepreciable);
                              }}
                            />
                          </span>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={6}>
                      <Col lg={6} md={6}>
                        <Form.Item
                          name="group"
                          label="Asset Group"
                          rules={[
                            { required: true, message: "Please select Group." },
                          ]}
                          className="inputboxmb"
                        >
                          <TreeSelect
                            //className="inputboxsize"
                            treeNodeFilterProp="title"
                            showSearch
                            placeholder="Please select Asset Group"
                            allowClear
                            // value={groupValue}
                            //treeDefaultExpandAll
                            loading={groupDropdownloading}
                            style={{ marginBottom: "3px" }}
                            treeData={AssetsGroupOptions}
                            // onChange={onAssetGroupChange}
                            dropdownRender={(groupOptions) => (
                              <>
                                {groupOptions}
                                <Divider style={{ margin: "5px 0px" }} />
                                <Button
                                  style={{ width: "100%" }}
                                  icon={<PlusOutlined />}
                                  type="link"
                                  onClick={() =>
                                    setOpenModel({
                                      type: "addGroup",
                                      param: "",
                                    })
                                  }
                                >
                                  New Group
                                </Button>
                              </>
                            )}
                            dropdownStyle={{
                              maxHeight: 400,
                              overflow: "auto",
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={18} md={18}>
                        <Form.Item
                          name="description"
                          label="Description"
                          className="inputboxmb"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Form.Item
                        name="attachments"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => {
                          return e.fileList;
                        }}
                        // className="m-0"
                        style={{ paddingTop: "10px", margin: "0px" }}
                      >
                        <Upload
                          multiple
                          // accept={".png, .jpg, .jpeg, .pdf"}
                          // accept={".png, .jpg, .jpeg"}
                          beforeUpload={() => {
                            return false;
                          }}
                          onRemove={(e: any) => {
                            if (editAssetId && e?.id) {
                              setDeleteUploadId([...deleteUploadId, e?.id]);
                            }
                          }}
                          onPreview={(e: any) => {
                            // console.log("preview", e);
                            if (editAssetId && e?.path) {
                              setPreviewImage({
                                isBaseConvert: false,
                                path: e,
                              });
                            } else if (e.type?.split("/")[0] === "image") {
                              setPreviewImage({
                                isBaseConvert: true,
                                path: e,
                              });
                            }
                          }}
                        >
                          <Button
                            icon={<UploadOutlined />}
                            style={{ backgroundColor: "ButtonFace" }}
                          >
                            Upload
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Row>
                  </Card>
                  {isDepreciable && (
                    <>
                      <Card
                        title="Depreciation Detail"
                        size="small"
                        style={{ marginTop: "10px" }}
                      >
                        <Row gutter={6}>
                          <Col lg={16} md={16}>
                            <Form.Item
                              name="category"
                              label="Asset Category"
                              rules={[
                                {
                                  required: true,
                                  message: "Asset category is mandatory.",
                                },
                              ]}
                              className="inputboxmb"
                            >
                              <TreeSelect
                                //className="inputboxsize"
                                treeNodeFilterProp="title"
                                showSearch
                                placeholder="Please select Asset Category"
                                allowClear
                                value={categoryValue}
                                treeDefaultExpandAll
                                style={{ marginBottom: "3px" }}
                                treeData={AssetCategoryOptions}
                                onChange={(cat: string) =>
                                  onAssetCategoryChange(cat)
                                }
                                dropdownStyle={{
                                  maxHeight: 400,
                                  overflow: "auto",
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={6} md={6}>
                            <Form.Item
                              name="depMethod"
                              label="Depreciation Method"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select depreciation method.",
                                },
                              ]}
                              className="inputboxmb"
                            >
                              <Select
                                //className="inputboxsize"
                                style={{ marginBottom: "3px" }}
                                onChange={(val) => onAssetDepMethodChange(val)}
                                placeholder="Please select depreciation method"
                                //defaultValue={CalcMethod.WrittenDownValue}
                                options={[
                                  {
                                    value: CalcMethod.StraightLine,
                                    label: "Straight Line Method",
                                  },
                                  {
                                    value: CalcMethod.WrittenDownValue,
                                    label: "Written Down Method",
                                  },
                                ]}
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={4} md={4}>
                            <Form.Item
                              name="lifeCycleDuration"
                              label="Asset Life in year"
                              className="inputboxmb"
                            >
                              <Input
                                onBlur={(val: any) =>
                                  handleSLMDepRate(val?.target?.value)
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col lg={4} md={4}>
                            <Form.Item
                              name="depRate"
                              label="Depreciation Rate"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input depreciation rate!",
                                },
                              ]}
                              className="inputboxmb"
                            >
                              <Input type="number" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    </>
                  )}

                  <Card
                    title="Purchase Detail"
                    size="small"
                    style={{ marginTop: "10px" }}
                  >
                    <Row>
                      {((invoiceDetaildata.length < 1 && singleAssetType) ||
                        !singleAssetType) && (
                        <>
                          {" "}
                          <Col span={24}>
                            <Button
                              type="link"
                              onClick={() => {
                                setOpenModel({
                                  type: "AssestInvoiceModal",
                                  param: {
                                    type: "add",
                                    selKey:
                                      invoiceDetaildata.length > 0
                                        ? invoiceDetaildata?.length
                                        : 0,
                                  },
                                });
                                // setPurchaseDetailCount(purchaseDetailCount + 1);
                                // setSelIndex(undefined);
                              }}
                              // onClick={() => {
                              //   setPurchaseDetailCount(purchaseDetailCount + 1);
                              //   setSelIndex(undefined);
                              //   setOpenDrawer(true);
                              // }}
                              style={{ marginBottom: "10px" }}
                            >
                              <PlusCircleOutlined /> Add Purchase Detail
                            </Button>
                          </Col>
                        </>
                      )}
                      <Col span={24}>
                        <Table
                          columns={columns}
                          dataSource={invoiceDetaildata}
                        ></Table>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Form>
            </Spin>
          </Col>
        </Row>
      </Card>

      {openModel.type === "AssestInvoiceModal" && (
        <AssetInvoiceDetail
          open={true}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          singleAssetType={singleAssetType}
          isDepreciable={isDepreciable}
          depMethod={depMethod}
          refNumber={form.getFieldValue("number")}
          setInvoiceDetail={setInvoiceDetail}
          selKey={openModel?.param?.selKey}
          invoiceDetaildata={
            openModel?.param?.type === "add"
              ? invoiceDetaildata?.length !== 0
                ? {
                    key: invoiceDetaildata?.length,
                    lifeCycleDuration: form.getFieldValue("lifeCycleDuration"),
                  }
                : singleAssetType
                ? {
                    key: 0,
                    lifeCycleDuration: form.getFieldValue("lifeCycleDuration"),
                    childAssetDetail: [
                      {
                        childAssetName: form.getFieldValue("name"),
                      },
                    ],
                  }
                : {
                    key: 0,
                    lifeCycleDuration: form.getFieldValue("lifeCycleDuration"),
                  }
              : invoiceDetaildata[openModel?.param?.selKey]
          }
        />
      )}
      {/* {previewImage.path && (
        <DocumentPreview
          open={true}
          onCancel={() =>
            setPreviewImage({
              path: "",
              isBaseConvert: false,
            })
          }
          documentDetails={previewImage}
        />
      )} */}

      {/* {openModel.type === "addGroup" && (
        <AddGroup
          open={true}
          editData={openModel?.param}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() => setGroupListTrigger(!groupListTrigger)}
          groupData={groupData}
        />
      )}
      {openModel.type === "addLedger" && (
        <AddLedger
          openDrawer={true}
          id={""}
          setOpenDrawer={() => setOpenModel({ type: "", param: "" })}
          setTrigger={() =>
            onPaymentModeChange(form.getFieldValue("payMethod"))
          }
          setAddLedgerResponse={setAddLedgerResponse}
        />
      )} */}
    </div>
  );
};

export default AssetModel;
