import { Route, Routes } from "react-router-dom";
import ResourceNotFound from "../../Common/ResourceNotFound";
import SettingsCompanyInfo from "./SettingsCompanyInfo";
import SettingPaymentTerm from "./SettingPaymentTerm";
import SettingsGst from "./SettingsGst";
import SettingsReport from "./SettingsReport";
import SettingOpeningBalance from "./SettingOpeningBalance";
import SettingsEmail from "./SettingsEmail";
import SettingsTemplates from "./SettingsTemplates";
import SettingsAdvance from "./SettingsAdvance";
import { Card } from "antd";
import SettingSecCustomization from "./SettingSecCustomization";
import SettingDefaultLedger from "./SettingDefaultLedger";
import TermsAndConditions from "./TermsAndConditions";
import TdsList from "../TcsTds/Tds/TdsList";
// import SettingsNewDashboard from "./SettingsNewDashboard";

const SettingNestedRoute = () => {
  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        marginTop: "6px",
        padding: "15px 0px 0px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <Routes>
        <Route path="/companyInfo" element={<SettingsCompanyInfo />} />
        <Route path="/customization" element={<SettingSecCustomization />} />
        <Route path="/paymentTerm" element={<SettingPaymentTerm />} />
        <Route path="/gst" element={<SettingsGst />} />
        <Route path="/report" element={<SettingsReport />} />
        <Route path="/openingBalance" element={<SettingOpeningBalance />} />
        <Route path="/email" element={<SettingsEmail />} />
        <Route path="/templates" element={<SettingsTemplates />} />
        <Route path="/advSetting" element={<SettingsAdvance />} />
        <Route path="/termsandConditions" element={<TermsAndConditions />} />
        <Route path="/tds" element={<TdsList Role="ADMIN" />} />

        <Route path="/defaultLedger" element={<SettingDefaultLedger />} />
        {/* <Route path="/newDashboard" element={<SettingsNewDashboard />} /> */}
        <Route path="*" element={<ResourceNotFound pageHeight="100%" />} />
      </Routes>
    </Card>
  );
};

export default SettingNestedRoute;
