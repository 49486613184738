import React, { useEffect, useState } from "react";
import PasswordImg from "../../images/password.svg";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Typography,
  message,
} from "antd";
import "./Login.css";
import { Link, useNavigate } from "react-router-dom";
import { AuthService } from "../../Services/AuthService";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { useUser } from "../../Context/UserContext";
import SendEmail from "./SendEmail";
import CapsitechAccount from "../../CapsitechAccountsComponents/Images/CapsitechAccounts.svg";
import LoginNew from "../../CapsitechAccountsComponents/Images/LoginNew.svg";
import ClientServiceNew from "../../../src/CapsitechAccountsComponents/Services/ClientService";
import { IUserProfile } from "../../Types";
import { useDispatch } from "react-redux";
import { setLoginUserData } from "../../CapsitechAccountsComponents/Redex/UserSlice";
import { setIndianState } from "../../CapsitechAccountsComponents/Redex/IndianStateSlice";

const Login = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 14, color: "white" }} spin />
  );
  const { setCurrentUser } = useUser();
  const { setIsLoggedIn } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [isForgotpass, setIsforgotpass] = useState(false);
  const [userEmail, setUserEmail] = React.useState("");
  const [userData, setUserData] = React.useState<IUserProfile>();

  const UserLogin = () => {
    // setLoading(true);
    form
      .validateFields()
      .then(async (val) => {
        setLoading(true);
        await AuthService.login(val.username, val.password)
          .then((user: any) => {
            console.log("User Data: ", user);

            if (user?.token != null) {
              //update usercontext value before switching to dashboard
              // setCurrentUser(user);
              setUserData(user);
              setIsLoggedIn(true);
              dispatch(setLoginUserData(user));

              sessionStorage.setItem("state", JSON.stringify(true));
              localStorage.setItem(
                "ci-config",
                JSON.stringify({
                  darkMode: false,
                  themeColor: "#3a5dd3",
                  fontSize: 14,
                  borderRadius: 6,
                  componentSize: "middle",
                  tablePageSize: 15,
                  silderSize: true,
                })
              );
            }
            setLoading(false);
          })
          .catch((ex) => {
            console.error("Login error", ex);
            setLoading(false);
          });
      })
      .catch((ex) => {
        console.error("Login error", ex);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (userData) {
      const getState = async () => {
        await ClientServiceNew.getStateOption(true)
          .then((res: any) => {
            if (res.result) {
              const result = res?.result;
              result.shift();

              localStorage.setItem("IndianState", JSON.stringify(result));
              setCurrentUser(userData, result);
              navigate("/accounts");
              // dispatch(setIndianState(result));

              // return result;
            }
            // setStatesOption(res?.result);
          })
          .catch((ex) => {
            console.error("Login error", ex);
            setLoading(false);
          });
      };
      getState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const [cardWidth, setcardWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setcardWidth(window.innerWidth);
  });
  if (JSON.parse(localStorage.getItem("LockPeriod")!)) {
    localStorage.removeItem("LockPeriod");
  }
  const [messageApi, contextHolder] = message.useMessage();
  const info = (msg: any) => {
    messageApi.success(msg);
  };
  useEffect(() => {
    if (!localStorage.getItem("projecttype")) {
      localStorage.setItem("projecttype", "accounts");
    }
  }, []);

  const [ForgotPasswordForm] = Form.useForm();

  const error = (mes: any) => {
    messageApi.error(mes);
  };
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const enterLoading = (index: number) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 1000);
  };

  const postMail = () => {
    setLoading(true);
    enterLoading(1);
    //  /auth/forgotpassword
    ForgotPasswordForm.validateFields().then((val: any) => {
      console.log(val);
      setLoading(false);

      AuthService.forgotpassword(val.email).then((res: any) => {
        console.log("res ================================== ", res);
        if (res) {
          info(
            "We have sent you an email to reset your password,Please check your inbox"
          );
          // console.log(val, "asdkfasdflsdlf;jsdlfjsdlfjlsdkjkl")
          console.log(res);
          ForgotPasswordForm.resetFields();
        } else {
          error("Something went to wrong");
          ForgotPasswordForm.resetFields();
        }
      });
    });
  };

  return (
    <Row className="h100vh">
      <Col lg={12} className="loginImg">
        {/* <img
            style={{ height: "87vh" }}
            src={process.env.PUBLIC_URL + "/landing_banner.svg"}
            alt=""  
          /> */}
        {/* <Loginimage /> */}
        <Row className="loginImg">
          <Col lg={24} style={{ lineHeight: 3 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div>
                <img alt="CapsitechAccount" src={CapsitechAccount} />
              </div>
            </div>
            <div>
              <Typography.Title
                level={5}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  color: "#4D4D4D",
                  lineHeight: 4,
                }}
              >
                One step from to having an entire Accounting solutions
              </Typography.Title>
            </div>
            <img alt="LoginNew" src={LoginNew} width={600} />
          </Col>
        </Row>
      </Col>
      <Col xs={24} sm={24} md={24} lg={12} xl={12} className="loginBoxSection">
        <Row className="loginBox">
          <Col lg={24}>
            <div className="loginInnerBox">
              <img
                alt="CapsitechAccount"
                src={CapsitechAccount}
                width={150}
                height={100}
              />
              <p className="secondloginheading" style={{ color: "#4d4d4d" }}>
                Log in to your account
              </p>
              <p className="thirdloginheading">
                {isForgotpass !== true ? (
                  // <> Enter your credentials to sign in.</>
                  <></>
                ) : (
                  <> Send mail for new password and sign in.</>
                )}
              </p>

              <div className="loginBoxContent">
                {contextHolder}
                {isForgotpass !== true ? (
                  <Form
                    form={form}
                    name="login"
                    initialValues={{ remember: true }}
                    className="login-form"
                  >
                    <Row>
                      <Col span={24} className="loginnames">
                        Username
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your username!",
                            },
                            { type: "email", message: "Invalid Email" },
                          ]}
                        >
                          <Input
                            suffix={
                              <UserOutlined
                                style={{ color: "rgb(0,0,0,0.45)" }}
                              />
                            }
                            // type="email"
                            className="loginInput"
                            onChange={(e: any) => {
                              // console.log(userEmail, "valueeveet");
                              setUserEmail(e.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24} className="loginnames">
                        Password
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please enter your password!",
                            },
                          ]}
                        >
                          <Input.Password
                            suffix={<img src={PasswordImg} alt="password" />}
                            type="password"
                            className="loginInput"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row style={{ margin: "4px 0px 40px 0px" }}>
                      <Col span={12}>
                        {/* <Form.Item
                        name="remember"
                        valuePropName="checked"
                        noStyle
                      >
                        <Checkbox className="lgname">Remember me</Checkbox>
                      </Form.Item> */}
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        {/* <Button
                        className="lgname"
                        size="small"
                       
                      > */}
                        <Link onClick={() => setIsforgotpass(true)} to={""}>
                          {" "}
                          Forgot password ?
                        </Link>
                        {/* </Button> */}
                      </Col>
                    </Row>

                    <Form.Item>
                      <Row justify="center">
                        <Col lg={24}>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="check"
                            onClick={() => UserLogin()}
                            style={{
                              backgroundColor: "#dd2a1b",
                              color: "white",
                              width: "100%",
                            }}
                          >
                            {loading ? <Spin indicator={antIcon} /> : "LOG IN"}
                          </Button>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Form>
                ) : (
                  <>
                    {/* <SendEmail
                    postMail={postMail}
                    ForgotPasswordForm={ForgotPasswordForm}
                    contextHolder={contextHolder}
                    loadings={loadings}
                    loading={loading}
                  /> */}
                    <Form
                      requiredMark={false}
                      form={ForgotPasswordForm}
                      name="ForgotPassword"
                      layout="vertical"
                      initialValues={{ email: userEmail }}
                    >
                      <Row style={{ marginTop: "40px" }}>
                        <Col lg={24}>
                          <Form.Item
                            name="email"
                            label="Enter Your Email"
                            style={{ marginBottom: "40px" }}
                            rules={[
                              { required: true, message: "Required" },
                              { type: "email", message: "Invalid Email" },
                            ]}
                          >
                            <Input
                              // style={{ height: "6vh", fontSize: "18px" }}
                              placeholder="example@gmail.com"
                            />
                          </Form.Item>
                          <Button
                            type="primary"
                            loading={loadings[1]}
                            onClick={postMail}
                            htmlType="submit"
                          >
                            {" "}
                            Send Mail
                          </Button>
                          <Row justify={"end"}>
                            <Col>
                              {" "}
                              <Link
                                to={""}
                                onClick={() => setIsforgotpass(!isForgotpass)}
                              >
                                {" "}
                                Back to Login
                              </Link>{" "}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default Login;
