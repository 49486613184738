import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Table,
  Row,
  Col,
  Input,
  DatePicker,
  Select,
  Space,
  Card,
  Skeleton,
  Tag,
  Tooltip,
  Popover,
} from "antd";
import {
  PlusOutlined,
  DownloadOutlined,
  EditOutlined,
  DeleteTwoTone,
  SyncOutlined,
} from "@ant-design/icons";
import search from "../../Images/search.svg";
import { useLocation, useNavigate } from "react-router-dom";

import type { ColumnsType } from "antd/es/table";
import ViewCreditNote from "./ViewCreditNote";
import ViewSalesInvoice from "./ViewSalesInvoice";
import { dayjs } from "../../../Utilities/dayjs";
import { Utils } from "../../../Utilities/Utils";
import { useTheme } from "../../Context/ThemeContext";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";
import { ConfirmationModal } from "../../Common/ConfirmationModal";
import { FiDownload } from "react-icons/fi";
import VoucherServices, {
  VoucherFilterStatus,
  VoucherStatus_New,
  Voucher_Type,
} from "../../Services/VoucherServices";
import jsPDF from "jspdf";
import "jspdf-autotable";
import ExcelJS from "exceljs";
import InvoiceExportService from "../../Services/InvoiceExportDetails";
import DraftService from "../../Services/DraftService";
import DownloadService, { ViewType } from "../../Services/DownloadService";
import ViewPreviewModel from "./ViewPreviewModel";

interface DataType {
  key: React.Key;
  id: string;
  invNumber: { id: string; name: string };
  invRefNumber: { id: string; name: string };
  customer: string;
  date: string;
  amount: string;
  invAmount: any;
  voucherStatus: VoucherStatus_New;
}
const CreditNotes: React.FC<{ partyId?: string }> = (props) => {
  const { themeData } = useTheme();
  const { RangePicker } = DatePicker;
  const { currentClient, companySettings } = useClient();
  const location = useLocation();
  const { openNotification } = useContext(notificationContext);
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setIsOpen] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [totalRecords, setTotalRecords] = useState<number>();
  const [totalAmount, setTotalAmount] = useState<number>();
  const [creditNoteList, setCreditNoteList] = useState<DataType[]>([]);
  const [exportCreditNoteData, setExportCreditNoteData] = useState<DataType[]>(
    []
  );
  const [open1, setIsOpen1] = React.useState<string>("");
  const [openPopArray, setOpenPopArray] = useState<boolean[]>([]);
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [creditNoteId, setCreditNoteId] = React.useState<string>("");
  const [searchCustomer, setSearchCustomer] = React.useState<string>("");
  const [salesInvoiceId, setSalesInvoiceId] = React.useState<string>("");
  const [refreshAtDelete, setRefreshAtDelete] = React.useState<boolean>(false);
  const [filterDate, setFilterDate] = React.useState<any>(
    JSON.parse(localStorage.getItem("LockPeriod")!)
      ? {
          from: JSON.parse(localStorage.getItem("LockPeriod")!).from,
          to: JSON.parse(localStorage.getItem("LockPeriod")!).to,
        }
      : {
          from: dayjs(companySettings?.period?.from).format("DD/MM/YYYY"),
          to: dayjs(companySettings?.period?.to).format("DD/MM/YYYY"),
        }
  );
  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [exportType, setExportType] = useState<number>(-1);
  const [invoiceStatus, setInvoiceStatus] = React.useState<VoucherFilterStatus>(
    VoucherFilterStatus.Active
  );

  const [openSalesInvoice, setOpenSalesInvoice] =
    React.useState<boolean>(false);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [deleteCreditNote, setDeleteCreditNote] = React.useState<{
    id: string;
    isRev: boolean;
    number: string;
  }>({
    id: "",
    isRev: false,
    number: "",
  });

  let splitPathInVchType: Voucher_Type;
  // let splitPathLedgerByGroupsType: SearchLedgerByGroupsTypes;
  if (location.pathname.split("/")[4] === "sales") {
    splitPathInVchType = Voucher_Type.CreditNote;
    // splitPathLedgerByGroupsType = SearchLedgerByGroupsTypes.Sales;
  } else {
    splitPathInVchType = Voucher_Type.DebitNote;
    // splitPathLedgerByGroupsType = SearchLedgerByGroupsTypes.Purchase;
  }
  console.log("splitPathInVchType=>>", splitPathInVchType);

  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  // Get the selected row data
  const selectedRows = creditNoteList?.filter((item) =>
    selectedRowKeys.includes(item.key)
  );

  // console.log("Selected Row Keys:", selectedRowKeys);
  // console.log("Selected Row Data:", selectedRows);
  const [loadingButtons, setLoadingButtons] = useState<any>({});
  const downloadAndView = async (
    cliendId: string,
    voucherId: string,
    type: ViewType
    // invId: any
  ) => {
    if (cliendId && voucherId) {
      setLoadingButtons((prev: any) => ({ ...prev, [voucherId]: true }));
      await DownloadService.downloadPdf(
        cliendId,
        voucherId,
        type,
        splitPathInVchType
      )
        .then((res) => {
          console.log("res", res);
          if (res) {
            console.log("download sucess");
            setLoadingButtons((prev: any) => ({ ...prev, [voucherId]: false }));
          }
        })
        .catch((ex) => {
          openNotification("error", ex.message);
        });
    } else {
      openNotification("error", "Invalid Details");
      setLoadingButtons(false);
    }
  };
  const columns: ColumnsType<DataType> = [
    {
      title:
        splitPathInVchType === Voucher_Type.CreditNote
          ? "Credit Note No."
          : "Debit Note No.",
      dataIndex: "number",
      sorter: true,
      key: "number",
      width: "15%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.voucherStatus !== 1 ? (
            <>
              <span
                style={{ color: "#1677FF", cursor: "pointer" }}
                onClick={() => {
                  // setIsOpen(true);
                  setIsOpen1("viewPreview");
                  setCreditNoteId(record?.invNumber?.id);
                }}
              >
                {record?.invNumber?.name}
              </span>
            </>
          ) : (
            <>
              <span style={{ color: "red" }}>{record?.invNumber?.name}</span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Party Name",
      dataIndex: "customer",
      key: "pLedger",
      sorter: true,
      // width: "30%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.customerName?.name}
        </Skeleton>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      sorter: true,
      key: "date",
      width: "10%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.date}
        </Skeleton>
      ),
    },
    {
      title:
        splitPathInVchType === Voucher_Type.CreditNote
          ? "Invoice No."
          : "Bill No.",
      dataIndex: "invoiceNo",
      key: "PORef.Name",
      sorter: true,
      width: "15%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <span
            style={{ color: "#1677FF", cursor: "pointer" }}
            onClick={() => {
              setOpenSalesInvoice(true);
              setSalesInvoiceId(record?.invRefNumber?.id);
            }}
          >
            {record?.invRefNumber?.name}
          </span>
        </Skeleton>
      ),
    },
    {
      title: "Amount",
      dataIndex: "C",
      sorter: true,
      key: "invAmount",
      align: "right",
      width: "20%",
      render: (_, record: any) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{
            rows: 1,
            style: { display: "flex", justifyContent: "end" },
          }}
          title={false}
          style={{ display: "flex", justifyItems: "end" }}
        >
          {record?.voucherStatus !== 1 ? (
            <>₹ {Utils.getFormattedNumber(record?.invAmount)}</>
          ) : (
            <>
              <span style={{ textDecoration: "line-through" }}>
                ₹ {Utils.getFormattedNumber(record?.invAmount)}
              </span>
            </>
          )}
        </Skeleton>
      ),
    },
    {
      title: "Action",
      dataIndex: "",
      align: "center",
      width: "10%",
      render: (_, record, index) => (
        <Skeleton
          className="cursor"
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Row gutter={13}>
              <Col>
                {record?.voucherStatus !== 1 &&
                invoiceStatus !== VoucherFilterStatus?.Draft ? (
                  <>
                    {/* <FiDownload style={{ color: "#395cd2" }} /> */}
                    <Button
                      size="small"
                      icon={<FiDownload />}
                      style={{ border: "none", color: "#395cd2" }}
                      loading={!!loadingButtons[record.id]}
                      onClick={() =>
                        downloadAndView(
                          currentClient?.id!,
                          record?.id,
                          ViewType.Pdf
                          // record.invNumber?.id
                        )
                      }
                    />
                  </>
                ) : (
                  <></>
                )}
              </Col>

              <Col>
                {record.voucherStatus === VoucherStatus_New.Active ? (
                  <>
                    <span>
                      <Tooltip title="Edit">
                        {invoiceStatus === VoucherFilterStatus?.Draft ? (
                          <>
                            <EditOutlined
                              onClick={() => {
                                splitPathInVchType === Voucher_Type.CreditNote
                                  ? navigate(
                                      `/accounts/clients/${currentClient?.id}/sales/editCreditNoteDraft/${record?.id}`
                                    )
                                  : navigate(
                                      `/accounts/clients/${currentClient?.id}/purchase/editDebitNoteDraft/${record?.id}`
                                    );
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <EditOutlined
                              onClick={() => {
                                splitPathInVchType === Voucher_Type.CreditNote
                                  ? navigate(
                                      `/accounts/clients/${currentClient?.id}/sales/editCreditNote/${record?.id}`
                                    )
                                  : navigate(
                                      `/accounts/clients/${currentClient?.id}/purchase/editDebitNote/${record?.id}`
                                    );
                              }}
                            />
                          </>
                        )}
                      </Tooltip>
                    </span>
                    <span style={{ marginLeft: "10px" }}>
                      <Tooltip title="Delete" color="red">
                        <DeleteTwoTone
                          className="ca-delete-btn"
                          twoToneColor="#ff879d"
                          onClick={() => {
                            setConfirmation(true);
                            setDeleteCreditNote({
                              ...deleteCreditNote,
                              number: record?.invNumber?.name,
                            });
                            setCreditNoteId(record?.id);
                            handleOpenChange(index, false);
                          }}
                        />
                      </Tooltip>
                    </span>
                  </>
                ) : (
                  <Row>
                    <Col>
                      <Tooltip title="Revive Voucher">
                        <SyncOutlined
                          style={{
                            color: "green",
                            // textAlign: "center",
                            justifyContent: "end",
                          }}
                          // className="ca-delete-btn cursor"
                          onClick={() => {
                            splitPathInVchType === Voucher_Type.CreditNote
                              ? navigate(
                                  `/accounts/clients/${currentClient?.id}/sales/editCreditNote/${record?.id}`
                                )
                              : navigate(
                                  `/accounts/clients/${currentClient?.id}/purchase/editDebitNote/${record?.id}`
                                );
                          }}
                        />
                      </Tooltip>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </div>
        </Skeleton>
      ),
    },
  ];

  const handleOpenChange = (columnIndex: number, newOpen: boolean) => {
    const updatedOpenPopArray = [...openPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenPopArray(updatedOpenPopArray);
  };

  // const deleteDraftData = async () => {
  //   const deleteData =
  //   if (deleteData.result) {
  //     openNotification("success", "Delete Sucessfully");

  //     setRefreshAtDelete(!refreshAtDelete);
  //     setExportType(-1);
  //   } else {
  //     deleteCreditNote?.id;
  //   }
  // };

  React.useEffect(() => {
    console.log("useEffected Call");

    if (deleteCreditNote?.id) {
      const DeleteCreditNote = async () => {
        try {
          setLoading(true);
          const res =
            invoiceStatus === VoucherFilterStatus.Draft
              ? await DraftService.deleteDraft(
                  currentClient?.id!,
                  deleteCreditNote.id
                )
              : await VoucherServices.deleteVoucher(
                  currentClient?.id!,
                  deleteCreditNote.id
                );
          if (res) {
            setLoading(false);
            if (res?.result) {
              setRefreshAtDelete(!refreshAtDelete);
              openNotification(
                "success",
                `Entry deleted successfully ${deleteCreditNote.number}`
              );
            }
          } else {
            // console.log("error", ex);
            openNotification("error", res);
          }
        } catch (ex) {
          console.log("error", ex);
          openNotification("error", ex);
        }
      };

      DeleteCreditNote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteCreditNote?.id]);

  React.useEffect(() => {
    if (currentClient?.id && (exportType === -1 || exportType)) {
      getList(
        currentClient?.id,
        splitPathInVchType === Voucher_Type.CreditNote
          ? Voucher_Type.CreditNote
          : Voucher_Type.DebitNote,
        dayjs(filterDate.from, "DD/MM/YYYY").toISOString(),
        dayjs(filterDate.to, "DD/MM/YYYY").endOf("day").toISOString(),
        exportType >= 1 ? 0 : (page - 1) * pageSize,
        exportType >= 1 ? 0 : pageSize,
        filter.sortCol,
        filter.sortDir,
        undefined,
        undefined,
        searchCustomer,
        invoiceStatus
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentClient?.id,
    page,
    pageSize,
    filter.sortCol,
    filter.sortDir,
    filterDate.from,
    filterDate.to,
    searchCustomer,
    refreshAtDelete,
    invoiceStatus,
    exportType,
  ]);

  // ----- Table Ascending & Desending Order -----
  const getList = async (
    clientId: string,
    Voucher_Type: Voucher_Type,
    fromDate: string,
    toDate: string,
    start: number,
    length: number,
    sortCol?: string,
    sortDir?: string,
    noPartyId?: string,
    noReconciled?: string,
    search?: string,
    status?: VoucherFilterStatus
  ) => {
    console.log("export", exportType);

    try {
      if (exportType < 1) {
        setLoading(true);
      }
      const res =
        invoiceStatus === VoucherFilterStatus.Draft
          ? await DraftService.getVoucherDraftList(
              clientId,
              Voucher_Type,
              fromDate,
              toDate,
              start,
              length,
              sortCol,
              sortDir,
              noPartyId,
              search
            )
          : await VoucherServices.getVoucharList(
              clientId,
              Voucher_Type,
              fromDate,
              toDate,
              start,
              length,
              sortCol,
              sortDir,
              noPartyId,
              noReconciled,
              search,
              status
            );

      if (res?.result?.voucherItems?.length > 0) {
        // setTotAmount(res?.result?.totalAmount);
        let creditNoteData = res?.result?.voucherItems?.map(
          (v: any, index: number) => {
            return {
              key: index,
              id: v?.vId,
              date: dayjs(v?.date).format("DD/MM/YYYY"),
              invNumber: { name: v?.number, id: v?.vId },
              invRefNumber: v?.purchaseOrderRef,
              customerName: {
                id: v?.pLedger[0].id,
                name: v?.pLedger[0]?.ledgerName,
              },
              invAmount: v?.invAmount,
              dueBalance: v?.dueAmount,
              voucherStatus: v?.voucherStatus,
            };
          }
        );

        if (exportType >= 1) {
          setExportCreditNoteData(creditNoteData);
          if (exportType === 1) {
            exportCsv(creditNoteData);
          } else if (exportType === 2) {
            exportPDF(creditNoteData);
          } else if (exportType === 3) {
            exportExcel(creditNoteData);
          }
        } else {
          setCreditNoteList(creditNoteData);
          setTotalAmount(res?.result?.totalAmount);
        }
      } else {
        setCreditNoteList([]);
        // props.setLoadSkeleton(false);
        setTotalRecords(0);
        setTotalAmount(0);
      }
      setLoading(false);
    } catch (ex: any) {
      console.error(ex.message);
      setTotalAmount(0);
      setLoading(false);
      setCreditNoteList([]);
      setTotalRecords(0);
    }
  };

  // ----- Table Ascending & Desending Order -----
  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "date",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("filter", filter);
  };

  const getQuarterPreset: any = (startMonth: number) => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() + startMonth).startOf("month"),
      fromDate.month(fromDate.month() + startMonth + 2).endOf("month"),
    ];
  };
  const thisYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month()).startOf("month"),
      fromDate.month(fromDate.month() + 11).endOf("month"),
    ];
  };
  const lastYear = () => {
    const fromDate = dayjs(companySettings?.period?.from);
    return [
      fromDate.month(fromDate.month() - 12).startOf("month"),
      fromDate.month(fromDate.month() - 1).endOf("month"),
    ];
  };

  const getLast15Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(15, "day").startOf("day"); // Start 15 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };
  const getLast7Days = () => {
    const now = dayjs(); // Get the current date
    const startDate = now.subtract(7, "day").startOf("day"); // Start 7 days ago from today
    const endDate = now.endOf("day"); // End of the current day
    return [startDate, endDate];
  };

  const getThisMonth = () => {
    const now = dayjs();
    return [now.startOf("month"), now.endOf("month")];
  };
  const getLastMonth = () => {
    const now = dayjs();
    const lastMonth = now.subtract(1, "month");
    return [lastMonth.startOf("month"), lastMonth.endOf("month")];
  };

  // document.addEventListener("keydown", (e) => {
  //   if (e.altKey && e.key.toLowerCase() === "o") {
  //     e.preventDefault();
  //     navigate(`/accounts/clients/${currentClient?.id}/sales/addCreditNote`);
  //     //console.log("event", e);
  //   }
  // });
  //----- Short-Cut for CreditNotes -----

  let path =
    splitPathInVchType === Voucher_Type.CreditNote
      ? "Credit Note List"
      : "Debit Note List";
  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => {
      if (col === "Amount") {
        return `${
          selectedRowKeys.length > 0
            ? Utils.getFormattedNumber(
                selectedRows.reduce(
                  (acc, data) => acc + (data?.invAmount || 0),
                  0
                )
              )
            : Utils.getFormattedNumber(totalAmount)
        }`;
      } else if (col === "Credit Note No.") {
        return "Total";
      } else if (col === "Debit Note No.") {
        return "Total";
      } else {
        return "";
      }
    });
  };

  const columnsA = [
    splitPathInVchType === Voucher_Type.CreditNote
      ? "Credit Note No."
      : "Debit Note No.",
    "Party Name",
    "Date",
    splitPathInVchType === Voucher_Type.CreditNote ? "Invoice No." : "Bill No.",
    "Amount",
  ];

  const fieldMapping = {
    "Credit Note No.": (item: any) => item?.invNumber?.name,
    "Debit Note No.": (item: any) => item?.invNumber?.name,
    "Party Name": (item: any) => item?.customerName?.name,
    Date: (item: any) => item?.date,
    "Invoice No.": (item: any) => item?.invRefNumber?.name,
    "Bill No.": (item: any) => item?.invRefNumber?.name,
    Amount: (item: any) => Utils?.getFormattedNumber(item?.invAmount),
  };

  const exportCsv = (invExportData: DataType[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      filterDate,
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (invExportData: DataType[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const exportExcel = async (invExportData: DataType[]) => {
    const exportData =
      selectedRowKeys.length > 0 ? selectedRows : invExportData;
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      filterDate,
      path
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportCreditNoteData.length > 0) {
              exportCsv(exportCreditNoteData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportCreditNoteData.length > 0) {
              exportPDF(exportCreditNoteData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSX"
          onClick={() => {
            if (exportCreditNoteData.length > 0) {
              exportExcel(exportCreditNoteData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSX
        </Button>
      </div>
    </Space>
  );
  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        // width: "97%",
        // marginLeft: "16px",
        marginTop: 35,
        boxShadow: "0px 0px 10px 0px #96A1C84D",
        // border: "1px solid #eef2fe",
      }}
    >
      <Row
        justify="space-between"
        style={{
          // paddingTop: props?.partyId ? "10px" : "0px"
          // marginTop: 10,
          padding: "0px 0px 3px 0px",
        }}
      >
        <Col>
          <Space>
            {/* <Tooltip
              title={
                splitPathInVchType === Voucher_Type.CreditNote ? (
                  <>
                    Add Credit Note
                    <br />
                    <Tag>
                      <kbd>Shift + F6</kbd>
                    </Tag>
                  </>
                ) : (
                  <>
                    Add Debit Note
                    <br />
                    <Tag>
                      <kbd>Shift + F10</kbd>
                    </Tag>
                  </>
                )
              }
            > */}
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                props?.partyId
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/sales/addCreditNote/${props?.partyId}`
                    )
                  : splitPathInVchType === Voucher_Type.CreditNote
                  ? navigate(
                      `/accounts/clients/${currentClient?.id}/sales/addCreditNote`
                    )
                  : navigate(
                      `/accounts/clients/${currentClient?.id}/purchase/addDebitNote`
                    );
              }}
            >
              {splitPathInVchType === Voucher_Type.CreditNote
                ? "Credit Note"
                : "Debit Note"}
            </Button>
            {/* </Tooltip> */}

            {creditNoteList?.length === 0 ? (
              <>
                <Button type="default" icon={<DownloadOutlined />} disabled>
                  Export
                </Button>
              </>
            ) : (
              <>
                <Popover content={content} placement="rightTop" trigger="hover">
                  <Button
                    type="default"
                    icon={<DownloadOutlined />}
                    // onClick={() => exportCsv()}
                  >
                    Export
                  </Button>
                </Popover>
              </>
            )}
          </Space>
        </Col>

        <Col
          style={{
            marginTop: width <= 768 ? 15 : "",
          }}
        >
          <Row gutter={width <= 768 ? 60 : 8}>
            <Col>
              <Input
                style={{ width: "200px", border: "1px solid #f1f3ff" }}
                placeholder="Search Ledger"
                allowClear
                onChange={(e: any) => {
                  setExportCreditNoteData([]);
                  setExportType(-1);

                  setPage(1);
                  setSearchCustomer(e.target.value);
                }}
                suffix={
                  searchCustomer ? "" : <img alt="Search.." src={search} />
                }
              />
            </Col>
            <Col>
              <RangePicker
                defaultValue={[
                  dayjs(filterDate?.from, "DD/MM/YYYY"),
                  dayjs(filterDate?.to, "DD/MM/YYYY"),
                ]}
                format="DD/MM/YYYY"
                style={{
                  width:
                    width <= 464 ? "230px" : width === 375 ? "200px" : "250px",
                }}
                onChange={(e: any) => {
                  if (e === null) {
                    setFilterDate({});
                  } else {
                    setFilterDate({
                      ...filterDate,
                      from: dayjs(e[0]).format("DD/MM/YYYY"),
                      to: dayjs(e[1]).format("DD/MM/YYYY"),
                    });
                    localStorage.setItem(
                      "LockPeriod",
                      JSON.stringify({
                        ...filterDate,
                        from: dayjs(e[0]).format("DD/MM/YYYY"),
                        to: dayjs(e[1]).format("DD/MM/YYYY"),
                      })
                    );
                    openNotification("success", `Period locked.`);
                  }
                  setExportCreditNoteData([]);
                  setExportType(-1);
                }}
                presets={[
                  {
                    label: "Today",
                    value: [dayjs().add(0, "d"), dayjs()],
                  },
                  {
                    label: "Last 7 Days",
                    value: getLast7Days(),
                  },
                  {
                    label: "Last 15 Days",
                    value: getLast15Days(),
                  },
                  {
                    label: "This Month",
                    value: getThisMonth(),
                  },
                  {
                    label: "Last Month",
                    value: getLastMonth(),
                  },
                  {
                    label: "Quarter 1",
                    value: getQuarterPreset(0),
                  },
                  {
                    label: "Quarter 2",
                    value: getQuarterPreset(3),
                  },
                  {
                    label: "Quarter 3",
                    value: getQuarterPreset(6),
                  },
                  {
                    label: "Quarter 4",
                    value: getQuarterPreset(9),
                  },
                  {
                    label: "This Year",
                    value: thisYear(),
                  },
                  {
                    label: "Last Year",
                    value: lastYear(),
                  },
                ]}
              />
            </Col>
            <Col>
              <Select
                allowClear={false}
                value={invoiceStatus}
                style={{ width: 120 }}
                options={[
                  { value: VoucherFilterStatus.All, label: "All" },
                  { value: VoucherFilterStatus.Active, label: "Active" },
                  { value: VoucherFilterStatus.Deleted, label: "Deleted" },
                  { value: VoucherFilterStatus.Draft, label: "Draft" },
                ]}
                onChange={(val: VoucherFilterStatus) => {
                  setPage(1);
                  setInvoiceStatus(val);
                  setExportCreditNoteData([]);
                  setExportType(-1);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <div>
        <Table
          className={`Tabel-style table-${
            themeData?.componentSize ?? "middle"
          }`}
          locale={{
            triggerDesc: "",
            triggerAsc: "",
            cancelSort: "",
          }}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={
            loading
              ? (Array.from({ length: pageSize }, (_, index) => ({
                  key: `loading-${index}`,
                })) as any[])
              : creditNoteList
          }
          onChange={tableSort}
          summary={() => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row
                  style={{ backgroundColor: "#f5f8ff", fontWeight: "bold" }}
                >
                  <Table.Summary.Cell
                    index={1}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={2}
                    className="fw600"
                  ></Table.Summary.Cell>
                  <Table.Summary.Cell index={3} className="fw600">
                    Total
                  </Table.Summary.Cell>
                  <Table.Summary.Cell
                    index={4}
                    className="fw600"
                  ></Table.Summary.Cell>

                  <Table.Summary.Cell index={5} className="fw600">
                    {"   "}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={6} className="fw600" align="right">
                    ₹ {Utils.getFormattedNumber(totalAmount)}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={7} className="fw600">
                    {"   "}
                  </Table.Summary.Cell>

                  {/* <Table.Summary.Cell index={8} className="fw600">
                    {"   "}
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={8} className="fw600">
                    {"   "}
                  </Table.Summary.Cell> */}
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
          pagination={{
            total: totalRecords,
            current: page,
            pageSize: pageSize,
            showSizeChanger: true,
            onChange: (page: number, pageSize: number) => {
              setPage(page);
              setPageSize(pageSize);
            },
            pageSizeOptions: ["10", "15", "25", "50", "100"],
            showTotal: (totalRecords, page) =>
              `${page[0]}-${page[1]} of ${totalRecords} items`,
          }}
          // scroll={creditNoteList.length > 15 ? { x: 700, y: 600 } : { x: 600 }}
          scroll={
            pageSize > 15 ? { x: 1500, y: window.innerHeight - 300 } : undefined
          }
        />
      </div>
      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            setDeleteCreditNote({
              ...deleteCreditNote,
              id: creditNoteId,
              isRev:
                invoiceStatus === VoucherFilterStatus.Deleted ? true : false,
            });
            setConfirmation(false);
          }}
          text={
            invoiceStatus === VoucherFilterStatus.Deleted
              ? `Are you sure you want to revive "${deleteCreditNote.number}" ?`
              : `Are you sure you want to delete "${deleteCreditNote.number}" ?`
          }
        />
      )}
      {open && (
        <ViewCreditNote
          open={open}
          onCancel={() => setIsOpen(false)}
          creditNoteId={creditNoteId}
          voucharType={splitPathInVchType}
        />
      )}
      {open1 === "viewPreview" && (
        <ViewPreviewModel
          open={open1 === "viewPreview"}
          onCancel={() => setIsOpen1("")}
          voucherId={creditNoteId}
          invVoucharType={splitPathInVchType}
          partyId={currentClient?.id}
        />
      )}
      {openSalesInvoice && (
        <ViewSalesInvoice
          open={openSalesInvoice}
          onCancel={() => setOpenSalesInvoice(false)}
          invoiceId={salesInvoiceId}
          invVoucharType={
            splitPathInVchType === Voucher_Type.CreditNote
              ? Voucher_Type.SalesVoucher
              : Voucher_Type.PurchaseVoucher
          }
        />
      )}
    </Card>
  );
};
export default CreditNotes;
