import {
  Button,
  Card,
  Col,
  Input,
  Popover,
  Row,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import React from "react";
import ItemsService, { UnitTypes } from "../../Services/ItemsService";
import { useTheme } from "../../Context/ThemeContext";
import { useClient } from "../../Context/ClientContext";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { LedgerStatus } from "../../Services/LedgerService";
import ItemLocationModel from "./ItemLocationModel";
import { notificationContext } from "../../Common/PageRoute";
// import UnitModel from "./UnitModel";
import InvoiceExportService from "../../Services/InvoiceExportDetails";

interface IItemDataType {
  key: string;
  // companyId: string;
  id: number;
  itemLocationName: string;
  status: LedgerStatus;
}

const ItemLocations = () => {
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const { openNotification } = React.useContext(notificationContext);

  const [width, setWidth] = React.useState<any>(window.innerWidth);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [itemLocationList, setItemLocationsList] = React.useState<any[]>([]);
  const [itemLocationData, setItemLocationsData] = React.useState<any>({});
  const [searchItemLocation, setSearchItemLocation] =
    React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openModel, setOpenModel] = React.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [exportType, setExportType] = React.useState<number>(-1);
  const [exportLocData, setExportLocData] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (currentClient?.id && (exportType === -1 || exportType)) {
      // setLoading(true);
      getItemLocationsList(
        currentClient?.id,
        searchItemLocation,
        filter.sortCol,
        filter.sortDir
      );
    } else if (currentClient?.id === null) {
      console.error("No client found.");
    }

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger, searchItemLocation, filter.sortCol, filter.sortDir, exportType]);

  // --------- Get Units TableData API ---------
  const getItemLocationsList = async (
    clientId: string | undefined,
    search?: string,
    sortCol?: string,
    sortDir?: string,
    status?: LedgerStatus
  ) => {
    if (exportType < 1) {
      setLoading(true);
    }
    await ItemsService.getItemLocationsList(
      clientId,
      search,
      sortCol,
      sortDir,
      status
    )
      .then((res: any) => {
        if (res.result?.length > 0) {
          let locationData = res.result?.map((v: any) => {
            return {
              // companyId: v?.companyId,
              itemLocationName: v?.itemLocationName,
              id: v?.id,
              status: v?.status,
            };
          });

          if (exportType >= 1) {
            setExportLocData(locationData);
            if (exportType === 1) {
              exportCsv(locationData);
            } else if (exportType === 2) {
              exportPDF(locationData);
            } else if (exportType === 3) {
              exportExcel(locationData);
            }
          } else {
            setItemLocationsList(locationData);
            setLoading(false);
            // setTotalRecords(res.result?.totalRecords);
          }
        } else {
          setItemLocationsList([]);
          setLoading(false);
        }
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        setItemLocationsList([]);
      });
  };

  // --------- Units Table Columns  ---------
  const itemColumns: ColumnsType<IItemDataType> = [
    {
      title: "S. No.",
      dataIndex: "",
      key: "",
      width: "10%",
      render: (_, record, index: number) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {index + 1}
        </Skeleton>
      ),
    },
    {
      title: "Item Location's Name",
      dataIndex: "itemLocationName",
      key: "itemLocationName",
      width: "75%",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.itemLocationName}
        </Skeleton>
      ),
    },

    {
      title: "Actions",
      dataIndex: "",
      key: "edit",
      render: (record: IItemDataType) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <Row gutter={18}>
            <Col>
              <Tooltip title="Edit" placement="top" color="#3D4998">
                <EditOutlined
                  // className="ca-edit-btn"
                  style={{
                    color: "#444653",

                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setOpenModel(true);
                    setItemLocationsData({
                      id: record?.id,
                      itemLocationName: record?.itemLocationName,
                      status: record?.status,
                    });
                  }}
                />
              </Tooltip>
            </Col>
            <Col>
              <Tooltip title="Delete" placement="top" color="red">
                <DeleteOutlined
                  // className="ca-edit-btn"
                  style={{
                    color: "red",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Col>
          </Row>
        </Skeleton>
      ),
    },
  ];

  let path = "Item Location list";
  const columnsA = ["Item Location's Name"];

  const fieldMapping = {
    // "S. No.": (item: any, index: number) => index,
    "Item Location's Name": (item: any, index: number) =>
      item?.itemLocationName,
  };

  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => {});
  };

  const exportCsv = (exportData: any[]) => {
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      "",
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (exportData: any[]) => {
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      path
    );
    setExportType(0);
  };

  const exportExcel = async (exportData: any[]) => {
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      path
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportLocData.length > 0) {
              exportCsv(exportLocData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportLocData.length > 0) {
              exportPDF(exportLocData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSX"
          onClick={() => {
            if (exportLocData.length > 0) {
              exportExcel(exportLocData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSX
        </Button>
      </div>
    </Space>
  );

  return (
    <Card
      size="small"
      className="TableCard"
      style={{
        // marginTop: "6px",
        marginTop: 35,
        // padding: "48px 0px 0px 0px",
        boxShadow: "0px 0px 10px 0px #96A1C84D",
      }}
    >
      <div>
        <Row justify="space-between">
          <Col>
            <Row gutter={15}>
              <Col>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setOpenModel(true)}
                >
                  Item Location
                </Button>
              </Col>
              <Col>
                {itemLocationList?.length === 0 ? (
                  <Button icon={<DownloadOutlined />} disabled type="primary">
                    Export
                  </Button>
                ) : (
                  <Popover
                    content={content}
                    placement="rightTop"
                    trigger="hover"
                  >
                    <Button
                      type="default"
                      icon={<DownloadOutlined />}
                      // onClick={() => exportCsv()}
                    >
                      Export
                    </Button>
                  </Popover>
                )}
              </Col>
            </Row>
          </Col>
          <Col>
            <Input
              allowClear
              style={{ width: "220px" }}
              placeholder="Search Item Location"
              onChange={(e: any) => {
                setLoading(true);
                if (!e.cancelable) {
                  //console.log("typeing user............");
                  const searchItemLocation = setTimeout(() => {
                    setSearchItemLocation(e.target.value);
                  }, 1000);
                  setExportLocData([]);
                  setExportType(-1);
                  return () => clearTimeout(searchItemLocation);
                } else {
                  // console.log("Click cancle............");
                  setSearchItemLocation(e.target.value);
                }
              }}
              suffix={loading || searchItemLocation ? "" : <SearchOutlined />}
            />
          </Col>
        </Row>
        <Table
          className={`Tabel-style table-${
            themeData?.componentSize ?? "middle"
          }`}
          columns={itemColumns}
          dataSource={
            loading
              ? (Array.from({ length: pageSize }, (_, index) => ({
                  key: `loading-${index}`,
                })) as IItemDataType[])
              : itemLocationList
          }
          // scroll={
          //   pageSize > 15 ? { x: 1500, y: window.innerHeight - 300 } : undefined
          // }
          // pagination={{
          //   total: totalRecords,
          //   current: page,
          //   pageSize: pageSize,
          //   showSizeChanger: true,
          //   onChange: (page: number, pageSize: number) => {
          //     setPage(page);
          //     setPageSize(pageSize);
          //   },
          //   pageSizeOptions: ["15", "25", "50", "100"],
          //   showTotal: (totalRecords, page) =>
          //     `${page[0]}-${page[1]} of ${totalRecords} items`,
          // }}
        />
        {openModel && (
          <ItemLocationModel
            open={true}
            data={itemLocationData}
            onCancel={() => setOpenModel(false)}
            setTrigger={setTrigger}
          />
        )}
      </div>
    </Card>
  );
};

export default ItemLocations;
