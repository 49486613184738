import {
  DownloadOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Input,
  Popover,
  Row,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import React from "react";
import { useTheme } from "../../../Context/ThemeContext";
import { ColumnsType } from "antd/es/table";
import { useClient } from "../../../Context/ClientContext";
import LedgerService, {
  LedgerStatus,
  SearchLedgerByGroupsTypes,
} from "../../../Services/LedgerService";
import { Party_Tax } from "../../../Services/VoucherServices";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";
import AddCategoryItems from "./AddCategoryItemsModel";
import ItemCategoryService from "../../../Services/ItemCategoryService";
import { number } from "echarts";
import CategoryToItemList from "./CategoryToItemList";
import InvoiceExportService from "../../../Services/InvoiceExportDetails";
import MoveToCategoryItemModal from "./MoveToCategoryItemModal";
import { notificationContext } from "../../../Common/PageRoute";

interface IAllCategoryListDataType {
  key: string;
  id: string;
  currentBalance: number;
  categoryName: string;
  sno: string;
  system: LedgerStatus;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// interface IbankTransactionsDataType {
//   key: string;
//   date: string;
//   description: string;
//   transactionType: string;
//   refNo: {
//     id: number;
//     name: string;
//     type: number;
//   };
//   amount: number;
//   balance: number;
// }

const ItemCategory: React.FC = () => {
  const { themeData } = useTheme();
  const { currentClient } = useClient();
  const { openNotification } = React.useContext(notificationContext);

  const [categoryTotalRecords, setCategoryTotalRecords] =
    React.useState<number>(0);
  // const [bankTransactionsTotalRecords, setBankTransactionsTotalRecords] =
  //   React.useState<number>(0);
  const [categoryLoading, setCategoryLoading] = React.useState<boolean>(false);
  const [bankTrigger, setBankTrigger] = React.useState<boolean>(false);
  const [categoryList, setItemCategoryList] = React.useState<any[]>([]);
  const [searchItemCategory, setSearchCategory] = React.useState<string>("");
  const [pageInv, SetPageInv] = React.useState<number>(1);
  // const [pageSizeInv, setPageSizeInv] = React.useState<number>(15);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageItem, setPageItem] = React.useState<number>(1);
  const [catItemCount, setCatItemCount] = React.useState<any>();
  const [category, setCategory] = React.useState<{
    id: string;
    name: string;
    totalItem: number;
  }>({
    id: "",
    name: "",
    totalItem: 0,
  });

  const [openModel, setOpenModel] = React.useState<boolean>(false);
  const [selectCategoryDetail, setSelectCategoryDetail] = React.useState<any>(
    {}
  );
  const [filterOrder, setFilterOrder] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [openCategoryPopArray, setOpenCategoryPopArray] = React.useState<
    boolean[]
  >([]);
  const [refresh, setRefresh] = React?.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [confirmationDeleted, setConfirmationDelete] =
    React.useState<boolean>(false);
  const [id, setId] = React?.useState<any>();
  const [editCategoryData, setEditCategoryData] = React?.useState<{
    categoryName: string;
    id: string;
  }>({ categoryName: "", id: "" });

  const [exportType, setExportType] = React.useState<number>(-1);
  const [exportData, setExportData] = React.useState<any[]>([]);
  const [moveCatItmModel, setMoveCatItmModel] = React.useState<boolean>(false);

  //----- Category Table List -----
  React.useEffect(() => {
    if (currentClient?.id && (exportType === -1 || exportType)) {
      GetCategoryList(currentClient?.id, searchItemCategory);
    } else {
      console.error("currentClient is null Items");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bankTrigger,
    pageInv,
    pageItem,
    searchItemCategory,
    filterOrder.sortCol,
    filterOrder.sortDir,
    trigger,
    refresh,
    exportType,
  ]);

  // --------- Get All CategoryList Table Data  API ---------
  const GetCategoryList = async (clientId: string, search?: string) => {
    if (exportType < 1) {
      setCategoryLoading(true);
    }
    await ItemCategoryService.getCategoryList(
      clientId,
      searchItemCategory,
      filterOrder.sortCol,
      filterOrder.sortDir
    )
      .then((res: any) => {
        console.log("hfghdhhjfdgfdhjgdfhjg", res?.result);
        if (res?.result?.items.length > 0) {
          let data = res?.result?.items.map((v: any, i: any) => {
            return {
              categoryName: v?.categoryName,
              id: v?.id,
              // totalItem: v?.totalItem,
            };
          });
          if (exportType >= 1) {
            setExportData(data);
            if (exportType === 1) {
              exportCsv(data);
            } else if (exportType === 2) {
              exportPDF(data);
            } else if (exportType === 3) {
              exportExcel(data);
            }
          } else {
            setItemCategoryList(data);
            setCategoryLoading(false);

            // setTotalRecords(res.result?.totalRecords);
          }

          //   !category?.id &&
          setCategory({
            id: res?.result?.items[0]?.id,
            name: res?.result?.items[0]?.categoryName,
            //   totalItem: res?.result[0]?.partyOrTax,
            totalItem: 0,
          });
          setCategoryTotalRecords(res?.length?.items);
        } else if (res?.result?.items?.length === 0) {
          setItemCategoryList([]);
          setCategoryTotalRecords(res?.result?.totalRecords);
        }
        setCategoryLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex?.message);
        setCategoryLoading(false);
        setItemCategoryList([]);
      });
  };

  const CategoryPopoverOnchange = (columnIndex: number, newOpen: boolean) => {
    const updatedOpenPopArray = [...openCategoryPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenCategoryPopArray(updatedOpenPopArray);
  };

  // --------- Category Table Columns  ---------
  const categoryColomns: ColumnsType<IAllCategoryListDataType> = [
    {
      title: "Category Name",
      dataIndex: "categoryName",
      key: "id",
      render: (_, record, index: number) => (
        <Skeleton
          active
          loading={categoryLoading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <Row>
            <Col span={23} style={{ cursor: "pointer" }}>
              <div
                onClick={() => {
                  setCategory({
                    id: record.id,
                    name: record.categoryName,
                    totalItem: 0,
                  });
                }}
                style={{
                  cursor: "pointer",
                  color: "black",
                }}
              >
                {record?.categoryName}
              </div>
            </Col>
            <Col span={1}>
              <Popover
                trigger="click"
                placement="bottom"
                arrow={{ pointAtCenter: true }}
                open={openCategoryPopArray[index]}
                // arrow={{ pointAtCenter: true }}
                onOpenChange={(newOpen) =>
                  CategoryPopoverOnchange(index, newOpen)
                }
                // onOpenChange={(x: boolean) =>
                //   setPopoverOpen({ ...filter, inventoryTable: x })
                // }
                content={
                  <Row style={{ maxWidth: "100px" }}>
                    <Col lg={24}>
                      <Tooltip
                        title={
                          record?.system
                            ? "Default ledger are not editable"
                            : ""
                        }
                        placement="top"
                        color={record?.system ? "rgb(216 207 207)" : ""}
                      >
                        <Button
                          style={{ width: 100 }}
                          onClick={() => {
                            setOpenModel(true);
                            CategoryPopoverOnchange(index, false);
                            setEditCategoryData({
                              categoryName: record?.categoryName,
                              id: record?.id,
                            });
                          }}
                          disabled={record?.system ? true : false}
                        >
                          Edit
                        </Button>
                      </Tooltip>
                    </Col>

                    <Col lg={24} style={{ marginTop: "6px" }}>
                      <Button
                        style={{ width: 100 }}
                        disabled={record?.system ? true : false}
                        onClick={() => {
                          setConfirmationDelete(true);
                          setId(record?.id);
                          setEditCategoryData({
                            categoryName: record?.categoryName,
                            id: record?.id,
                          });
                        }}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <MoreOutlined style={{ fontSize: "16px" }} />
              </Popover>
            </Col>
          </Row>
        </Skeleton>
      ),
    },
  ];

  //------- Get Select Category Details -------
  React.useEffect(() => {
    console.log("data", category?.id);

    if (category?.id) {
      // setModelLoading(true);
      const get = async (id: string) => {
        await setSelectCategoryDetail(
          categoryList.find((x) => x.id === category?.id)
        );
      };
      // console.log("Bank Detail: ", selectCategoryDetail);

      get(category.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category?.id, trigger, refresh, categoryList]);

  let path = "Item Category Name";
  const columnsA = ["Category Name"];

  const fieldMapping = {
    // "S. No.": (item: any, index: number) => index,
    "Category Name": (item: any, index: number) => item?.categoryName,
  };

  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => {});
  };

  const exportCsv = (exportData: any[]) => {
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      "",
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (exportData: any[]) => {
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      path
    );
    setExportType(0);
  };

  const exportExcel = async (exportData: any[]) => {
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      path
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportData.length > 0) {
              exportCsv(exportData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportData.length > 0) {
              exportPDF(exportData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSX"
          onClick={() => {
            if (exportData.length > 0) {
              exportExcel(exportData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSX
        </Button>
      </div>
    </Space>
  );

  return (
    <div>
      <div
        style={{
          // borderRadius: "8px",
          marginTop: 7,
          // backgroundColor: "black",
          padding: "10px 0px 0px 0px",
          // boxShadow: "0px 0px 10px 0px #96A1C84D",
        }}
      ></div>
      <Row style={{ marginTop: "0px" }}>
        <Col lg={6} style={{ paddingRight: "5px", paddingTop: "7px" }}>
          <Card
            className="ca-card-body"
            style={{ boxShadow: "0px 0px 10px 0px #96A1C84D" }}
          >
            <div>
              <Row
                align="middle"
                justify="space-around"
                style={{ borderBottom: "1px solid #DFE9F7" }}
              >
                <Col lg={13}>
                  <Input
                    allowClear={true}
                    // width: "220px",
                    style={{ margin: "5px 0px 5px 0px" }}
                    placeholder="Search.."
                    onChange={(e: any) => {
                      setCategoryLoading(true);
                      if (!e.cancelable) {
                        const searchItemCategory = setTimeout(() => {
                          SetPageInv(1);
                          setSearchCategory(e.target.value);
                        }, 1000);
                        return () => clearTimeout(searchItemCategory);
                      } else {
                        setSearchCategory(e.target.value);
                      }
                    }}
                    suffix={
                      categoryLoading || searchItemCategory ? (
                        ""
                      ) : (
                        <SearchOutlined />
                      )
                    }
                  />
                </Col>
                {/* style={{ marginLeft: "8px" }} */}
                <Col>
                  <Button
                    className="customButtonCss"
                    type="primary"
                    id=""
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setOpenModel(true);
                      setEditCategoryData({
                        categoryName: "",
                        id: "",
                      });
                    }}
                  >
                    Category
                  </Button>
                </Col>
                <Col>
                  {categoryList.length === 0 ? (
                    <Button
                      icon={<DownloadOutlined />}
                      disabled
                      type="primary"
                    ></Button>
                  ) : (
                    <Popover
                      content={content}
                      placement="rightTop"
                      trigger="hover"
                    >
                      <Button
                        icon={<DownloadOutlined />}
                        type="primary"
                      ></Button>
                    </Popover>
                  )}
                </Col>
              </Row>
            </div>
            <div
              className={"withoutanimation"}
              style={{
                maxHeight: "100%",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <Table
                rowClassName={(record, index) => {
                  return record.id === category.id ? "ca-active-table" : "";
                }}
                className={`Tabel-style-none table-${
                  themeData?.componentSize ?? "middle"
                }`}
                // showHeader={false}
                // rowSelection={rowSelection}
                columns={categoryColomns}
                dataSource={
                  categoryLoading
                    ? (Array.from({ length: 20 }, (_, index) => ({
                        key: `loading-${index}`,
                      })) as IAllCategoryListDataType[])
                    : categoryList
                }
                pagination={false}
                footer={() => (
                  <>
                    <div
                      style={{
                        position: "sticky",
                        bottom: 0,
                        backgroundColor: "#F5F8FF",
                      }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <th className="textStart">
                              Total Categorys
                              {/* {filterOption.partyType === 0
                              ? " Party"
                              : filterOption.partyType === 1
                              ? " Customer"
                              : " Supplier"} */}
                            </th>
                            <th style={{}}>{categoryTotalRecords}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              />
            </div>
          </Card>
        </Col>

        <Col
          lg={18}
          style={{ paddingLeft: "5px", paddingTop: "7px" }}
          className="ca-card-body-padding"
        >
          <Card
            style={{
              marginBottom: "10px",
              boxShadow: "0px 0px 10px 0px #96A1C84D",
            }}
          >
            <Row justify={"space-between"}>
              <Col span={6}>
                <div>
                  <>
                    {/* <span
                        style={{
                          paddingRight: "10px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        Ledger:
                      </span> */}
                    {selectCategoryDetail?.categoryName}
                  </>
                </div>
                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Number of Items:
                  </span>
                  {catItemCount ?? 0}
                </div>
              </Col>
              {/* <Col span={7}>
                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Balance:
                  </span>
                  {selectCategoryDetail?.balance === 0
                    ? 0
                    : selectCategoryDetail?.balance > 0
                    ? Utils.getFormattedNumber(selectCategoryDetail?.balance) +
                      " Dr"
                    : Utils.getFormattedNumber(
                        Math.abs(selectCategoryDetail?.balance)
                      ) + " Cr"}
                  
                </div>
                <div>
                  <span
                    style={{
                      paddingRight: "14px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    A/c No.:
                  </span>
                  {selectCategoryDetail?.bankdetail?.accountNumber!}
                  {selectCategoryDetail.accountNumber?.length > 23 ? (
                    <Tooltip
                      title={selectCategoryDetail?.accountNumber}
                      placement="top"
                      color="#3D4998"
                    >
                      {selectCategoryDetail?.accountNumber.slice(0, 23) + "..."}
                    </Tooltip>
                  ) : (
                    selectCategoryDetail?.accountNumber
                  )}
                </div>
              </Col>
              <Col span={6}>
                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    IFSC Code:
                  </span>
                  {selectCategoryDetail?.bankdetail?.ifsCode!}
                </div>
                <div>
                  <span
                    style={{
                      paddingRight: "16px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Bank Name:
                  </span>
                  {selectCategoryDetail?.bankdetail?.bankName!}
                </div>
              </Col> */}

              <Col span={5} style={{ display: "flex", justifyContent: "end" }}>
                <div style={{ paddingTop: "6px" }}>
                  <Button
                    type="primary"
                    onClick={() => setMoveCatItmModel(true)}
                  >
                    Move To This Category
                  </Button>
                </div>
              </Col>
            </Row>
          </Card>

          <Card
            className="ca-card-body"
            style={{ boxShadow: "0px 0px 10px 0px #96A1C84D" }}
          >
            <div
              style={{
                height: `calc(100vh - 216px)`,
                // backgroundColor: "azure",
                overflowY: "auto",
              }}
            >
              {/* {category.id && (
                <VoucherDetailsByLedger
                  refresh={refresh}
                  ledgerId={category?.id}
                  partyOrTax={category?.partyOrTax}
                  ledgerName={category?.categoryName}
                />
              )} */}
              {category.id && (
                <CategoryToItemList
                  refresh={refresh}
                  categoryId={category?.id}
                  setCatItemCount={setCatItemCount}
                />
              )}
            </div>
          </Card>
        </Col>
      </Row>

      {confirmationDeleted && (
        <ConfirmationModal
          open={confirmationDeleted}
          onNo={() => setConfirmationDelete(false)}
          onYes={async () =>
            await ItemCategoryService.deleteCategoryDatabyId(
              currentClient?.id!,
              id
            )
              .then((res: any) => {
                if (res.result === true) {
                  console.log("res", res);
                  setTrigger((x: boolean) => !x);
                  openNotification("success", "Delete Sucessfully");
                  setConfirmationDelete(false);
                } else if (res?.message) {
                  debugger;
                  openNotification("error", res?.message);
                  setConfirmationDelete(false);
                }
              })
              .catch((err: any) => {
                openNotification("error", err);
                setConfirmationDelete(false);
                console.log("error", err);
              })
          }
          text={`Are you sure you want to Delete Category "${editCategoryData?.categoryName}"?`}
          loading={false}
        />
      )}

      {/* -------------ADD Category Name ----------*/}

      {openModel && (
        <AddCategoryItems
          open={true}
          onCancel={() => setOpenModel(false)}
          setTrigger={setTrigger}
          editData={editCategoryData}
        />
      )}

      {moveCatItmModel && (
        <MoveToCategoryItemModal
          open={true}
          onCancel={() => setMoveCatItmModel(false)}
          setTrigger={setTrigger}
          // editData={[]}
          id={category?.id}
        />
      )}
    </div>
  );
};

export default ItemCategory;
