import {
  Button,
  Card,
  Col,
  Input,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tooltip,
} from "antd";
import React from "react";

import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useClient } from "../../../Context/ClientContext";
import { useTheme } from "../../../Context/ThemeContext";
import ItemsService, {
  FilterCategoryInItems,
} from "../../../Services/ItemsService";
import { LedgerStatus } from "../../../Services/LedgerService";
// import UnitModel from "./UnitModel";
import InvoiceExportService from "../../../Services/InvoiceExportDetails";
import { Utils } from "../../../../Utilities/Utils";
import CategoryItems from "../CategoryItems";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";
import { notificationContext } from "../../../Common/PageRoute";
import { CategoryType } from "../../../Services/CategoryService";

interface IItemDataType {
  key: string;
  number: string;
  // companyId: string;
  id: number;
  name: string;
  stockQty: number;
}

const CategoryToItemList: React.FC<{
  refresh: boolean;
  categoryId: string;
  setCatItemCount: any;
}> = (props) => {
  const { currentClient } = useClient();
  const { themeData } = useTheme();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState<number>(15);
  const [itemList, setItemList] = React.useState<any[]>([]);
  const [searchItem, setSearchItem] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [exportType, setExportType] = React.useState<number>(-1);

  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [filter, setfilter] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [isfilterCategoryInItems, setIsfilterCategoryInItems] =
    React.useState<FilterCategoryInItems>(FilterCategoryInItems.AvailableItems);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [exportItemData, setExportItemData] = React.useState<any[]>([]);
  const [confirmationDeleted, setConfirmationDelete] =
    React.useState<boolean>(false);
  const { openNotification } = React.useContext(notificationContext);
  const [idOrName, setIdOrName] = React.useState<any>({
    id: "",
    name: "",
  });
  React.useEffect(() => {
    // setLoading(true);
    if (currentClient?.id && (exportType === -1 || exportType)) {
      getItemLocationsList(
        currentClient?.id,
        props?.categoryId,
        isfilterCategoryInItems,
        exportType >= 1 ? 0 : (page - 1) * pageSize,
        exportType >= 1 ? 0 : pageSize,
        searchItem,
        filter?.sortCol,
        filter?.sortDir
      );
    } else if (currentClient?.id === null) {
      console.error("No client found.");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    trigger,
    props.categoryId,
    searchItem,
    filter.sortCol,
    filter.sortDir,
    isfilterCategoryInItems,
    page,
    pageSize,
    exportType,
    refresh,
  ]);

  // --------- Get Units TableData API ---------
  const getItemLocationsList = async (
    clientId: string | undefined,
    categoryId: string,
    isCategoryInItem: FilterCategoryInItems,
    start: number,
    length: number,
    search?: string,
    sortCol?: string,
    sortDir?: string,
    status?: LedgerStatus
  ) => {
    if (exportType < 1) {
      setLoading(true);
    }
    await ItemsService.getCategoryToItemList(
      clientId,
      categoryId,
      isCategoryInItem,
      start,
      length,
      search,
      sortCol,
      sortDir,
      status
    )
      .then((res: any) => {
        if (res.result?.items?.length > 0) {
          let itemCatData = res.result?.items?.map((v: any) => {
            return {
              // companyId: v?.companyId,
              number: v?.number,
              name: v?.name,
              id: v?.id,
              stockQty: v?.stockQty,
              status: v?.status,
            };
          });
          if (exportType >= 1) {
            setExportItemData(itemCatData);
            if (exportType === 1) {
              exportCsv(itemCatData);
            } else if (exportType === 2) {
              exportPDF(itemCatData);
            } else if (exportType === 3) {
              exportExcel(itemCatData);
            }
          } else {
            setItemList(itemCatData);
            setLoading(false);
            setTotalRecords(res.result?.totalRecords);
            props?.setCatItemCount(res.result?.totalRecords);
          }
        } else if (res.result?.items?.length === 0) {
          setItemList([]);
          setLoading(false);
          setTotalRecords(res.result?.totalRecords);
          props?.setCatItemCount(res.result?.totalRecords);
        }
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setLoading(false);
        setItemList([]);
      });
  };

  console.log("idd", idOrName);

  const moveCatData = async () => {
    let add = [idOrName.id];
    // setBtnLoading(true);
    let res = await ItemsService?.moveCategoryData(
      currentClient?.id,
      props?.categoryId,
      isfilterCategoryInItems,
      add
    );
    if (res?.status) {
      console.log("res", res);
      openNotification(
        "success",
        `${
          isfilterCategoryInItems === FilterCategoryInItems.AvailableItems
            ? "Remove"
            : "Add"
        } this item successfully`
      );
      // props.onCancel(false);
      setConfirmationDelete(false);
      setRefresh((x: boolean) => !x);
      // setBtnLoading(false);
    } else {
      // setBtnLoading(false);
      openNotification("error", res.message);
    }
  };

  // --------- Units Table Columns  ---------
  const itemColumns: ColumnsType<IItemDataType> = [
    {
      title: "S. No.",
      dataIndex: "",
      key: "",
      width: "10%",
      render: (_, record, index: number) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {index + 1}
        </Skeleton>
      ),
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      width: "25%",
      sorter: true,
      defaultSortOrder: "ascend",
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.number}
        </Skeleton>
      ),
    },
    {
      title: "Item Name",
      dataIndex: "name",
      key: "name",
      width: "25%",
      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.name}
        </Skeleton>
      ),
    },
    {
      title: "Stock Quantity",
      dataIndex: "stockQty",
      key: "stockQty",
      width: "25%",

      sorter: true,
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          {record?.stockQty}
        </Skeleton>
      ),
    },

    {
      title: "Action",
      dataIndex: "",
      key: "y",
      // width: "25%"
      render: (_, record) => (
        <Skeleton
          active
          loading={loading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <SyncOutlined
            onClick={() => {
              setIdOrName({ id: record?.id, name: record?.name });
              setConfirmationDelete(true);
            }}
          />
        </Skeleton>
      ),
    },
    // {
    //   title: "Actions",
    //   dataIndex: "",
    //   key: "edit",
    //   render: (record: IItemDataType) => (
    //     <Skeleton
    //       active
    //       loading={loading}
    //       paragraph={{ rows: 1 }}
    //       title={false}
    //     >
    //       <Row gutter={18}>
    //         <Col>
    //           <Tooltip title="Edit" placement="top" color="#3D4998">
    //             <EditOutlined
    //               // className="ca-edit-btn"
    //               style={{
    //                 color: "#444653",

    //                 cursor: "pointer",
    //               }}
    //               onClick={() => {
    //                 setItemData({
    //                   id: record?.id,
    //                   name: record?.name,
    //                   //   status: record?.status,
    //                 });
    //               }}
    //             />
    //           </Tooltip>
    //         </Col>
    //         <Col>
    //           <Tooltip title="Delete" placement="top" color="red">
    //             <DeleteOutlined
    //               // className="ca-edit-btn"
    //               style={{
    //                 color: "red",
    //                 cursor: "pointer",
    //               }}
    //             />
    //           </Tooltip>
    //         </Col>
    //       </Row>
    //     </Skeleton>
    //   ),
    // },
  ];

  const tableSort = (pagination?: any, sort?: any, filter?: any) => {
    setfilter({
      sortCol: filter.column !== undefined ? filter.columnKey : "number",
      sortDir:
        filter.column !== undefined
          ? filter.order === "descend"
            ? "desc"
            : "asc"
          : "desc",
    });
    console.log("sort", sort);

    //  setFilterGroupTypes(sort?.group_Type);
  };
  let path = "Item Category";
  const columnsA = ["Number", "Item Name", "Stock Quantity"];

  const fieldMapping = {
    // "S no": (item: any, index: number) => index + 1,
    Number: (item: any) => item?.number,
    "Item Name": (item: any) => item?.name,
    "Stock Quantity": (item: any) => item?.stockQty,
  };

  const generateFooterRow = (columns: string[]) => {
    return columns.map((col) => {});
  };

  const exportCsv = (exportData: any[]) => {
    InvoiceExportService.generateCSV(
      exportData,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      "",
      path,
      currentClient?.businessName
    );
    setExportType(0);
  };

  const exportPDF = (exportData: any[]) => {
    InvoiceExportService.generatePDF(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      path
    );
    setExportType(0);
  };

  const exportExcel = async (exportData: any[]) => {
    await InvoiceExportService.generateExcel(
      exportData,
      currentClient?.businessName,
      columnsA,
      fieldMapping,
      generateFooterRow,
      "",
      path
    );
    setExportType(0);
  };

  const content = (
    <Space direction="vertical">
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="CSV"
          onClick={() => {
            if (exportItemData.length > 0) {
              exportCsv(exportItemData);
            } else {
              setExportType(1);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          CSV
        </Button>
      </div>

      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="PDF"
          onClick={() => {
            if (exportItemData.length > 0) {
              exportPDF(exportItemData);
            } else {
              setExportType(2);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          PDF
        </Button>
      </div>
      <div>
        <Button
          style={{ width: "80px" }}
          size="small"
          title="XLSX"
          onClick={() => {
            if (exportItemData.length > 0) {
              exportExcel(exportItemData);
            } else {
              setExportType(3);
              // setApiCallExports(true);
            }
          }}
          icon={<DownloadOutlined />}
        >
          XLSX
        </Button>
      </div>
    </Space>
  );

  return (
    <div>
      <Row justify={"space-between"}>
        <Col>
          <Row gutter={15}>
            <Col>
              <Select
                value={isfilterCategoryInItems}
                options={[
                  {
                    value: FilterCategoryInItems.AvailableItems,
                    label: "Available Items",
                  },
                  {
                    value: FilterCategoryInItems.NotAvailableItems,
                    label: "Not Available Items",
                  },
                ]}
                onChange={(e) => {
                  setIsfilterCategoryInItems(e);
                  setExportItemData([]);
                  setExportType(-1);
                }}
              />
            </Col>
            <Col>
              {itemList.length === 0 ? (
                <Button icon={<DownloadOutlined />} disabled type="primary">
                  Export
                </Button>
              ) : (
                <Popover content={content} placement="rightTop" trigger="hover">
                  <Button
                    type="default"
                    icon={<DownloadOutlined />}
                    // onClick={() => exportCsv()}
                    disabled={itemList.length === 0 ? true : false}
                  >
                    Export
                  </Button>
                </Popover>
              )}
            </Col>
          </Row>
        </Col>
        <Col>
          <Input
            allowClear
            style={{ width: "220px" }}
            placeholder="Search"
            onChange={(e: any) => {
              setLoading(true);
              if (!e.cancelable) {
                //console.log("typeing user............");
                const searchItem = setTimeout(() => {
                  setSearchItem(e.target.value);
                }, 1000);
                setExportItemData([]);
                setExportType(-1);
                return () => clearTimeout(searchItem);
              } else {
                // console.log("Click cancle............");
                setSearchItem(e.target.value);
              }
            }}
            suffix={loading || searchItem ? "" : <SearchOutlined />}
          />
        </Col>
      </Row>
      <Table
        className={`Tabel-style table-${themeData?.componentSize ?? "middle"}`}
        columns={itemColumns}
        dataSource={
          loading
            ? (Array.from({ length: pageSize }, (_, index) => ({
                key: `loading-${index}`,
              })) as IItemDataType[])
            : itemList
        }
        onChange={tableSort}
        pagination={{
          total: totalRecords,
          current: page,
          pageSize: pageSize,
          showSizeChanger: true,
          onChange: (page: number, pageSize: number) => {
            setPage(page);
            setPageSize(pageSize);
          },
          pageSizeOptions: ["10", "15", "25", "50", "100"],
          showTotal: (totalRecords, range) =>
            `${range[0]}-${range[1]} of ${totalRecords} items`,
        }}
      />

      {confirmationDeleted && (
        <ConfirmationModal
          open={confirmationDeleted}
          onYes={() => moveCatData()}
          onNo={() => setConfirmationDelete(false)}
          text={`${
            isfilterCategoryInItems === FilterCategoryInItems.AvailableItems
              ? "Remove"
              : "Add"
          } category Item 
            "${idOrName?.name}"`}
            
        />
      )}
    </div>
  );
};

export default CategoryToItemList;
