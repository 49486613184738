import {
  DownOutlined,
  MoreOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Input,
  MenuProps,
  Popover,
  Row,
  Skeleton,
  Table,
  Tag,
  Tooltip,
  message,
  notification,
} from "antd";
import React from "react";
import { useTheme } from "../../../Context/ThemeContext";
import { ColumnsType } from "antd/es/table";
import { useClient } from "../../../Context/ClientContext";
import AddBankAccountModel from "./AddBankAccountModel";
import AdjustBankBalanceModel, {
  AdjustBankType,
} from "./AdjustBankBalanceModel";
import LedgerService, {
  LedgerStatus,
  SearchLedgerByGroupsTypes,
} from "../../../Services/LedgerService";
import VoucherDetailsByLedger from "../../Reports/VoucherDetailsByLedger";
import { Party_Tax } from "../../../Services/VoucherServices";
import { useKeyboardShortcuts } from "../../../Common/KeyboardShortcuts ";
import { NotificationType } from "../../../Common/PageRoute";
import { ConfirmationModal } from "../../../Common/ConfirmationModal";
import { Utils } from "../../../../Utilities/Utils";

interface IAllBankListDataType {
  key: string;
  id: string;
  currentBalance: number;
  ledgerName: string;
  sno: string;
  partyOrTax: Party_Tax;
  system: LedgerStatus;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface IbankTransactionsDataType {
  key: string;
  date: string;
  description: string;
  transactionType: string;
  refNo: {
    id: number;
    name: string;
    type: number;
  };
  amount: number;
  balance: number;
}
const { RangePicker } = DatePicker;

const BankAccountsAllEffected: React.FC = () => {
  const { themeData } = useTheme();
  const { currentClient } = useClient();
  const [bankTotalRecords, setBankTotalRecords] = React.useState<number>(0);
  // const [bankTransactionsTotalRecords, setBankTransactionsTotalRecords] =
  //   React.useState<number>(0);
  const [bankLoading, setBankLoading] = React.useState<boolean>(false);
  const [bankTransactionsLoading, setBankTransactionsLoading] =
    React.useState<boolean>(false);
  const [bankTrigger, setBankTrigger] = React.useState<boolean>(false);
  const [bankList, setBankList] = React.useState<any[]>([]);
  const [searchBank, setSearchBank] = React.useState<any>();
  const [pageInv, SetPageInv] = React.useState<number>(1);
  // const [pageSizeInv, setPageSizeInv] = React.useState<number>(15);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pageItem, setPageItem] = React.useState<number>(1);
  const [pageSizeItem, setPageSizeItem] = React.useState<number>(15);
  const [bank, setBank] = React.useState<{
    id: string;
    name: string;
    partyOrTax: Party_Tax;
  }>({
    id: "",
    name: "",
    partyOrTax: Party_Tax.other,
  });
  const [filterOption, setFilterOption] = React.useState<{
    gstRate: number[];
    partyType: number;
    iState: number;
  }>({
    gstRate: [],
    partyType: 0,
    iState: 0,
  });
  const [openModel, setOpenModel] = React.useState<{
    type: string;
    param: any;
  }>({
    type: "",
    param: {},
  });
  const [bankAccountDetails, setBankAccountDetails] = React.useState<any>({});
  const [filterOrder, setFilterOrder] = React.useState<any>({
    sortCol: "",
    sortDir: "",
  });
  const [filterOrderInventory, setfilterOrderInventory] = React.useState<{
    invSortCol: string;
    invSortDir: string;
  }>({
    invSortCol: "",
    invSortDir: "",
  });
  const [type, setType] = React.useState<number[]>([]);
  // const [filterDate, setFilterDate] = React.useState<any>({
  //   from: "",
  //   to: "",
  // });
  const [triggerBankTransactions, setTriggerBankTransactions] =
    React.useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();

  const [openbankAccountPopArray, setOpenBankAccountNoPopArray] =
    React.useState<boolean[]>([]);
  const [openbankTransactionPopArray, setOpenBankTransactionPopArray] =
    React.useState<boolean[]>([]);
  const [bankBalance, setBankBalance] = React.useState<any>(0);
  const [refresh, setRefresh] = React?.useState<boolean>(false);
  const [trigger, setTrigger] = React.useState<boolean>(false);
  const [confirmationDeleted, setConfirmationDelete] =
    React.useState<boolean>(false);
  const [id, setId] = React?.useState<any>();
  const [AccName, setAccName] = React?.useState<any>();

  const openNotification = (type: NotificationType, message: string) => {
    api[type]({
      message: "",
      description: message,
      duration: 2,
      closeIcon: false,
      style: {
        width: 350,
      },
    });
  };

  //----- Bank Account Table List -----
  React.useEffect(() => {
    if (currentClient?.id) {
      GetLedgersByGroupTypes(currentClient?.id, searchBank);
    } else {
      console.error("currentClient is null Items");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // props.bankTrigger,
    bankTrigger,
    pageInv,
    pageItem,
    searchBank,
    filterOrder.sortCol,
    filterOrder.sortDir,
    filterOption.gstRate,
    trigger,
    refresh,
  ]);

  // --------- Get All BankAccount Table Data  API ---------
  const GetLedgersByGroupTypes = async (clientId: string, search?: string) => {
    setBankLoading(true);
    await LedgerService?.getLedgersByGroupName(
      clientId,
      SearchLedgerByGroupsTypes?.BankOnly,
      searchBank
    )
      .then((res: any) => {
        console.log("hfghdhhjfdgfdhjgdfhjg", res);
        if (res.length > 0) {
          setBankList(
            res?.map((v: any, i: any) => {
              return {
                parent: v.parent,
                ledgerName: v?.ledgerName,
                aliasName: v?.aliasName,
                id: v?.id,
                bankDetail: v?.bankDetail !== null ? v?.bankDetail[0] : [{}],
                balance: v?.balance,
                partyOrTax: v?.partyOrTax,
                system: v?.system,
                attachments: v?.attachments,
              };
            })
          );

          !bank.id &&
            setBank({
              id: res[0].id,
              name: res[0].ledgerName,
              partyOrTax: res[0].partyOrTax,
            });
          setBankTotalRecords(res?.length);
        } else if (res.length === 0) {
          setBankList([]);
          setBankTotalRecords(res?.bankTotalRecords);
        }
        setBankLoading(false);
      })
      .catch((ex: any) => {
        console.error(ex.message);
        setBankLoading(false);
        setBankList([]);
      });
  };

  const bankAccountPopoverOnchange = (
    columnIndex: number,
    newOpen: boolean
  ) => {
    const updatedOpenPopArray = [...openbankAccountPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenBankAccountNoPopArray(updatedOpenPopArray);
  };

  // --------- Bank Account Table Columns  ---------
  const bankColomns: ColumnsType<IAllBankListDataType> = [
    {
      title: "Account Name",
      dataIndex: "ledgerName",
      key: "id",
      render: (_, record, index: number) => (
        <Skeleton
          active
          loading={bankLoading}
          paragraph={{ rows: 1 }}
          title={false}
        >
          <Row>
            <Col span={23} style={{ cursor: "pointer" }}>
              <div
                onClick={() => {
                  setBank({
                    id: record.id,
                    name: record.ledgerName,
                    partyOrTax: record?.partyOrTax,
                  });
                }}
                style={{
                  cursor: "pointer",
                  color: "black",
                }}
              >
                {record.ledgerName}
              </div>
            </Col>
            <Col span={1}>
              <Popover
                trigger="click"
                placement="bottom"
                arrow={{ pointAtCenter: true }}
                open={openbankAccountPopArray[index]}
                // arrow={{ pointAtCenter: true }}
                onOpenChange={(newOpen) =>
                  bankAccountPopoverOnchange(index, newOpen)
                }
                // onOpenChange={(x: boolean) =>
                //   setPopoverOpen({ ...filter, inventoryTable: x })
                // }
                content={
                  <Row style={{ maxWidth: "100px" }}>
                    <Col lg={24}>
                      <Tooltip
                        title={
                          record?.system
                            ? "Default ledger are not editable"
                            : ""
                        }
                        placement="top"
                        color={record?.system ? "rgb(216 207 207)" : ""}
                      >
                        <Button
                          style={{ width: 100 }}
                          onClick={() => {
                            setOpenModel({
                              type: "addBankAccount",
                              param: {
                                id: record?.id,
                              },
                            });
                            bankAccountPopoverOnchange(index, false);
                          }}
                          disabled={record?.system ? true : false}
                        >
                          Edit
                        </Button>
                      </Tooltip>
                    </Col>

                    <Col lg={24} style={{ marginTop: "6px" }}>
                      <Button
                        style={{ width: 100 }}
                        disabled={record?.system ? true : false}
                        onClick={() => {
                          setConfirmationDelete(true);
                          setId(record?.id);
                          setAccName(record?.ledgerName);
                        }}
                      >
                        Delete
                      </Button>
                    </Col>
                  </Row>
                }
              >
                <MoreOutlined style={{ fontSize: "16px" }} />
              </Popover>
            </Col>
          </Row>
        </Skeleton>
      ),
    },
  ];

  //------- Get Bank Account Details -------
  React.useEffect(() => {
    console.log("data", bank?.id);

    if (bank?.id) {
      // setModelLoading(true);
      const get = async (id: string) => {
        await setBankAccountDetails(bankList.find((x) => x.id === bank?.id));
      };
      // console.log("Bank Detail: ", bankAccountDetails);

      get(bank.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bank?.id, trigger, refresh, bankList]);

  // Table Loading

  const bankTransactionsPopoverOnchange = (
    columnIndex: number,
    newOpen: boolean
  ) => {
    const updatedOpenPopArray = [...openbankTransactionPopArray];
    updatedOpenPopArray[columnIndex] = newOpen;
    setOpenBankTransactionPopArray(updatedOpenPopArray);
  };

  // const items: MenuProps["items"] = [
  //   {
  //     key: "1",
  //     label: (
  //       <div
  //         onClick={() => {
  //           setOpenModel({
  //             type: "adjustBankBalance",
  //             param: { id: "", type: AdjustBankType.BankToBank },
  //           });
  //         }}
  //       >
  //         Bank to Bank Transfer
  //       </div>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: (
  //       <div
  //         onClick={() => {
  //           setOpenModel({
  //             type: "adjustBankBalance",
  //             param: { id: "", type: AdjustBankType.BankToCash },
  //           });
  //         }}
  //       >
  //         Bank to Cash Transfer
  //       </div>
  //     ),
  //   },
  //   {
  //     key: "3",
  //     label: (
  //       <div
  //         onClick={() => {
  //           setOpenModel({
  //             type: "adjustBankBalance",
  //             param: { id: "", type: AdjustBankType.CashToBank },
  //           });
  //         }}
  //       >
  //         Cash to Bank Transfer
  //       </div>
  //     ),
  //   },
  //   {
  //     key: "4",
  //     label: (
  //       <div
  //         onClick={() => {
  //           setOpenModel({
  //             type: "adjustBankBalance",
  //             param: { id: "", type: AdjustBankType.CashToCash },
  //           });
  //         }}
  //       >
  //         Cash to Cash Transfer
  //       </div>
  //     ),
  //   },
  //   // {
  //   //   key: "4",
  //   //   label: (
  //   //     <div
  //   //       onClick={() => {
  //   //         setOpenModel({
  //   //           type: "adjustBankBalance",
  //   //           param: { id: "", type: BankAdjustType.IncreaseBalance },
  //   //         });
  //   //       }}
  //   //     >
  //   //       Adjust Bank Balance
  //   //     </div>
  //   //   ),
  //   // },
  // ];

  return (
    <div>
      {/* //tabbackground */}
      <div
        style={{
          // borderRadius: "8px",
          marginTop: 7,
          // backgroundColor: "black",
          padding: "10px 0px 0px 0px",
          // boxShadow: "0px 0px 10px 0px #96A1C84D",
        }}
      ></div>
      <Row style={{ marginTop: "0px" }}>
        <Col lg={6} style={{ paddingRight: "5px", paddingTop: "7px" }}>
          <Card
            className="ca-card-body"
            style={{ boxShadow: "0px 0px 10px 0px #96A1C84D" }}
          >
            <div>
              <Row
                align="middle"
                justify="space-around"
                style={{ borderBottom: "1px solid #DFE9F7" }}
              >
                <Col lg={15}>
                  <Input
                    allowClear={true}
                    // width: "220px",
                    style={{ margin: "5px 0px 5px 0px" }}
                    placeholder="Search.."
                    onChange={(e: any) => {
                      setBankLoading(true);
                      if (!e.cancelable) {
                        const searchBank = setTimeout(() => {
                          SetPageInv(1);
                          setSearchBank(e.target.value);
                        }, 1000);
                        return () => clearTimeout(searchBank);
                      } else {
                        setSearchBank(e.target.value);
                      }
                    }}
                    suffix={bankLoading || searchBank ? "" : <SearchOutlined />}
                  />
                </Col>
                {/* style={{ marginLeft: "8px" }} */}
                <Col lg={7}>
                  <Button
                    className="customButtonCss"
                    type="primary"
                    id=""
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setOpenModel({
                        type: "addBankAccount",
                        param: {
                          id: "",
                          type: "",
                        },
                      });
                    }}
                  >
                    Bank
                  </Button>
                </Col>
              </Row>
            </div>
            <div
              className={"withoutanimation"}
              style={{
                maxHeight: "100%",
                overflowY: "auto",
                position: "relative",
              }}
            >
              <Table
                rowClassName={(record, index) => {
                  return record.id === bank.id ? "ca-active-table" : "";
                }}
                className={`Tabel-style-none table-${
                  themeData?.componentSize ?? "middle"
                }`}
                // showHeader={false}
                // rowSelection={rowSelection}
                columns={bankColomns}
                dataSource={
                  bankLoading
                    ? (Array.from({ length: 20 }, (_, index) => ({
                        key: `loading-${index}`,
                      })) as IAllBankListDataType[])
                    : bankList
                }
                pagination={false}
                footer={() => (
                  <>
                    <div
                      style={{
                        position: "sticky",
                        bottom: 0,
                        backgroundColor: "#F5F8FF",
                      }}
                    >
                      <table>
                        <tbody>
                          <tr>
                            <th className="textStart">
                              Total Bank Account(s)
                              {/* {filterOption.partyType === 0
                              ? " Party"
                              : filterOption.partyType === 1
                              ? " Customer"
                              : " Supplier"} */}
                            </th>
                            <th style={{}}>{bankTotalRecords}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              />
            </div>
          </Card>
        </Col>

        <Col
          lg={18}
          style={{ paddingLeft: "5px", paddingTop: "7px" }}
          className="ca-card-body-padding"
        >
          <Card
            style={{
              marginBottom: "10px",
              boxShadow: "0px 0px 10px 0px #96A1C84D",
            }}
          >
            <Row>
              <Col span={6}>
                <div>
                  {bankAccountDetails.ledgerName?.length > 30 ? (
                    <Tooltip
                      title={bankAccountDetails?.ledgerName}
                      placement="top"
                      color="#3D4998"
                    >
                      {bankAccountDetails?.ledgerName.slice(0, 30) + "..."}
                    </Tooltip>
                  ) : (
                    <>
                      <span
                        style={{
                          paddingRight: "10px",
                          color: "rgb(139, 143, 165)",
                        }}
                      >
                        Ledger:
                      </span>
                      {bankAccountDetails?.ledgerName}
                    </>
                  )}
                </div>
                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Account Type:
                  </span>
                  {bankAccountDetails?.bankdetail?.accountType === 1
                    ? "Saving Account"
                    : bankAccountDetails?.bankdetail?.accountType === 2
                    ? "Current Account"
                    : bankAccountDetails?.bankdetail?.accountType === 3
                    ? "Credit Card"
                    : ""}
                </div>
              </Col>
              <Col span={7}>
                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Balance:
                  </span>
                  {bankAccountDetails?.balance === 0
                    ? 0
                    : bankAccountDetails?.balance > 0
                    ? Utils.getFormattedNumber(bankAccountDetails?.balance) +
                      " Dr"
                    : Utils.getFormattedNumber(
                        Math.abs(bankAccountDetails?.balance)
                      ) + " Cr"}
                  {/* {bankAccountDetails.upiId?.length > 23 ? (
                    <Tooltip
                      title={bankAccountDetails?.upiId}
                      placement="bottom"
                      color="#3D4998"
                    >
                      {bankAccountDetails?.upiId.slice(0, 35) + "..."}
                    </Tooltip>
                  ) : (
                    bankAccountDetails?.upiId
                  )} */}
                </div>
                <div>
                  <span
                    style={{
                      paddingRight: "14px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    A/c No.:
                  </span>
                  {bankAccountDetails?.bankdetail?.accountNumber!}
                  {bankAccountDetails.accountNumber?.length > 23 ? (
                    <Tooltip
                      title={bankAccountDetails?.accountNumber}
                      placement="top"
                      color="#3D4998"
                    >
                      {bankAccountDetails?.accountNumber.slice(0, 23) + "..."}
                    </Tooltip>
                  ) : (
                    bankAccountDetails?.accountNumber
                  )}
                </div>
              </Col>
              <Col span={6}>
                <div>
                  <span
                    style={{
                      paddingRight: "10px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    IFSC Code:
                  </span>
                  {bankAccountDetails?.bankdetail?.ifsCode!}
                  {/* {bankAccountDetails?.ifsccode} */}
                </div>
                <div>
                  <span
                    style={{
                      paddingRight: "16px",
                      color: "rgb(139, 143, 165)",
                    }}
                  >
                    Bank Name:
                  </span>
                  {bankAccountDetails?.bankdetail?.bankName!}
                  {/* {bankAccountDetails?.bankname} */}
                </div>
              </Col>

              <Col span={5} style={{ display: "flex", justifyContent: "end" }}>
                <div style={{ paddingTop: "6px" }}>
                  {/* <Dropdown
                    menu={{ items }}
                    placement="bottomLeft"
                    trigger={["click"]}
                  >
                    <Button type="primary">
                      Deposit / Withdraw
                      <DownOutlined />
                    </Button>
                  </Dropdown> */}
                  {/* <Tooltip
                    title={
                      <>
                        Add Transfer Entry
                        <br />
                        <Tag>
                          <kbd>Shift + F4</kbd>
                        </Tag>
                      </>
                    }
                  > */}
                  <Button
                    onClick={() => {
                      setOpenModel({
                        type: "adjustBankBalance",
                        param: { id: "", type: AdjustBankType.undefined },
                      });
                    }}
                    type="primary"
                  >
                    Deposit / Withdrawal
                  </Button>
                  {/* </Tooltip> */}
                </div>
              </Col>
            </Row>
          </Card>

          <Card
            className="ca-card-body"
            style={{ boxShadow: "0px 0px 10px 0px #96A1C84D" }}
          >
            <div
              style={{
                height: `calc(100vh - 216px)`,
                // backgroundColor: "azure",
                overflowY: "auto",
              }}
            >
              {bank.id && (
                <VoucherDetailsByLedger
                  refresh={refresh}
                  ledgerId={bank?.id}
                  partyOrTax={bank?.partyOrTax}
                  ledgerName={bank?.name}
                />
              )}
            </div>
          </Card>
        </Col>
      </Row>

      {/* -------------ADD Bank Account ----------*/}
      {openModel.type === "addBankAccount" && (
        <AddBankAccountModel
          open={openModel.type === "addBankAccount"}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          bankAccountTrigger={() => setBankTrigger(!bankTrigger)}
          id={openModel.param?.id}
          curentBankAccountId={bank.id}
          allBankAccount={bankList}
        />
      )}
      {openModel.type === "adjustBankBalance" && (
        <AdjustBankBalanceModel
          open={true}
          onCancel={() => setOpenModel({ type: "", param: "" })}
          // triggerBankTransactions={() =>
          //   setTriggerBankTransactions(!triggerBankTransactions)
          // }
          setRefresh={setRefresh}
          type={openModel.param?.type}
          allBankAccount={bankList}
          bank={bank}
          id={openModel.param?.id}
        />
      )}

      {confirmationDeleted && (
        <ConfirmationModal
          open={confirmationDeleted}
          onNo={() => setConfirmationDelete(false)}
          onYes={async () =>
            await LedgerService.deleteLedgerMaster(currentClient?.id!, id)
              .then((res: any) => {
                if (res.result === true) {
                  console.log("res", res);
                  setTrigger((x: boolean) => !x);
                  notification.success({
                    message: "",
                    description: "Deleted successfully.",
                    placement: "topRight",
                  });
                  setConfirmationDelete(false);
                } else if (res?.message) {
                  debugger;
                  notification.error({
                    message: "",
                    description: res?.message || "Error.",
                    placement: "topRight",
                  });
                  setConfirmationDelete(false);
                }
              })
              .catch((err: any) => {
                openNotification("error", err);
                // setConfirmationDelete(false);
                console.log("error", err);
              })
          }
          text={`Are you sure you want to Delete Ledger "${AccName}?`}
          loading={false}
        />
      )}
      {/* {openModel.type === TransactionTypes.Receipt && (
        <ViewPaymentIn
          open={viewModal.type === TransactionTypes.Receipt}
          onCancel={() => setViewModal({ type: undefined, value: undefined })}
          paymentId={viewModal.value}
        />
      )}
      {openModel.type === TransactionTypes.Payment && (
        <ViewPaymentIOut
          open={viewModal.type === TransactionTypes.Payment}
          onCancel={() => setViewModal({ type: undefined, value: undefined })}
          paymentId={viewModal.value}
        />
      )} */}
    </div>
  );
};

export default BankAccountsAllEffected;
