import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Spin,
} from "antd";
import React, { useState } from "react";
import VoucherServices, { Voucher_Type } from "../../Services/VoucherServices";
import { dayjs } from "../../../Utilities/dayjs";
import { useClient } from "../../Context/ClientContext";
import { notificationContext } from "../../Common/PageRoute";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const AddJornalBadDebts: React.FC<{
  open: boolean;
  onCancel: (value: React.SetStateAction<string>) => void;
  voucherId: string;
  partyId: string;
  amount: number;
  type?: number;
  apiData?: any;
}> = (props) => {
  const { currentClient, companySettings } = useClient();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dateFiled, setDateFiled] = React.useState<any>();
  const { openNotification } = React.useContext(notificationContext);

  // Date Api
  React.useEffect(() => {
    const getDate = async () => {
      if (currentClient?.id) {
        await VoucherServices.getMaxVoucherDate(
          currentClient?.id!,
          Voucher_Type.Journal
        ).then((res) => {
          const givenDate = new Date(res?.result);
          const currentTime = new Date();

          // Set the time part of givenDate to match currentTime
          givenDate?.setHours(
            currentTime.getHours(),
            currentTime.getMinutes(),
            currentTime.getSeconds(),
            currentTime.getMilliseconds()
          );
          form.setFieldValue("date", dayjs(givenDate));

          setDateFiled(res.result);
        });
      }
    };
    getDate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  const getVoucherTitle = companySettings?.setting?.vouchersSettings.find(
    (x: any) => x?.title === "JOURNAL"
  );

  // ----- initial value -----
  React.useEffect(() => {
    if (currentClient?.id && dateFiled) {
      setLoading(true);
      VoucherServices.getVoucherMaxNo(
        currentClient?.id!,
        Voucher_Type.Journal,
        dayjs(dateFiled).endOf("day").toISOString(),
        getVoucherTitle?.title!
      )
        .then((res) => {
          form.setFieldValue("number", res?.result);

          setLoading(false);
        })
        .catch((err: any) => {
          openNotification("error", err.message);
          console.error(err.message);
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id, dateFiled]);

  //   React.useEffect(() => {
  //     if (props?.id) {
  //       form.setFieldsValue({
  //         name: props.name,
  //         rate: props.rate,
  //         abbreviation: props.abbreviation,
  //       });
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [props?.id]);

  // --------- Add & Edit Post Api ---------
  //   const addBadDebts = () => {
  //     form.validateFields().then(async (value: any) => {
  //       const PostGstData = {
  //         ...value,
  //         isActive: true,
  //         isEdit: true,
  //       };
  //       await ClientService.postGst(PostGstData, props?.id)
  //         .then((res: any) => {
  //           if (res?.result) {
  //             // console.log(res?.result);
  //             props.notification(
  //               "success",
  //               `${props?.id ? " Edit" : "Add"} GST Add Successfully`
  //             );
  //             props.onCancel();
  //             props.setTrigger((x: boolean) => !x);
  //           }
  //         })
  //         .catch((error: any) => props.notification("error", error));
  //     });
  //   };

  return (
    <div>
      <Modal
        open={props.open}
        title="Bad Debts"
        width={450}
        maskClosable={false}
        // closable={false}
        footer={
          <div style={{ marginTop: "0px" }}>
            <Button
              onClick={() => {
                form.resetFields();
                props.onCancel("");
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              //   onClick={() => addBadDebts()}
              className="modelSaveButtonMargin"
            >
              Save
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          name="purchases-item"
          autoCapitalize="false"
          requiredMark={false}
          disabled={false}
          colon={false}
          labelAlign="left"
          labelCol={{ flex: "110px" }}
        >
          <Divider className="ca-model-css" />
          <Spin indicator={antIcon} spinning={loading}>
            <Form.Item
              name="number"
              label="Journal no."
              rules={[{ required: true, message: "Name is required" }]}
              className="input-item"
            >
              <Input />
            </Form.Item>

            <Form.Item name="date" label="Date" className="input-item">
              <DatePicker format="DD/MM/YYYY" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item name="amount" label="Amount" className="input-item">
              <InputNumber controls={false} style={{ width: "100%" }} />
            </Form.Item>

            <Divider className="divider-purchase" />
          </Spin>
        </Form>
      </Modal>
    </div>
  );
};

export default AddJornalBadDebts;
