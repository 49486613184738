import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  notification,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import React from "react";
import AddCustomer from "./AddCustomer";
import { ColumnsType } from "antd/es/table";
import { useTheme } from "../../Context/ThemeContext";
import CustomerService from "../../Services/CustomerService";
import { DBRecordStatus } from "../../Services/SalesService";
import Search from "antd/es/transfer/search";
import { ApplicationUserStatus } from "../../Services/ProductService";
import { ConfirmationModal } from "../../Common/ConfirmationModal";

const Customer = () => {
  const { themeData } = useTheme();
  const [isModelOpen, setIsModelOpen] = React.useState<boolean>(false);
  const [refresh, setRefresh] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [customerData, setCustomerData] = React.useState<any[]>();
  const [page, setPage] = React.useState(1);
  const [id, setId] = React.useState<string | undefined>();
  const [selectType, setSelectType] = React.useState<ApplicationUserStatus>(
    ApplicationUserStatus?.Active
  );
  const [search, setSearch] = React.useState<string>("");
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [totalRecords, setTotalRecords] = React.useState<number>(0);
  const [confirmation, setConfirmation] = React.useState<boolean>(false);

  React.useEffect(() => {
    getCustomerData();
  }, [refresh, selectType, search, page, pageSize]);
  const getCustomerData = async () => {
    setLoading(true);
    let res = await CustomerService?.getCustomerDetails(
      (page - 1) * pageSize,
      pageSize,
      search,
      selectType
    );
    if (res?.result) {
      setCustomerData(
        res?.result?.map((c: any, index: number) => {
          return {
            id: c?.id,
            name: c?.customerName?.last
              ? c?.customerName?.first + " " + c?.customerName?.last
              : c?.customerName?.first,
            email: c?.email,
            gst: c?.gstin !== null ? c?.gstin : c?.pan,
            productName: c?.products,
            customerType: c?.customerType === 0 ? "Company" : "Practitioner",
            person: c?.contactDetail?.personName,
            phone: c?.contactDetail?.phone,
          };
        })
      );
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const deleteCusomerDataById = async () => {
    let res = await CustomerService?.deleteCustomerDetailsById(id);
    if (res?.status) {
      getCustomerData();
      notification.success({
        message: "Success",
        description: "Deleted Successfully",
      });
    } else {
      notification.error({
        message: "Error",
        description: res?.message,
      });
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "25%",
    },
    {
      title: "GST / PAN",
      dataIndex: "gst",
    },
    {
      title: "Person Name",
      dataIndex: "person",
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      render: (_: any, record: any) => (
        <>
          {record?.productName?.map((p: any, index: number) => (
            <div key={index}>{p?.name}</div>
          ))}
        </>
      ),
    },

    {
      title: "Customer Type",
      dataIndex: "customerType",
    },
    {
      title: "Action",
      dataIndex: "",
      width: "10%",
      render: (_: any, record: any) => (
        <>
          <Row gutter={14}>
            <Col>
              <EditOutlined
                onClick={() => {
                  setId(record?.id);
                  setIsModelOpen(true);
                }}
              />
            </Col>
            <Col>
              <DeleteOutlined
                style={{ color: "red" }}
                onClick={() => {
                  setId(record?.id);
                  setConfirmation(true);
                }}
              />
            </Col>
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <div>
        <Card
          size="small"
          className="TableCard"
          style={{
            margin: "15px",
            height: "100%",
            border: "1px solid #eef2fe",
          }}
        >
          <Spin
            spinning={loading}
            indicator={<LoadingOutlined />}
            style={{ marginTop: "40px" }}
          >
            <div style={{ padding: "5px 10px" }}>
              <Row gutter={25}>
                <Col>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setId("");
                      setIsModelOpen(true);
                    }}
                  >
                    Customer
                  </Button>
                </Col>
                <Col>
                  <Select
                    style={{ width: "200px" }}
                    placeholder="Select Type..."
                    onChange={(val: any) => setSelectType(val)}
                    options={[
                      {
                        value: ApplicationUserStatus?.Active,
                        label: "Active",
                      },
                      {
                        value: ApplicationUserStatus?.Inactive,
                        label: "In-Active",
                      },
                      {
                        value: ApplicationUserStatus?.Deleted,
                        label: "Deleted",
                      },
                      {
                        value: ApplicationUserStatus?.All,
                        label: "All",
                      },
                    ]}
                  />
                </Col>
                <Col>
                  <Search
                    placeholder="Search here..."
                    onChange={(e: any) => setSearch(e?.target?.value)}
                  />
                </Col>
              </Row>

              <div style={{ marginTop: "15px" }}>
                <Table
                  columns={columns}
                  className={`Tabel-style table-${
                    themeData?.componentSize ?? "middle"
                  }`}
                  dataSource={customerData}
                  pagination={{
                    total: totalRecords,
                    current: page,
                    pageSize: pageSize,
                    showSizeChanger: true,
                    onChange: (page: number, pageSize: number) => {
                      setPage(page);
                      setPageSize(pageSize);
                    },
                    pageSizeOptions: ["10", "15", "25", "50", "100"],
                    showTotal: (totalRecords, page) =>
                      `${page[0]}-${page[1]} of ${totalRecords} items`,
                  }}
                />
              </div>
            </div>
          </Spin>
        </Card>
      </div>

      {isModelOpen && (
        <AddCustomer
          onCancel={() => setIsModelOpen(false)}
          isModelOpen={isModelOpen}
          setRefresh={setRefresh}
          id={id}
        />
      )}

      {confirmation && (
        <ConfirmationModal
          open={confirmation}
          onNo={() => setConfirmation(false)}
          onYes={() => {
            deleteCusomerDataById();
            setConfirmation(false);
          }}
          text={"Are you sure you want to delete this data "}
        />
      )}
    </>
  );
};

export default Customer;
