import { Button, Col, Divider, Modal, Row, Spin } from "antd";
import React, { useContext } from "react";
import FixedAssetsService from "../../Services/FixedAssetsService";
import { useClient } from "../../Context/ClientContext";
import { Utils } from "../../../Utilities/Utils";
import "./Assets.css";
import { LoadingOutlined } from "@ant-design/icons";
import { CalcMethod, PmtMethod } from "./AssetModel";
import { notificationContext } from "../../Common/PageRoute";
import FixedAssetsServiceNew from "../../Services/FixedAssetsServiceNew";
import { dayjs } from "../../../Utilities/dayjs";
import ChartofAccountServicesNew from "../../Services/ChartofAccountServicesNew";
import LedgerService, {
  SearchLedgerByGroupsTypes,
} from "../../Services/LedgerService";
import { dep } from "./Depreciation";

interface Modelprops {
      isViewModelOpen: boolean;
  onCancel: any;
  assetId: string | undefined;
  type?: boolean;
}
const ViewAssetModel = ({
  isViewModelOpen,
  onCancel,
  assetId,
  type,
}: Modelprops) => {
  const { currentClient } = useClient();
  const { openNotification } = useContext(notificationContext);
  const [data, setData] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isGstEnabled, setIsGstEnabled] = React.useState<boolean>();
  const [depreciable, setDepreciable] = React.useState<boolean>(false);
  const [fixedAssetsGroups, setFixedAssetsGroups] = React.useState<any>([]);
  const [payLedgers, setPayLedgers] = React.useState<any>([]);

  console.log("assestId", assetId);

  // React.useEffect(() => {
  //   if (assetId) {
  //     setLoading(true);
  //     FixedAssetsService.getFixedAsset(currentClient?.id!, false, assetId)
  //       .then((res: any) => {
  //         if (res.status) {
  //           const ass = res?.result?.asset;
  //           setIsGstEnabled(res?.result?.isGstEnabled);
  //           setDepreciable(ass?.isDepreciable);
  //           setData({
  //             name: ass?.name,
  //             number: ass?.number,
  //             date: Utils.getFormattedDate(ass?.date),
  //             account: ass?.account.name,
  //             price: Utils.getFormattedCurrency(ass?.price),
  //             startDate: Utils.getFormattedDate(ass?.depreciationStartDate),
  //             accumulatedDep: Utils.getFormattedCurrency(
  //               ass?.accumulatedDepreciation
  //             ),
  //             accountType: ass?.accountGroupType,
  //             bankAccount: ass?.bankAccount?.name,
  //             paymentMethod: ass?.creditor?.paymentMethod,
  //             refrence: ass?.creditor?.refrence?.name,
  //             gst: ass?.creditor?.gst?.name,
  //             description: ass?.description,
  //             method: ass?.method,
  //             rate: ass?.rate,
  //             year: ass?.year,
  //           });
  //           setLoading(false);
  //         } else setLoading(false);
  //       })
  //       .catch((err: any) => {
  //         openNotification("error", err);
  //         setLoading(false);
  //       });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [assetId]);

  React?.useEffect(() => {
    setLoading(true);
    FixedAssetsServiceNew.getFixedAsset(currentClient?.id!, assetId, type)
      .then((res) => {
        if (res.status) {
          const ass = res?.result;
          setData(res?.result);
          const asset = {
            ...ass,
            invoiceDetail: {
              ...ass?.invoiceDetail,
              date: dayjs(ass?.invoiceDetail?.date),
              invNumber: ass?.invoiceDetail?.invNumber,
              //supplier: ass?.invoiceDetail?.supplier,
              //"qty": 0,
              price: ass?.invoiceDetail?.price,
              totalValue: ass?.invoiceDetail?.price,
              voucherDate: dayjs(ass?.invoiceDetail?.voucherDate),
              purPayDate: dayjs(ass?.invoiceDetail?.purPayDate),
            },
            //   gst:
            //     ass?.gst !== null
            //       ? {
            //         key: ass?.gst?.rate,
            //         label: ass?.gst?.name,
            //         value: ass?.gst?.id,
            //       }
            //       : null,
            //   taxCredit: ass?.taxCredit === true ? 1 : 0,
            //   location: Utils.getNumber(ass?.location),
            //   depreciationStartDate: dayjs(ass?.depreciationStartDate),
            //   warrantyExpDate: dayjs(ass?.warrantyExpDate),
            //   // date: dayjs(ass.date),
            //   // account: {
            //   //   label: ass?.account?.name,
            //   //   value: ass?.account?.id,
            //   // },
            //   // price: Utils.getFormattedNumber(ass?.price),
            //   // startDate: dayjs(ass?.depreciationStartDate),
            //   // accumulatedDep: Utils.getFormattedNumber(
            //   //   ass?.accumulatedDepreciation
            //   // ),
            //   // bankAccount: {
            //   //   label: ass?.bankAccount?.name,
            //   //   value: ass?.bankAccount?.id,
            //   // },
            //   // creditor: {
            //   //   ...ass?.creditor,
            //   //   refrence: {
            //   //     label: ass?.creditor?.refrence?.name,
            //   //     value: ass?.creditor?.refrence?.id,
            //   //   },
            //   //   gst: {
            //   //     key: ass?.creditor?.gst?.rate,
            //   //     label: ass?.creditor?.gst?.name,
            //   //     value: ass?.creditor?.gst?.id,
            //   //   },
            //   // },
            // };
            // setPaymentMode(
            //   ass?.payMethod === 0
            //     ? PmtMethod.bank
            //     : ass?.payMethod === 1
            //       ? PmtMethod.cash
            //       : PmtMethod.party
            // );
            // setDepMethod(
            //   ass?.depMethod === 0
            //     ? CalcMethod.WrittenDownValue
            //     : CalcMethod.StraightLine
            // );
            // setResidualValue(ass?.residualValue);
            // if (editAsset?.id) {
            //   console.log("Edit Record: ", asset);
            //   onPaymentModeChange(asset.payMethod);
            //   form.setFieldsValue(asset);
            //   //setLocation(ass?.location === "1" ? 1 : 2);
            //   //setPaymentMode(ass?.payMode);
            // } else {
            //   form.setFieldValue("number", ass?.number);
            // }
            // setDepreciable(ass?.isDepreciable);
            //setIsGstEnabled(res?.result?.isGstEnabled);
            // if (res?.result?.fixedAssetAccounts) {
            //   let acc: ISelectGroup[] = res?.result?.fixedAssetAccounts?.map(
            //     (group: any) => ({
            //       label: `${group.typeName} (${group.groupName})`,
            //       options: group.accounts?.map((a: AccountShort) => ({
            //         label: a.name,
            //         value: a.id,
            //       })),
            //     })
            //   );
            //   setAccounts([...acc]);
          };
          setLoading(false);
        } else setLoading(false);
      })
      .catch((ex) => {
        openNotification("error", ex.message);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  React.useEffect(() => {
    const getGroupFixedAssets = async (clientId: String) => {
      if (clientId) {
        await ChartofAccountServicesNew.getGroupList(
          currentClient?.id!,
          currentClient?.productModule,
          "",
          "",
          "",
          "",
          SearchLedgerByGroupsTypes.FixedAssets
        )
          .then((res: any) => {
            if (res) {
              setFixedAssetsGroups(res?.items);
              console.log("groupss", fixedAssetsGroups);
            }
          })
          .catch((err: any) => {
            console.error("error......", err?.message);
          });
      }

      LedgerService.getLedgersByGroupName(
        currentClient?.id!,
        SearchLedgerByGroupsTypes?.All
      ).then((res: any) => {
        setPayLedgers(res);
      });
    };
    getGroupFixedAssets(currentClient?.id!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient?.id]);

  const getGroupLabel = (id: any) => {
    const group = fixedAssetsGroups?.find((group: any) => group?.id === id);
    return group ? group?.group_Name : id;
  };

  const getLedgerLabel = (id: any) => {
    const ledger = payLedgers?.find((ledger: any) => ledger?.id === id);
    return ledger ? ledger?.ledgerName : id;
  };

  console.log("kkkk", payLedgers);
  const GetCategoryList = (DepList: any[]) => {
    return DepList?.map((itm: any) => {
      const list: any = {
        key: itm.Id,
        value: itm.Id,
        title: itm.Title,
      };
      if (itm.Children.length > 0) {
        list.children = GetCategoryList([...itm.Children]);
      }
      return list;
    });
  };
  const AssetCategoryOptions = GetCategoryList(dep);

  const categoryList = (CategoryOptions: any[], Id: any) => {
    const category: any = CategoryOptions?.map((catg: any) => {
      let res = "";
      if (catg?.key === Id) {
        res = catg?.title;
      } else {
        if (catg?.children?.length > 0) {
          res = categoryList(catg?.children, Id);
        }
      }
      return res;
    });
    return category ? category : Id;
  };

  return (
    <div>
      <Modal
        width={1000}
        title={"View Fixed Asset"}
        open={isViewModelOpen}
        onCancel={() => onCancel(false)}
        maskClosable={false}
        footer={
          <>
            <Button onClick={() => onCancel(false)}>Cancel</Button>
          </>
        }
        className="modelpadding"
      >
        <div style={{ maxHeight: "65vh", overflowY: "auto" }}>
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            spinning={loading}
          >
            <div className="inputboxmb">
              {/* <Row>
                <Col lg={8} md={8}>
                  <span className=" fontcolor  fontcolor fw-500">
                    Asset Name
                  </span>
                  <div>{data?.name}</div>
                </Col>
                <Col lg={8} md={8}>
                  <span className=" fontcolor fw-500">Asset Reference</span>
                  <div>{data?.number}</div>
                </Col> */}
              {/* <Col lg={8} md={8}>
                  <span className=" fontcolor fw-500">Purchase Date</span>
                  <div>{data?.date}</div>
                </Col> */}
              {/* </Row> */}
              {/* <Row style={{ paddingTop: "5px" }}>
                <Col lg={8} md={8}>
                  <span className="fontcolor">Identity No.
                  </span>
                  <div>{data?.identityCode
                  }</div>
                </Col>
                <Col lg={8} md={8}>
                  <span className=" fontcolor fw-500">Model</span>
                  <div>{data?.model}</div>
                </Col>
                <Col lg={8} md={8}>
                  <span className=" fontcolor fw-500">Notes</span>
                  <div>{data?.notes}</div>
                </Col>
              </Row> */}

              {/* <Row style={{ paddingTop: "5px" }}>
                <Col lg={8} md={8}>
                  <span className="fontcolor">Asset Group
                  </span>
                  <div>{getGroupLabel(data?.group)}</div>
                </Col>
                <Col lg={14} md={14}>
                  <span className=" fontcolor fw-500">Description</span>
                  <div>{data.description}</div>
                </Col>
              </Row>
              <Divider className="botomline"
              />
              <p style={{ margin: "5px 0px", fontWeight: "700" }}>Purchase Detail
              </p> */}
              {/* <Row style={{ paddingTop: "5px" }}>
                <Col lg={8} md={8}>
                  <span className=" fontcolor fw-500">PaymentMethod</span>
                  <div>
                    {data.paymentMethod === PmtMethod.bank
                      ? "Bank"
                      : data.paymentMethod === PmtMethod.cash
                        ? "Cash"
                        : "Suppliers"}
                  </div>
                </Col>
                <Col lg={8} md={8}>
                  <span className=" fontcolor fw-500">Suppliers</span>
                  <div>{data?.refrence}</div>
                </Col>
                {data.paymentMethod === PmtMethod.bank && (
                  <Col lg={8} md={8}>
                    <span className=" fontcolor fw-500">Bank</span>
                    <div>{data?.bankAccount}</div>
                  </Col>
                )}
              </Row> */}
              {/* {isGstEnabled && (
                <Row>
                  <Col lg={8} md={8}>
                    <span className="fontcolor fw-500">Gst</span>
                    <div>{data?.gst}</div>
                  </Col>
                </Row>
              )} */}
              {/* <Row style={{ paddingTop: "5px" }}>
                <Col lg={4} md={4}>
                  <span className=" fontcolor fw-500">Purchase Date
                  </span>
                  <div>{dayjs(data?.invoiceDetail?.date).format("DD/MM/YYYY")}</div>
                </Col>
                <Col lg={4} md={4}>
                  <span className=" fontcolor fw-500">Invoice No.
                  </span>
                  <div>{data?.invoiceDetail?.invNumber}</div>
                </Col>
                <Col lg={4} md={4}>
                  <span className=" fontcolor fw-500">Tax Credit</span>
                  <div>{data?.taxCredit === true ? "Available" : "Not Available"}</div>
                </Col>
                <Col lg={4} md={4}>
                  <span className=" fontcolor fw-500">Purchase Price
                  </span>
                  <div>{data?.invoiceDetail?.price}</div>
                </Col>
                <Col lg={4} md={4}>
                  <span className=" fontcolor fw-500">GST
                  </span>
                  <div>{data?.gst?.name}</div>
                </Col>
                <Col lg={4} md={4}>
                  <span className=" fontcolor fw-500">
                    Voucher Date
                  </span>
                  <div>{dayjs(data?.invoiceDetail?.voucherDate).format("DD/MM/YYYY")}</div>
                </Col>
              </Row>
              <Divider className="botomline"

              /> */}

              {/* <p style={{ margin: "5px 0px", fontWeight: "700" }}>Payment Given Detail

              </p>
              <Row style={{ paddingTop: "5px" }}>
                <Col lg={4} md={4}>
                  <span className=" fontcolor fw-500">Payment Mode

                  </span>
                  <div>{data?.payMethod === 0 ? "Bank" : data?.payMethod === 1 ? "Cash" : "Party"}</div>
                </Col>
                <Col lg={4} md={4}>
                  <span className=" fontcolor fw-500">Payment Ledger</span>
                  <div>{getLedgerLabel(data?.paymentLedger)}</div>
                </Col>
                <Col lg={4} md={4}>
                  <span className=" fontcolor fw-500">Payment Reference
                  </span>
                  <div>{data?.invoiceDetail?.purPayRef}</div>
                </Col>
                <Col lg={4} md={4}>
                  <span className=" fontcolor fw-500">
                    Payment Date
                  </span>
                  <div>{dayjs(data?.invoiceDetail?.purPayDate).format("DD/MM/YYYY")}</div>
                </Col>
              </Row> */}

              {/* <Divider className="botomline"
              />
              <p style={{ margin: "5px 0px", fontWeight: "700" }}>Depreciation Detail </p>
              <Row style={{ paddingTop: "5px" }}>
                <Col lg={8} md={8}>
                  <span className=" fontcolor fw-500">Asset Category</span>
                  <div>{data?.category}</div>
                </Col>

              </Row>

              <Row style={{ paddingTop: "5px" }}>
                <Col lg={4} md={4}>
                  <span className=" fontcolor fw-500">Depreciation Method
                  </span>
                  <div>{data?.depMethod === 1 ? "Straight Line Method" : "Written Down Method"}</div>
                </Col>
                <Col lg={4} md={4}>
                  <span className=" fontcolor fw-500">Asset Life in year
                  </span>
                  <div>{data?.lifeCycleDuration}</div>
                </Col>
                <Col lg={4} md={4}>
                  <span className=" fontcolor fw-500">Depreciation Rate
                  </span>
                  <div>{data?.depRate}</div>
                </Col>
                <Col lg={4} md={4}>
                  <span className=" fontcolor fw-500">Depreciation Start Date
                  </span>
                  <div>{dayjs(data?.depreciationStartDate).format("DD/MM/YYYY")}</div>
                </Col>
              </Row>

              <Divider className="botomline"
              /> */}

              {/* <p style={{ margin: "5px 0px", fontWeight: "700" }}>Other Detail
              </p>
              <Row style={{ paddingTop: "5px" }}>
                <Col lg={8} md={8}>
                  <span className=" fontcolor fw-500">Asset Location

                  </span>
                  <div>{data?.location === "1" ? "Office" : "Plant"}</div>
                </Col>
                <Col lg={8} md={8}>
                  <span className=" fontcolor fw-500">
                    Residual Value of Asset

                  </span>
                  <div>{data?.residualValue}</div>
                </Col>
                <Col lg={8} md={8}>
                  <span className=" fontcolor fw-500">
                    Warranty / Guarantee Expiry Date


                  </span>
                  <div>{dayjs(data?.warrantyExpDate).format("DD/MM/YYYY")}</div>
                </Col>
              </Row> */}

              <div>
                <table
                  className="ca-viewmodel-table"
                  style={{
                    width: "100%",

                    borderCollapse: "collapse",
                    margin: "30px auto",
                  }}
                >
                  <tbody>
                    <tr>
                      <td colSpan={4} align="left">
                        <strong>
                          <u>Asset Detail</u>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Asset Name
                      </td>
                      <td style={{ width: "30%" }}>{data?.name}</td>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Asset Reference
                      </td>
                      <td style={{ width: "30%" }}>{data?.number}</td>
                    </tr>
                    <tr>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Identity No.
                      </td>
                      <td style={{ width: "30%" }}>{data?.identityCode}</td>

                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Model
                      </td>
                      <td style={{ width: "30%" }}>{data?.model}</td>
                    </tr>

                    <tr>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Notes
                      </td>
                      <td style={{ width: "30%" }}>{data?.notes}</td>

                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Assest Group
                      </td>
                      <td style={{ width: "30%" }}>
                        {getGroupLabel(data?.group)}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Description
                      </td>
                      <td align="left" colSpan={4} style={{ width: "30%" }}>
                        {data?.description}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4} align="left">
                        <strong>
                          <u>Purchase Detail</u>
                        </strong>
                      </td>
                    </tr>

                    <tr>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Purchase Date
                      </td>
                      <td style={{ width: "30%" }}>
                        {dayjs(data?.invoiceDetail?.date).format("DD/MM/YYYY")}
                      </td>

                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Invoice No.
                      </td>
                      <td style={{ width: "30%" }}>
                        {data?.invoiceDetail?.invNumber}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Tax Credit
                      </td>
                      <td style={{ width: "30%" }}>
                        {data?.taxCredit === true
                          ? "Available"
                          : "Not Available"}
                      </td>

                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Purchase Price
                      </td>
                      <td style={{ width: "30%" }}>
                        {Utils?.getFormattedNumber(data?.invoiceDetail?.price)}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        GST
                      </td>
                      <td style={{ width: "30%" }}>{data?.gst?.name}</td>

                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Voucher Date
                      </td>
                      <td style={{ width: "30%" }}>
                        {dayjs(data?.invoiceDetail?.voucherDate).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={4} align="left">
                        <strong>
                          <u>Payment Given Detail</u>
                        </strong>
                      </td>
                    </tr>

                    <tr>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Payment Mode
                      </td>
                      <td style={{ width: "30%" }}>
                        {data?.payMethod === 0
                          ? "Bank"
                          : data?.payMethod === 1
                          ? "Cash"
                          : "Party"}
                      </td>

                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Payment Ledger
                      </td>
                      <td style={{ width: "30%" }}>
                        {getLedgerLabel(data?.paymentLedger)}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Payment Reference
                      </td>
                      <td style={{ width: "30%" }}>
                        {data?.invoiceDetail?.purPayRef}
                      </td>

                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Payment Date
                      </td>
                      <td style={{ width: "30%" }}>
                        {dayjs(data?.invoiceDetail?.purPayDate).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={4} align="left">
                        <strong>
                          <u>Depreciation Detail</u>
                        </strong>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Asset Category
                      </td>
                      <td style={{ width: "30%" }} colSpan={4}>
                        {categoryList(AssetCategoryOptions, data?.category)}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Depreciation Method
                      </td>
                      <td style={{ width: "30%" }}>
                        {data?.depMethod === 1
                          ? "Straight Line Method"
                          : "Written Down Method"}
                      </td>

                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Asset Life in year
                      </td>
                      <td style={{ width: "30%" }}>
                        {data?.lifeCycleDuration}
                      </td>
                    </tr>

                    <tr>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Depreciation Rate
                      </td>
                      <td style={{ width: "30%" }}>{data?.depRate}</td>

                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Depreciation Start Date
                      </td>
                      <td style={{ width: "30%" }}>
                        {dayjs(data?.depreciationStartDate).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td colSpan={4} align="left">
                        <strong>
                          <u>Other Detail</u>
                        </strong>
                      </td>
                    </tr>

                    <tr>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Asset Location
                      </td>
                      <td style={{ width: "30%" }}>
                        {data?.location === "1" ? "Office" : "Plant"}
                      </td>

                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Residual Value of Asset
                      </td>
                      <td style={{ width: "30%" }}>
                        {Utils?.getFormattedNumber(data?.residualValue)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "20%", background: "#f6f6f6" }}>
                        Warranty / Guarantee Expiry Date
                      </td>
                      <td align="left" colSpan={3} style={{ width: "30%" }}>
                        {dayjs(data?.warrantyExpDate).format("DD/MM/YYYY")}
                      </td>
                    </tr>

                    {type === true && (
                      <>
                        <tr>
                          <td colSpan={4} align="left">
                            <strong>
                              <u>Disposed Details</u>
                            </strong>
                          </td>
                        </tr>

                        <tr>
                          <td style={{ width: "20%", background: "#f6f6f6" }}>
                            Disposal Date
                          </td>
                          <td style={{ width: "30%" }}>
                            {dayjs(data?.disposalDate).format("DD/MM/YYYY")}
                          </td>

                          <td style={{ width: "20%", background: "#f6f6f6" }}>
                            Disposal Payment Ref
                          </td>
                          <td style={{ width: "30%" }}>
                            {data?.disposalPaymentRef}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "20%", background: "#f6f6f6" }}>
                            Disposal Value
                          </td>
                          <td align="left" style={{ width: "30%" }}>
                            {Utils?.getFormattedNumber(data?.disposalValue)}
                          </td>
                          <td style={{ width: "20%", background: "#f6f6f6" }}>
                            Pay Date
                          </td>
                          <td style={{ width: "30%" }}>
                            {dayjs(data?.payDate).format("DD/MM/YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "20%", background: "#f6f6f6" }}>
                            Disposal Ledger
                          </td>
                          <td>{getLedgerLabel(data?.disposalLedger)}</td>
                        </tr>
                      </>
                    )}

                    {/* <tr>
                      <td>Notes</td>
                      <td>Assest Group</td>
                    </tr>
                    <tr>
                      <td>{data?.notes}</td>
                      <td>{getGroupLabel(data?.group)}</td>
                    </tr>
                    <tr>
                      <td>Description</td>
                      <td>Purchase Date</td>
                    </tr>
                    <tr><td>{data?.description}</td>
                      <td>{dayjs(data?.invoiceDetail?.date).format("DD/MM/YYYY")}</td>
                    </tr>

                    <tr>
                      <td>Invoice No.
                      </td>
                      <td>Tax Credit
                      </td>
                    </tr>
                    <tr>
                      <td>{data?.invoiceDetail?.invNumber}</td>
                      <td>{data?.taxCredit === true ? "Available" : "Not Available"}</td>
                    </tr>
                    <tr>
                      <td>
                        Purchase Price

                      </td>
                      <td>GST
                      </td>
                    </tr>
                    <tr>
                      <td>{data?.invoiceDetail?.price}</td>
                      <td>{data?.gst?.name}</td>
                    </tr>
                    <tr>
                      <td>Voucher Date</td>
                      <td>Payment Mode</td>
                    </tr>
                    <tr>
                      <td>{dayjs(data?.invoiceDetail?.voucherDate).format("DD/MM/YYYY")}</td>
                      <td>{data?.payMethod === 0 ? "Bank" : data?.payMethod === 1 ? "Cash" : "Party"}</td>
                    </tr>
                    <tr>
                      <td>Payment Ledger
                      </td>
                      <td>Payment Reference
                      </td>
                    </tr>
                    <tr>
                      <td>{getLedgerLabel(data?.paymentLedger)}</td>
                      <td>{data?.invoiceDetail?.purPayRef}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>Asset Category</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>{data?.category}</td>
                    </tr>
                    <tr>
                      <td>Payment Date
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>{dayjs(data?.invoiceDetail?.purPayDate).format("DD/MM/YYYY")}</td>
                      <td></td>
                    </tr> */}
                  </tbody>
                </table>
              </div>

              {/* {depreciable ? (
                <>
                  <Row style={{ paddingTop: "3px" }}>
                    <b>Depreciation</b>
                  </Row>
                  <Row style={{ paddingTop: "5px" }}>
                    <Col lg={9} md={9}>
                      <span className=" fontcolor fw-500">
                        P&L account type
                      </span>
                      <div>
                        {data?.accountType === PnLAccountGroups.CostofSales
                          ? "Cost of Sales"
                          : data?.accountType ===
                            PnLAccountGroups.SellingAndDistributionCosts
                            ? "Selling and distribution costs"
                            : data?.accountType ===
                              PnLAccountGroups.AdministrativeExpenses
                              ? "Administrative expenses"
                              : ""}
                      </div>
                    </Col>
                    <Col lg={8} md={8}>
                      <span className=" fontcolor fw-500">Method</span>
                      <div>
                        {data?.method === CalcMethod.WrittenDownValue
                          ? "Written Down value"
                          : data?.method === CalcMethod.StraightLine
                            ? "Straight Line"
                            : ""}
                      </div>
                    </Col>
                    {data?.method === CalcMethod.WrittenDownValue && (
                      <Col lg={7} md={7}>
                        <span className=" fontcolor fw-500">Rate</span>
                        <div>{data.rate}</div>
                      </Col>
                    )}
                    {data?.method === CalcMethod.StraightLine && (
                      <Col lg={7} md={7}>
                        <span className=" fontcolor fw-500">Year</span>
                        <div>{data.year}</div>
                      </Col>
                    )}
                  </Row>
                  <Row style={{ paddingTop: "5px" }}>
                    <Col lg={9} md={9}>
                      <span className=" fontcolor fw-500">Start Date</span>
                      <div>{data.startDate}</div>
                    </Col>
                    <Col lg={8} md={8}>
                      <span className=" fontcolor fw-500">
                        Accumulated dep.
                      </span>
                      <div>{data.accumulatedDep}</div>
                    </Col>
                  </Row>
                  <Divider
                    className="botomline"
                    style={{ background: " rgb(214, 214, 214)" }}
                  />
                </>
              ) : null} */}
            </div>
          </Spin>
        </div>
      </Modal>
    </div>
  );
};

export default ViewAssetModel;
